import React from 'react';
import { 
  Typography, 
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { useStylesSessionListDetails, FileChip, ListCustom, FirstList} from './style.js';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@material-ui/core/Divider';

const SessionListDetails = (props) => {
  const classes = useStylesSessionListDetails();

  /**
   * * This function is executed if the user clicks on the medical certificate proof file
   * @param id is url of medical certificate
   * TODO: open the medical certificate proof file
   */
  const handleClick = (id) => {
    window.open(id);
  }

  return(
    <>
    {props.materialExist[props.sessionIdentify] || props.equipmentExist[props.sessionIdentify] || props.session.notes?
      <Card className={classes.cardDetail} elevation={0} style={{backgroundColor:props.backgroundColor}}>
        <CardContent className={classes.cardDetailContent}>
          <List dense={true}>
            <ListItem>
              <ListItemText primary={
                <Grid container spacing={3} justifyContent="space-between" >
                  <Grid item xs={4} style={{marginTop:'-15px',marginBottom:'-10px'}}>
                    {props.materialExist[props.sessionIdentify]?
                      <span>
                        <Grid container spacing={3} justifyContent="space-between" className={classes.gridContainer} >
                          <Grid item xs={10}>
                            <Typography variant="h6">Materials</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography variant="h6" style={{textAlign:'end'}}>kg/L</Typography>
                          </Grid>
                        </Grid>
                        <Card className={classes.cardSubDetail}>
                          <CardContent className={classes.cardSubDetailContent}>
                            <FirstList dense={true} disablePadding={true}>
                              {/* Checks if the session has material or equipment in the resources field */}
                              {props.session.resources?
                                props.session.resources.map((resourceData, resourceKey)=>{
                                  if(resourceData.resource)
                                  {
                                    if(resourceData.resource.type === 'chemical')
                                    {
                                      //* return material data view
                                      return(
                                        <ListItem key={resourceKey} className={classes.listDetail}>
                                            <ListItemText id={`materials_${props.sessionIdentify}_${resourceKey}`} className={classes.listItemDetail} secondaryTypographyProps={{component: "div"}} secondary={
                                            <Grid container spacing={0}>
                                              <Grid item xs={10}>
                                                <span id={`resource_name_${props.sessionIdentify}_${resourceKey}`} className={classes.font3}>{resourceData.resource.name}</span>
                                              </Grid>
                                              <Grid item xs={2} style={{textAlign: 'right'}}>
                                              <span id={`resource_quantity_${props.sessionIdentify}_${resourceKey}`} className={classes.font3}>{resourceData.quantity}</span>
                                              </Grid>
                                            </Grid>
                                          } />
                                        </ListItem>
                                      );
                                    }
                                  }
                                  return null;
                                })
                              :''}
                            </FirstList>
                          </CardContent>
                        </Card>
                      </span>
                    :''}
                  </Grid>
                  <Grid item xs={4} className={classes.GridStyle} style={{marginTop:'-15px',marginBottom:'-10px'}}>
                    {props.equipmentExist[props.sessionIdentify]?
                      <span>
                        <Typography variant="h6" className={classes.gridContainer}>Equipment</Typography>
                        <Card className={classes.cardSubDetail}>
                          <CardContent className={classes.cardSubDetailContent}>
                            <FirstList dense={true} disablePadding={true}>
                              {/* Checks if the session has material or equipment in the resources field */}
                              {props.session.resources?
                                props.session.resources.map((resourceData, resourceKey)=>{
                                  if(resourceData.resource)
                                  {
                                    if(resourceData.resource.type === 'equipment')
                                    {
                                      //* return equipment data view
                                      return(
                                        <ListItem id={`equipment_${props.sessionIdentify}_${resourceKey}`} key={resourceKey} className={classes.listDetail}>
                                          <ListItemText className={classes.listItemDetail} secondary={<span id={`resource_name_${props.sessionIdentify}_${resourceKey}`}  className={classes.font3}>{resourceData.resource.name}</span>} />
                                        </ListItem>
                                      );
                                    }
                                  }
                                  return null;
                                })
                              :''}
                            </FirstList>
                          </CardContent>
                        </Card>
                      </span>
                    :''}
                  </Grid>
                  <Grid item xs={4} className={classes.GridStyle}  style={{marginTop:'-15px',marginBottom:'-10px'}}>
                    {/* if the session has notes, then display */}
                    {props.session.notes?
                      <span>
                        <Typography variant="h6" className={classes.gridContainer}>Notes</Typography>
                        <Card className={classes.cardNotesDetail}>
                          <CardContent className={classes.cardSubDetailContent}>
                            <FirstList dense={true} disablePadding={true}>
                              <ListItem className={classes.listDetail}>
                                <ListItemText className={classes.listItemDetail} secondary={<span id={`session_notes_${props.sessionIdentify}`} className={classes.font3}>{props.session.notes}</span>}/>
                              </ListItem>
                            </FirstList>
                          </CardContent>
                        </Card>                      
                      </span>
                    :''}
                  </Grid>
                </Grid>              
              } />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    :''}
    {props.session?
      props.session.session_type.upload_description?
        props.session.attachments?
          //* if the session is sick leave and has uploaded proof of medical certificate, show the data
          props.session.attachments.map((file, index) => {
            return (
              <Card elevation={0} key={index} className={classes.cardDetail}>
                <CardContent className={classes.cardDetailFileContent}>
                  <List>
                    <ListItem>
                      <Grid container spacing={2} justifyContent="space-arround">
                        <Grid item xs={12} style={{marginTop:'-10px',marginBottom:'-10px'}}>
                          {file.directus_file !== null?
                            <FileChip
                              icon={<InsertDriveFileIcon />}
                              label={file.directus_file.filename_download} 
                              onClick={(id) => handleClick(file.directus_file.data.full_url)}
                              style={{ marginRight: '5px' }}
                            />
                          :  
                            <Tooltip placement="top" title="your file is undefined. Please reupload your file.">                   
                              <FileChip
                                icon={<InsertDriveFileIcon />}
                                label="undefined file" 
                                style={{ marginRight: '5px' }}
                              />
                            </Tooltip>
                          } 
                        </Grid>
                      </Grid>
                    </ListItem>
                  </List>   
                </CardContent>
              </Card>
            );
          })
        :''
      :''
    :''}

    {/* this is to display the options in the session, the option only exists for GTNT. If the employee already has awards, then he will have option data */}
    {props.session?
      props.session.options.length > 0?
      <Card className={classes.cardDetail} elevation={0}>
        <CardContent className={classes.cardDetailSessionOptionsContent}>          
          <ListCustom disablePadding={true}>
            {props.session.options.map((optionlist,index) => {
              return(
              optionlist.session_option !== null?
                optionlist.session_option.input_type !== 'none'?
                <>
                  <ListItem>
                    <Grid container spacing={0} justifyContent="space-evenly" alignItems="center">              
                      <Grid item xs={10}>
                        <Typography variant="h6">{optionlist.session_option.description}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Grid container spacing={0} justifyContent="center" alignItems="center">  
                          <Typography variant="h6">
                            {optionlist.session_option.input_type === "checkbox" || optionlist.session_option.input_type === ''?
                              optionlist.value==='true'?
                                'Yes'
                              :'No'
                            : 
                              optionlist.value?optionlist.value:''
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </>
                :''
              :''
              )
            })
            }
          </ListCustom>
        </CardContent>
      </Card>
      :''
    :''}
    </>
  );
};

export default SessionListDetails;
