import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Button,
  Typography,
  Grid,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Chip,
  Checkbox,
} from '@material-ui/core';
import {
  SubmitButton,
  CancelButton,
  pageOptions,
  inputTypeOptions,
  CustomTooltip,
  CustomGridApproval,
  CustomAccordion,
  CustomAccordionSummary,
  GridCustom,
  TextFieldCustom,
  TextFieldCustom2,
  AutoCompleteCustom,
  FormControlCustom
} from './style.js';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { useStylesEmployees, AddButton } from './style.js';
import { TimesheetOptionsViewModel } from "../../ViewModel/Settings/TimesheetOptionsViewModel.js";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import FormControlLabel from '@mui/material/FormControlLabel';
import Skeleton from '@mui/material/Skeleton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Select from '@mui/material/Select';
import SettingsSkeleton from './SettingsSkeleton';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function TimesheetOptions() {
  const [
    //states
    {
      open, title, timesheetOptions,
      mode, loading, disable,
      pageNumberRef, totalPage, page,
      pageNumber, text, localconfiguration,
      code, name, question, inputType, criteria,
      defaultValue, awardsFilterChosenRef, awards,
      customersFilterChosenRef, customers, loadingProcessRef
    },
    //function
    {
      setAwardsFilterChosen, setCustomersFilterChosen,
      handleClickOpen, handleClose, SaveTimesheetOptions,
      handleChange, handleChangeInput, pageChange
    }
  ] = TimesheetOptionsViewModel();

  const classes = useStylesEmployees();
  /* eslint-enable array-callback-return*/

  const handleClickList = (e, isHaveRelation) => {
    if (!isHaveRelation) {
      e.stopPropagation();
    }
  }

  return (
    <div>
      {timesheetOptions ?
        <div>
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ backgroundColor: '#f2f2f6', paddingRight: '40px' }}>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={1}>
                      <Typography variant="h5">Code</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5">Name</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h5">Description</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="h5" align="center" style={{ paddingLeft: '10px' }}>Input Type</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="h5" align="center" style={{ paddingLeft: '10px' }}>Default Value</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5" align="center" style={{ paddingLeft: '10px' }} >Criteria</Typography>
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: 'right' }}>
                      {localconfiguration.awards_settings ?
                        <AddButton size="small" color="secondary" aria-label="add" onClick={() => handleClickOpen('', 'add')}>
                          <AddIcon />
                        </AddButton>
                        : ''
                      }
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
          </List>

          {loadingProcessRef.current ?
            <SettingsSkeleton status='page_load' />
            :
            <div style={{ marginBottom: '20px' }}>
              {timesheetOptions.map((data, index) => {
                const isHaveRelation = data.awards.length > 0 || data.customers.length > 0;
                return (
                  <CustomAccordion key={index}>
                    <CustomAccordionSummary
                      id={`timesheet_option_${index}`}
                      expandIcon={isHaveRelation ? <ExpandMoreIcon /> : ''}
                      style={{ userSelect: 'auto', cursor: isHaveRelation ? 'pointer' : 'auto', alignItems: 'flex-start' }}
                    >
                      <Grid container onClick={(event) => handleClickList(event, isHaveRelation)} onFocus={(event) => event.stopPropagation()} style={{ paddingRight: isHaveRelation ? '0px' : '36px' }}>
                        <GridCustom item xs={1}>
                          <Typography noWrap variant="h6" id={`timesheetOptions_code_${index}`}>{data.code}</Typography>
                        </GridCustom>
                        <GridCustom item xs={2}>
                          <Typography noWrap variant="h6" id={`timesheetOptions_name_${index}`}>{data.name}</Typography>
                        </GridCustom>
                        <GridCustom item xs={4}>
                          <Typography noWrap variant="h6" id={`timesheetOptions_description_${index}`}>{data.description}</Typography>
                        </GridCustom>
                        <GridCustom item xs={1}>
                          <Typography noWrap variant="h6" id={`timesheetOptions_input_type_${index}`} align="center">{data.input_type}</Typography>
                        </GridCustom>
                        <GridCustom item xs={1}>
                          <Typography noWrap variant="h6" id={`timesheetOptions_default_value_${index}`} align="center">{data.default_value ? data.default_value : '-'}</Typography>
                        </GridCustom>
                        <GridCustom item xs={2}>
                          <Typography noWrap variant="h6" id={`timesheetOptions_only_overtime_${index}`} align="center">{data.only_overtime ? 'true' : 'false'}</Typography>
                        </GridCustom>
                        <Grid item xs={1} style={{ textAlign: 'right', marginLeft: 'auto' }}>
                          <IconButton id={`edit_btn_${index}`} aria-label="edit" onClick={(event) => { event.stopPropagation(); handleClickOpen(data, 'edit'); }}>
                            <EditIcon fontSize="small" />
                          </IconButton>
                          {localconfiguration.awards_settings ?
                            <IconButton id={`delete_btn_${index}`} aria-label="delete" onClick={(event) => { event.stopPropagation(); handleClickOpen(data, 'delete'); }} >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                            : ''}
                        </Grid>
                      </Grid>
                    </CustomAccordionSummary>
                    <AccordionDetails>
                      <Grid container key={index} style={{ paddingBottom: '1px' }}>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                          {data.awards.length > 0 ?
                            <div>
                              <Grid container key={index}>
                                <Grid item xs={1} style={{ maxWidth: '8.333333% !important' }}>
                                  <Typography noWrap variant="h6">Awards: &nbsp;</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                  {data.awards.map((awards, index) => {
                                    return (
                                      <CustomTooltip key={index} title={<Typography variant="h6">{awards.awards_id.name}</Typography>}>
                                        <Chip key={index} variant="outlined" size="small" label={
                                          <Typography variant="h6" id={`sp_cus_${index}`}>
                                            {awards.awards_id.code}
                                          </Typography>} color="primary" />
                                      </CustomTooltip>
                                    );
                                  })}
                                </Grid>
                              </Grid>
                            </div>
                            : ''}
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                          {data.customers.length > 0 ?
                            <div>
                              <Grid container key={index}>
                                <Grid item xs={1} style={{ maxWidth: '8.333333% !important' }}>
                                  <Typography noWrap variant="h6">Customers: &nbsp;</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                  {data.customers.map((customer, index) => {
                                    return (
                                      <Chip key={index} variant="outlined" size="small" label={
                                        <Typography variant="h6" id={`sp_cus_${index}`}>
                                          {customer.customers_id.name}
                                        </Typography>} color="primary" />
                                    );
                                  })}
                                </Grid>
                              </Grid>
                            </div>
                            : ''}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </CustomAccordion>
                );
              })}
              <Dialog
                id="timesheetOptions_dialog"
                isopen={`${open}`}
                open={open}
                fullWidth={true}
                onClose={handleClose}
                disableEnforceFocus
              >
                <DialogTitle disableTypography={true} id="form-dialog-title"><Typography id="timesheetOptions_dialog_title" component="div" variant="h6"><h2>{title}</h2></Typography></DialogTitle>
                <DialogContent>
                  <Typography variant="h6">{text ? text : ''}</Typography>
                  {mode !== 'delete' ?
                    <Grid container spacing={0} alignItems="center">
                      {localconfiguration.awards_settings ?
                        <>
                          <Grid item xs={12}>
                            <TextFieldCustom
                              size='small'
                              id="code_text"
                              label="Code"
                              variant="outlined"
                              fullWidth={true}
                              margin="normal"
                              name="code"
                              value={code ?? ''}
                              onChange={handleChangeInput}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextFieldCustom
                              size='small'
                              id="name_text"
                              label="Name"
                              variant="outlined"
                              fullWidth={true}
                              margin="normal"
                              name="name"
                              value={name ?? ''}
                              onChange={handleChangeInput}
                            />
                          </Grid>
                        </>
                        : ''}
                      <Grid item xs={12}>
                        <TextFieldCustom
                          size='small'
                          id="question_text"
                          label="Question"
                          variant="outlined"
                          fullWidth={true}
                          margin="normal"
                          name="question"
                          value={question ?? ''}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlCustom size='small' fullWidth style={{ marginTop: '16px', marginBottom: '8px' }}>
                          <Select
                            aria-label='Input Type'
                            id="input_type_text"
                            value={inputType}
                            name="input_type"
                            onChange={handleChangeInput}
                          >
                            {inputTypeOptions.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControlCustom>
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldCustom
                          size='small'
                          id="criteria_text"
                          label="Criteria"
                          variant="outlined"
                          fullWidth={true}
                          margin="normal"
                          name="criteria"
                          value={criteria ?? ''}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldCustom
                          size='small'
                          id="default_value_text"
                          label="Default Value"
                          variant="outlined"
                          fullWidth={true}
                          margin="normal"
                          name="default_value"
                          value={defaultValue ?? ''}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <br />
                        {awards && awardsFilterChosenRef.current ?
                          <AutoCompleteCustom
                            size='small'
                            aria-label='Select Awards'
                            multiple
                            id="awards-select"
                            options={awards}
                            disableCloseOnSelect
                            fullWidth={true}
                            getOptionLabel={(option) => option.code}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.code}
                              </React.Fragment>
                            )}
                            value={awardsFilterChosenRef.current}
                            onChange={(event, newValue) => {
                              setAwardsFilterChosen(newValue);
                            }}
                            renderInput={(params) => (
                              <TextFieldCustom2 {...params} variant="outlined" label="Select Awards" placeholder="Select..." />
                            )}
                          />
                          :
                          <SettingsSkeleton status='component_load' />
                        }
                      </Grid>
                      <Grid item xs={12}>
                        <br />
                        {customers && customersFilterChosenRef.current ?
                          <AutoCompleteCustom
                            size='small'
                            aria-label='Select Customers'
                            multiple
                            id="customers-select"
                            options={customers}
                            disableCloseOnSelect
                            fullWidth={true}
                            margin="normal"
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                              setCustomersFilterChosen(newValue)
                            }}
                            value={customersFilterChosenRef.current}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.name}
                              </React.Fragment>
                            )}
                            renderInput={(params) => (
                              <TextFieldCustom2 {...params} variant="outlined" label="Select Customers" placeholder="Select..." />
                            )}
                          />
                          :
                          <SettingsSkeleton status='component_load' />
                        }
                      </Grid>
                    </Grid>
                    : ''}
                </DialogContent>
                <DialogActions style={{ height: "60px" }}>
                  {loading ?
                    <CircularProgress className={classes.circular} />
                    :
                    <div>
                      <CancelButton
                        id="cancel_btn"
                        size="small"
                        variant="contained"
                        onClick={handleClose}
                        style={{ marginRight: '5px' }}
                      >
                        Cancel
                      </CancelButton>
                      <SubmitButton
                        id="save_btn"
                        disabled={mode === 'delete' ? false : disable}
                        size="small"
                        variant="contained"
                        onClick={() => SaveTimesheetOptions()}
                      >
                        {mode === 'delete' ? 'Delete' : 'Save'}
                      </SubmitButton>
                    </div>
                  }

                </DialogActions>
              </Dialog>
            </div>
          }
        </div>
        :
        <SettingsSkeleton status='page_load' />
      }

      {timesheetOptions ?
        <Grid
          item xs={12}
          style={{ textAlign: 'left', marginBottom: '10px', fontFamily: 'Manrope' }}
        >
          <span style={{ paddingRight: '20px' }}>
            Rows per page:
          </span>
          <span style={{ paddingRight: '20px' }}>
            <TextField
              id="standard-select-currency"
              select
              value={pageNumber}
              onChange={handleChange}
            >
              {pageOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </span>

          <span id="page_number" style={{ paddingRight: '20px' }}>
            {(pageNumberRef.current * (page - 1)) + 1}- {(pageNumberRef.current * page) > totalPage ? totalPage : pageNumberRef.current * page} of {totalPage}
          </span>

          <Button
            id="prev_btn"
            variant="outlined"
            size="small"
            aria-label='Back Button'
            disabled={page === 1}
            className={classes.buttonLeft}
            onClick={() => pageChange(-1)}
          >
            <ArrowBackIosRoundedIcon />
          </Button>

          <Button
            id="next_btn"
            variant="outlined"
            size="small"
            aria-label='Next Button'
            className={classes.buttonRight}
            disabled={page >= Math.ceil(totalPage / pageNumberRef.current)}
            onClick={() => pageChange(1)}
          >
            <ArrowForwardIosRoundedIcon />
          </Button>
        </Grid>
        :
        " "}
    </div>
  );
}