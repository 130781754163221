import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Grid,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
} from '@material-ui/core';
import {
  useStylesEmployees,
  AddButton,
  CssTextField,
  SubmitButton,
  CancelButton,
  pageOptions,
  CustomAccordion,
  CustomAccordionSummary,
  GridCustom,
  TextFieldCustom,
  KeyboardDatePickerCustom,
  AutoCompleteCustom,
} from './style.js';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { PublicHolidaysViewModel } from "../../ViewModel/Settings/PublicHolidaysViewModel";
import SettingsSkeleton from './SettingsSkeleton';

const company = JSON.parse(window.localStorage.getItem('company'));

export default function PublicHolidays() {

  const [
    //states
    {
      title, text, disable, open,
      Holidays, name, regions, description,
      localconfiguration, mode, regionsData, loading,
      selectedDate, pageNumber, pageNumberRef, totalPage,
      page, loadingProcessRef
    },
    //function
    {
      SaveHoliday, handleClickOpen, handleClose, handleDateChange,
      handleChangeInput, pageChange, handleChange,
    },
  ] = PublicHolidaysViewModel();

  const classes = useStylesEmployees();

  return (
    <>
      {Holidays ?
        <div >
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ backgroundColor: '#f2f2f6' }}>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={2}>
                      <Typography variant="h5">Date</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="h5">Name</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="h5">Description</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      {regionsData ? regionsData.length > 0 ?
                        <Typography variant="h5">Region</Typography>
                        : '' : ''}
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right', paddingRight: '40px' }}>
                      {localconfiguration.public_holidays_settings === true ?
                        <AddButton id="add_btn" size="small" color="secondary" aria-label="add" onClick={() => handleClickOpen('', 'add')}>
                          <AddIcon />
                        </AddButton>
                        : ''}
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
          </List>

          {loadingProcessRef.current ?
            <SettingsSkeleton status='page_load' />
            :
            <>
              {Holidays.map((holiday, index) => {
                return (
                  <CustomAccordion key={index}>
                    <CustomAccordionSummary
                      id={`holiday_list_${index}`}
                      style={{ userSelect: 'auto', cursor: 'auto', alignItems: 'flex-start' }}
                    >
                      <Grid container onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                        <GridCustom item xs={2} >
                          <Typography noWrap variant="h6" id={`holiday_date_${index}`}>{moment(holiday.date).format('dddd, DD MMM YYYY')}</Typography>
                        </GridCustom>
                        <GridCustom item xs={3} >
                          <Typography noWrap variant="h6" id={`holiday_name_${index}`}>{holiday.name}</Typography>
                        </GridCustom>
                        <GridCustom item xs={3}>
                          <Typography noWrap variant="h6" id={`holiday_desc_${index}`}>{holiday.description}</Typography>
                        </GridCustom>
                        <GridCustom item xs={2}>
                          {regionsData ? regionsData.length !== 0 ?
                            <Typography noWrap variant="h6" id={`holiday_region_${index}`}>{holiday.region ? holiday.region.name : ''}</Typography>
                            : '' : ''}
                        </GridCustom>
                        {localconfiguration.public_holidays_settings ?
                          <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <IconButton id={`edit_btn_${index}`} aria-label="edit" onClick={() => handleClickOpen(holiday, 'edit')} >
                              <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton id={`delete_btn_${index}`} aria-label="delete" onClick={() => handleClickOpen(holiday, 'delete')} >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Grid>
                          : ''}
                      </Grid>
                    </CustomAccordionSummary>
                  </CustomAccordion>
                );
              })}

              <Dialog
                id="holiday_dialog"
                isopen={`${open}`}
                open={open}
                fullWidth={true}
                onClose={handleClose}
              >
                <DialogTitle disableTypography={true} id="form-dialog-title"><Typography id="holiday_dialog_title" component="div" variant="h6"><h2>{title}</h2></Typography></DialogTitle>
                <DialogContent>
                  <Typography variant="h6">{text ? text : ''}</Typography>
                  {mode !== 'delete' ?
                    <div>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePickerCustom
                          id="date_picker"
                          inputVariant="outlined"
                          label="Date"
                          format="EEEE, dd-MM-yyyy"
                          placeholder="dd mm yyyy"
                          value={selectedDate ?? ''}
                          fullWidth={true}
                          onChange={date => handleDateChange(date)}
                          margin="normal"
                          size='small'
                        />
                      </MuiPickersUtilsProvider>
                      <TextFieldCustom
                        size='small'
                        id="name_text"
                        label="Name"
                        variant="outlined"
                        fullWidth={true}
                        margin="normal"
                        name="name"
                        value={name ?? ''}
                        onChange={handleChangeInput}
                      />
                      <TextFieldCustom
                        size='small'
                        id="description_text"
                        label="Description"
                        variant="outlined"
                        fullWidth={true}
                        margin="normal"
                        name="description"
                        value={description ?? ''}
                        onChange={handleChangeInput}
                      />
                      <br />
                      <br />
                      {regionsData ? regionsData.length !== 0 ?
                        <AutoCompleteCustom
                          id="size-small-standard"
                          size="small"
                          options={regionsData ? regionsData : []}
                          getOptionLabel={(option) => option.name}
                          value={regions ? regions : null}
                          onChange={(event, newValue) => {
                            handleChangeInput(newValue, "region");
                          }}
                          renderInput={(params) =>
                            <CssTextField
                              {...params}
                              variant="outlined"
                              name="region"
                              label="Select Region"
                            />}
                        />
                        : '' : ''}
                    </div>
                    : ''}
                </DialogContent>
                <DialogActions style={{ height: "60px" }}>
                  {loading ?
                    <SettingsSkeleton status='component_load' />
                    :
                    <div>
                      <CancelButton
                        id="cancel_btn"
                        size="small"
                        variant="contained"
                        onClick={handleClose}
                        style={{ marginRight: '5px' }}
                      >
                        Cancel
                      </CancelButton>
                      <SubmitButton
                        id="save_btn"
                        disabled={mode === 'delete' ? false : disable}
                        size="small"
                        variant="contained"
                        onClick={() => SaveHoliday()}
                      >
                        {mode === 'delete' ? 'Delete' : 'Save'}
                      </SubmitButton>
                    </div>
                  }
                </DialogActions>
              </Dialog>
            </>
          }
        </div>
        :
        <SettingsSkeleton status='page_load' />
      }

      {Holidays ?
        <Grid
          item xs={12}
          style={{ textAlign: 'left', marginBottom: '10px', fontFamily: 'Manrope' }}
        >
          <span style={{ paddingRight: '20px' }}>
            Rows per page:
          </span>
          <span style={{ paddingRight: '20px' }}>
            <TextField
              id="standard-select-currency"
              select
              value={pageNumber}
              onChange={handleChange}
            >
              {pageOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </span>

          <span id="page_number" style={{ paddingRight: '20px' }}>
            {(pageNumberRef.current * (page - 1)) + 1}- {(pageNumberRef.current * page) > totalPage ? totalPage : pageNumberRef.current * page} of {totalPage}
          </span>

          <Button
            id="prev_btn"
            variant="outlined"
            size="small"
            disabled={page === 1}
            className={classes.buttonLeft}
            onClick={() => pageChange(-1)}
          >
            <ArrowBackIosRoundedIcon />
          </Button>

          <Button
            id="next_btn"
            variant="outlined"
            size="small"
            className={classes.buttonRight}
            disabled={page >= Math.ceil(totalPage / pageNumberRef.current)}
            onClick={() => pageChange(1)}
          >
            <ArrowForwardIosRoundedIcon />
          </Button>
        </Grid>
        : " "}
    </>
  );
}