import React, { useState, useEffect } from 'react';
import {
  Typography,
  Container,
  Grid,
  Button,
  Menu,
  MenuItem,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import {
  useStyles,
  CancelButton,
  ApprovedPaidButton,
  YesPayrollButton,
  NoPayrollButton,
  CustomCardContent,
  CustomGridApproval,
  CodeCheckbox,
  LoadingButtonCustom
} from './style.js';
import Page from 'src/components/Page';
import { enableRipple } from '@syncfusion/ej2-base';
import WarningIcon from '@mui/icons-material/Warning';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import PaymentIcon from '@material-ui/icons/Payment';
import linq from "linq";
import useStateRef from "react-usestateref";
import directus from '../../services/directus';
import ApprovalTimesheetList from './ApprovalTimesheetList';
import moment from 'moment';
import { AdminPermission } from 'src/utils/Permission';
import { getConfiguration } from 'src/utils/sessions';
import EventEmitter from 'src/utils/EventEmitter';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { red } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../Components/ErrorMessage';
import Skeleton from '@mui/material/Skeleton';
import { DirectusEmployee } from 'src/views/Components/LocalstorageData.js';


let startApprovals;
let endApprovals;
let filterSessionLeave = 0;
let filterTimesheets = [];
let loop = 0;

const directusEmployeeData = DirectusEmployee();

// let filters_approvals=JSON.parse(window.localStorage.getItem('filters_approvals'));
const getApprovalSidebarStatus = () => {
  return localStorage.getItem('approvals_sidebar_open_status') ? JSON.parse(localStorage.getItem('approvals_sidebar_open_status')) : { data: 1, status: true };
}

const ApprovalListView = () => {
  /* eslint-disable */
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [items, setItems] = React.useState({ employees: null, timesheets: null, sessions: null });
  const [employeesFilterData, setEmployeesFilterData] = React.useState(null);
  const [initialized, setInitialized] = React.useState(false);
  const [employeesFilterChosen, setEmployeesFilterChosen] = React.useState(null);
  const [status, setStatus, statusRef] = useStateRef('all');
  const [statusView, setStatusView, statusViewRef] = useStateRef(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [startDate, setStartDate, startDateRef] = useStateRef('');
  const [endDate, setEndDate, endDateRef] = useStateRef('');
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [openNotif, setOpenNotif] = React.useState(false);
  const [addTimesheetLoading, setAddTimesheetLoading] = React.useState(false);
  const [moveLoading, setMoveLoading] = React.useState(false);
  const [loading, setLoading, loadingRef] = useStateRef(false);
  const [exportLoading, setExportLoading] = React.useState(false);
  const [anchorElExport, setAnchorElExport] = React.useState(null);
  const [openDialogPayroll, setOpenDialogPayroll] = React.useState(false);
  const [openDialogPayrollMsg, setOpenDialogPayrollMsg] = React.useState(false);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [openDialogChangeStatus, setOpenDialogChangestatus] = React.useState(false);
  const [changeLoading, setChangeLoading] = React.useState(false);

  const [payrollMessage, setPayrollMessage] = React.useState(false);
  const [searchApproval, setSearchApprovals, SearchApprovalRef] = useStateRef('');
  const [approvalLockData, setApprovalLockData, approvalLockDataRef] = useStateRef('');
  const [lockLoading, setLockLoading] = React.useState(false);
  const [exportdata, setExportData] = React.useState(null);
  const [selectedStatus, setSelectedStatus] = React.useState(5);
  const [selectedView, setSelectedView] = React.useState(1);
  const [errorText, setErrorText] = React.useState(null);
  const [configuration, setConfiguration] = useState(null);
  const [timesheetStatusType, setTimesheetStatusType] = React.useState([]);
  const [description, setDescription, descriptionRef] = useStateRef('');
  const [notification, setNotification, notificationRef] = useStateRef('');
  const [title, setTitle, titleRef] = useStateRef('');
  const [statusValue, setStatusValue] = useState(null);
  const [codeAll, setCodeAll] = useState(false);
  const [checked, setChecked] = useState([false, false, false]);
  const [timesheetStatus, setTimesheetStatus] = React.useState([]);
  const [type, setType] = React.useState([]);
  const [role, setRole] = React.useState([]);
  /* eslint-enable */
  enableRipple(true);

  var react_app_url = process.env.REACT_APP_URL;
  if (window.location.hostname.search('interax') !== -1) {
    react_app_url = process.env.REACT_APP_URL_INTERAX;
  }
  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      // opens or closes the sidebar based on user selection every time 'Approvals' is rendered for the first time
      window.DashboardGlobal = true;
      window.DashboardValue = getApprovalSidebarStatus().data;

      EventEmitter.emit('contentWidth', {
        text: getApprovalSidebarStatus().data
      })

      EventEmitter.emit('dashboardDrawer', {
        text: getApprovalSidebarStatus().data
      })

      setInitialized(true);
      setLoading(true);
      firstLoad();
      ApprovalExport();
      loadConfiguration();
      statusSummaryTypeLoad();
    }

    const searchApprovals = (eventData) => {
      setSearchApprovals(eventData.text);
      firstLoad();
    }

    const listener = EventEmitter.addListener('searchApprovals', searchApprovals);

    const FiltersTimesheet = (eventData) => {
      setChecked(eventData.checkedStatus);
      setTimesheetStatus(eventData.timesheetStatus);
      setType(eventData.type);
      setRole(eventData.role);
    }
    const listenerFilterTimesheet = EventEmitter.addListener('FiltersTimesheet', FiltersTimesheet);

    if (window.DashboardGlobal && window.DashboardValue === 5) {
      EventEmitter.emit('changePage');
    }

    const reloadPage = (eventData) => {
      if (eventData.text === 'reload') {
        loadTimeSheets('null', statusRef.current);
      }
    }
    const listenReloadpage = EventEmitter.addListener('reloadpage', reloadPage);

    return () => {
      listener.remove();
      listenerFilterTimesheet.remove();
      listenReloadpage.remove();
      setInitialized(false);
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps*/

  const SummaryDate = () => {
    EventEmitter.emit('summaryDate', {
      startdate: startDateRef.current,
      enddate: endDateRef.current,
      loading: moveLoading,
    })
  }

  const loadConfiguration = async () => {
    try {
      var configuration_data = await directus.getItems('config_ts');
      setConfiguration(configuration_data.data[0]);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e.code), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  };

  /* eslint-disable array-callback-return*/
  const statusSummaryTypeLoad = async () => {
    try {
      var timesheet_data = await directus.getField("timesheets", "status");
      const timesheet_status = Object.entries(timesheet_data.data.options.status_mapping);

      var timesheet_list = [];
      if (timesheet_status.length > 0) {
        timesheet_status.map((value) => {
          var background_color = "";
          var name = value[1].name;

          if (value[1].value === "approved") {
            background_color = "#DAF0DC";
          } else if (value[1].value === "rejected") {
            background_color = "#FFDBD9";
          } else if (value[1].value === "pending") {
            background_color = "#D4E3F2";
            name = "Not Yet Submitted";
          } else if (value[1].value === "awaiting_approval") {
            background_color = "#FFF0DD";
            name = "Waiting Approval";
          } else if (value[1].value === "sent") {
            background_color = "deepskyblue";
            name = "Sent to Payroll";
          } else if (value[1].value === "paid") {
            background_color = "#acebac";
          } else {
            background_color = "#ffffff";
          }
          timesheet_list.push({
            value: value[1].value,
            name: name,
            background_color: background_color,
          })
        })

        setTimesheetStatusType(timesheet_list);
      }
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  }

  const handleChangeCode = (e) => {
    e.persist(); // Persist the synthetic event
    setItems(prevItems => ({
      ...prevItems, timesheets:
        items.timesheets.map(d => {
          d.select = e.target.checked;
          return d;
        })
    })
    );
    setCodeAll(e.target.checked);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  const handleClickOpenDialogChangeStatus = (status) => {
    setOpenDialogChangestatus(true);
    setStatusValue(status.value);
    setTitle("Change Status to " + status.name);
    setDescription("This will change the timesheets status to " + status.name + ". Are you sure?");
  };

  const handleCloseDialogChangeStatus = () => {
    setOpenDialogChangestatus(false);
    handleCloseChangeStatus();
  };

  const updateChangeStatus = async () => {
    var timesheets = items.timesheets;
    let updateList = [];
    let sessionList = [];
    let timesheetId = [];

    setChangeLoading(true);
    handleCloseDialogChangeStatus();
    handleCloseChangeStatus();

    if (timesheets) {
      let timesheetsList = linq.from(timesheets)
        .where(x => x.select === true && x.employee.status !== 'deleted')
        .toArray();

      if (timesheetsList.length > 0) {
        timesheetsList.map((value) => {
          if (value.total_minutes !== 0 && value.employee.status !== 'deleted') {
            timesheetId = [...timesheetId, value.id];
          }

          updateList.push({
            id: value.id,
            status: statusValue
          })
        })

        await directus.updateItems('timesheets', updateList);

        try {
          if (timesheetId.length > 0) {
            var SessionResult = await directus.getItems('sessions', {
              fields: 'timesheet.id,id,status',
              filter: {
                'timesheet.id': { eq: timesheetId },
              },
              limit: -1
            });

            if (SessionResult.data.length > 0) {
              SessionResult.data.map((value) => {
                sessionList.push({
                  id: value.id,
                  status: statusValue === 'awaiting_approval' ? 'pending' : statusValue
                })
              })
              await directus.updateItems('sessions', sessionList);
            }
          }
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }

        loadTimeSheets('null', statusRef.current);
        EventEmitter.emit('reloadChangeStatus', { value: true })
        setCodeAll(false);
      }
      else {
        setOpenNotif(true);
        setNotification('There is no timesheet selected. Please select your timesheet first.')
      }

      setOpenDialogChangestatus(false);
      setChangeLoading(false);
    }
  }

  const handleClickChangeStatus = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleCloseChangeStatus = () => {
    setAnchorE2(null);
  };

  const handleCloseNotif = () => {
    setOpenNotif(null);
  };

  const firstLoad = async (data = false) => {

    if (window.localStorage.getItem('approvals_date') && data === false) {
      startApprovals = JSON.parse(window.localStorage.getItem('approvals_date')).start;
      endApprovals = JSON.parse(window.localStorage.getItem('approvals_date')).end;
    }
    else {
      var ConfigurationResult = await getConfiguration();
      let config_day_v1 = moment(moment().day(ConfigurationResult.first_day_of_week_v1)._d);
      let config_day_v2 = moment(moment().day(ConfigurationResult.first_day_of_week_v2)._d);
      let expired_date_v1 = moment(ConfigurationResult.expired_date_v1).format('YYYY-MM-DD 23:59:59');

      if (moment(moment().add(-2, 'd').format('YYYY-MM-DD')).isBefore(moment(config_day_v1).format('YYYY-MM-DD'))) {
        startApprovals = (moment(config_day_v1).add(-7, 'd').format('YYYY-MM-DD 00:00:00'));
        endApprovals = (moment(startApprovals).add(6, 'd').format('YYYY-MM-DD 23:59:59'));
      }
      else {
        startApprovals = (moment(config_day_v1).format('YYYY-MM-DD 00:00:00'));
        endApprovals = (moment(startApprovals).add(6, 'd').format('YYYY-MM-DD 23:59:59'));
      }


      if (moment(startApprovals).isAfter(moment(expired_date_v1))) {
        startApprovals = (moment(config_day_v2).format('YYYY-MM-DD 00:00:00'));
        endApprovals = (moment(startApprovals).add(6, 'd').format('YYYY-MM-DD 23:59:59'));
      }
    }


    setStartDate(startApprovals ? startApprovals : moment().add(-2, 'd').format('YYYY-MM-DD 00:00:00'));
    setEndDate(endApprovals ? endApprovals : moment().add(-2, 'd').format('YYYY-MM-DD 23:59:59'));

    SummaryDate();
    lockApproval();
    loadTimeSheets('null', statusRef.current);

  }

  const lockApproval = async () => {
    try {
      setLockLoading(true);

      var lockResult = await directus.getItems('approvals_lock', {
        fields: 'id,lock',
        filter: {
          start_date: startDateRef.current
        },
      })

      if (lockResult.data.length === 0) {
        try {
          lockResult = await directus.createItems('approvals_lock', [{ start_date: startDateRef.current, lock: 'false' }]);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      setApprovalLockData(lockResult.data[0]);
      setLockLoading(false);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  }

  const loadLock = async (id, event) => {
    setLockLoading(true);
    try {

      var lockResult = await directus.updateItem('approvals_lock', id,
        {
          lock: event,
        },
        { fields: 'id,lock' }
      );
      setApprovalLockData(lockResult.data);

    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
    setLockLoading(false);
  }

  const loadTimeSheets = async (employeeData, status) => {
    setMoveLoading(true);
    let employeesId = [];
    let filterData;
    let employeeArray = [];
    let loopCountEmployee = 0;
    let employeeCount = 0;

    if (employeeData !== 'null') {
      if (employeeData.length > 30) {
        employeeData.map((data, index) => {

          if (data) {
            if (index !== 0 && index % 30 === 0) {
              employeeArray[loopCountEmployee] = employeesId;
              employeesId = [];
              loopCountEmployee = loopCountEmployee + 1;
              employeeCount = 0
            }
            employeesId[employeeCount] = data.id;
            employeeCount = employeeCount + 1;

            if (index + 1 === employeeData.length) {
              employeeArray[loopCountEmployee] = employeesId;
            }
          }
        })
      }
      else {
        employeeData.map((data, index) => {
          if (data) {
            employeesId[index] = data.id;
          }
        })
      }
    }
    else {
      if (SearchApprovalRef.current) {
        filterData = {
          'employee.status': { in: ['published', 'deleted'] },
          'employee.user.first_name': { like: SearchApprovalRef.current },
          'employee.user.last_name': { 'logical': 'or', like: SearchApprovalRef.current },
          'employee.code': { 'logical': 'or', like: SearchApprovalRef.current },
        };
      }

      else {
        filterData = {
          'employee.status': { in: ['published', 'deleted'] },
        };
      }
    }

    if (status === 'all') {
      filterData = {
        ...filterData,
      }
    }
    else {
      filterData = {
        ...filterData,
        status: { eq: status }
      }
    }

    filterData = {
      ...filterData,
      start_time: { gte: startDateRef.current },
      end_time: { lte: endDateRef.current },
    }

    let TimesheetsResult = [];
    let countTimesheetArray = 0;

    var timesheet_fields = 'id,end_time,start_time,status,total_minutes,employee.user.id,';
    timesheet_fields += 'employee.user.first_name,employee.user.last_name,employee.id,';
    timesheet_fields += 'employee.code,employee.status,employee.type,employee.leave_approver,';
    timesheet_fields += 'employee.mobile_number,employee.parent_leave,employee.user.role,';

    if (employeeData.length > 30) {
      for (const employee of employeeArray) {
        filterData = {
          ...filterData,
          employee: { in: employee },
        }
        try {
          let fetchTimesheetResult = await directus.api.get('/custom/approvals/timesheets', {
            fields: timesheet_fields,
            filter: filterData,
            limit: -1,
            show_timesheet_review: true,
            timesheet_review_filter:
            {
              "read_by.employees_id": { in: [directusEmployeeData.id] }
            },
            timesheet_review_unread_count: { in: 0 },
          });

          TimesheetsResult = TimesheetsResult.concat(fetchTimesheetResult.data);

          countTimesheetArray = countTimesheetArray + 1;
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }
    }
    else {
      filterData = {
        ...filterData,
        employee: { in: employeesId },
      }

      try {
        let fetchTimesheetResult = await directus.api.get('/custom/approvals/timesheets', {
          fields: timesheet_fields,
          filter: filterData,
          limit: -1,
          show_timesheet_review: true,
          timesheet_review_filter:
          {
            "read_by.employees_id": { in: [directusEmployeeData.id] }
          },
          timesheet_review_unread_count: { in: 0 },
        });

        TimesheetsResult = TimesheetsResult.concat(fetchTimesheetResult.data);

      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    let timesheetId = [];

    TimesheetsResult.map((data) => {
      timesheetId = [...timesheetId, data.id];
    })

    //eslint-disable-next-line no-redeclare
    try {

      var TimesheetReview = await directus.api.post('/custom/mobile/timesheets/batch-data', {
        "timesheet_review": {
          fields: '*,employee.user.first_name,employee.user.last_name,read_by.employees_id.id',
          filter: {
            'timesheet.id': { in: timesheetId }
          },
          sort: "created_on"
        }
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    TimesheetsResult.map((data, index) => {
      let result = 0

      result = linq.from(TimesheetReview.data.timesheet_review)
        .where(x => x.timesheet.id === data.id).count();
      TimesheetsResult[index] = { ...data, totalCountTimesheet: result, select: false };
    })


    SummaryDate();

    if (TimesheetsResult.length > 0) {
      if (statusViewRef.current === 2) {
        filterSessionLeave = 0;
        filterTimesheets = [];
        loop = 0;
        filterLeaveStatus(TimesheetsResult)
      }
      else {
        TimesheetFinishing(TimesheetsResult);
      }
    }
    else {
      setItems(prevItems => ({
        ...prevItems,
        timesheets: TimesheetsResult,
      }));

      loadTimesheetsFinishLoading();
    }
    setLoading(false);
  };

  const loadTimesheetsFinishLoading = () => {
    window.localStorage.setItem('approvals_date', JSON.stringify({ start: startDateRef.current, end: endDateRef.current }));
    setMoveLoading(false);
    EventEmitter.emit('changeDate', { text: 'changeDate' });
    loadEmployeeFilter();
  }

  const filterLeaveStatus = async (TimesheetsResult) => {
    try {
      var SessionResult = await directus.getItems('sessions', {
        fields: '*.*',
        filter: {
          'timesheet.id': { eq: TimesheetsResult[filterSessionLeave].id },
          'session_type.leave': { nempty: true }
        },
        limit: -1
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    if (SessionResult.data.length > 0) {
      filterTimesheets[loop] = TimesheetsResult[filterSessionLeave];
      loop = loop + 1;
    }

    if (filterSessionLeave + 1 === TimesheetsResult.length) {
      TimesheetFinishing(filterTimesheets);
    }
    else {
      filterSessionLeave = filterSessionLeave + 1;
      filterLeaveStatus(TimesheetsResult);
    }
  }

  const TimesheetFinishing = async (TimesheetsResult) => {
    let filterdata;
    let filterTimesheet;

    if (SearchApprovalRef.current) {
      filterdata = linq.from(TimesheetsResult)
        .where(x => x.start_time === startDateRef.current && x.end_time === endDateRef.current && x.status !== 'deleted').toArray();
      filterTimesheet = linq.from(filterdata)
        .orderBy(x => x.employee.code).toArray();
    }
    else {
      filterTimesheet = linq.from(TimesheetsResult)
        .orderBy(x => x.employee.code).toArray();
    }

    filterTimesheet = linq.from(filterTimesheet)
      .orderByDescending(x => x.status === 'awaiting_approval')
      .thenByDescending(x => x.status === 'pending')
      .thenByDescending(x => x.status === 'approved')
      .thenByDescending(x => x.status === 'rejected').toArray();

    setItems(prevItems => ({
      ...prevItems,
      timesheets: filterTimesheet
    }));

    loadTimesheetsFinishLoading();
  }

  const loadEmployeeFilter = async () => {
    let ExcludeEmployee;
    try {
      var TimesheetsResult = await directus.api.get('/custom/approvals/timesheets', {
        fields: 'id,employee.id',
        filter: {
          start_time: { gte: startDateRef.current },
          end_time: { lte: endDateRef.current },
        },
        limit: -1,
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    TimesheetsResult.data.map((data, index) => {
      if (index === 0) {
        if (data.employee !== null) {
          ExcludeEmployee = data.employee.id;
        }
      }
      else {
        if (data.employee !== null) {
          ExcludeEmployee = ExcludeEmployee + "," + data.employee.id;
        }
      }
    })

    try {
      var EmployeeResult = await directus.getItems('employees', {
        fields: 'user.first_name,user.last_name,user.email,id,code,status',
        filter: {
          id: { nin: ExcludeEmployee },
          status: { eq: 'published' },
        },
        sort: 'code',
        limit: -1
      });
      let empResult = [];
      empResult = linq.from(EmployeeResult.data)
        .where(x => x.user !== null).toArray();

      setEmployeesFilterData(empResult);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

  }

  function findNextTuesday(date) {
    while (date.day() !== 2) {
      date.add(1, 'days');
    }
    return date;
  }

  async function moveTimesheet(input) {

    var ConfigurationResult = await getConfiguration();
    let expired_date_v1 = moment(ConfigurationResult.expired_date_v1).format('YYYY-MM-DD 23:59:59');

    if (input === 0) {
      firstLoad(true);
    }
    else {
      let start = moment(startDate).add(input, 'w').format('YYYY-MM-DD 00:00:00');
      let end = moment(endDate).add(input, 'w').format('YYYY-MM-DD 23:59:59');
      let filterOldEnd = moment(start).isBefore(expired_date_v1) && moment(end).isSameOrAfter(expired_date_v1) ? moment(expired_date_v1) : moment(endDate).add(input, 'w').format('YYYY-MM-DD 23:59:59');
      let oldEnd = moment(filterOldEnd);


      if (moment(start).isBefore(expired_date_v1) && oldEnd.isSameOrBefore(expired_date_v1)) {

        let newEndDate = findNextTuesday(oldEnd.clone());
        let newStartDate = findNextTuesday(oldEnd.clone()).add(-6, 'd');

        setStartDate(newStartDate.format('YYYY-MM-DD 00:00:00'));

        if (moment(newEndDate).isSameOrAfter(expired_date_v1)) {
          setEndDate(expired_date_v1);
        }
        else {
          setEndDate(newEndDate.format('YYYY-MM-DD 23:59:59'));
        }
      }
      else {
        let newEndDate = moment(endDate).add(input, 'w').format('YYYY-MM-DD 23:59:59');
        let newStartDate = moment(newEndDate).add(-6, 'd').format('YYYY-MM-DD 00:00:00')

        setStartDate(newStartDate);
        setEndDate(newEndDate);
      }
    }

    SummaryDate();
    lockApproval();
    loadTimeSheets('null', statusRef.current);
  }

  const ApprovalExport = async () => {

    try {
      var ExportResult = await directus.getItems('approvals_export', {
        fields: 'id,name,link,icon,send_to_email',
        sort: 'number'
      });

      setExportData(ExportResult.data);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

  }
  /* eslint-enable array-callback-return*/

  const checkTimesheet = (checked, id) => {
    setItems({
      timesheets:
        items.timesheets.map(data => {
          if (id === data.id) {
            data.select = checked;
          }
          return data;
        })
    });
  }

  return (
    <Page className={classes.root} title="Approvals">
      <Container maxWidth={false}>
        <div className="control-pane">
          <div className="control-section">
            <Grid container spacing={0} style={{ paddingBottom: '28px' }}>
              <Grid item xs={8} sm={8} md={6} lg={7}>
                <div>
                  <Button id="approvals_date_range_prev" variant="outlined" onClick={() => moveTimesheet(-1)} className={classes.buttonLeft}>
                    <ArrowBackIosRoundedIcon />
                  </Button>
                  <Button id="approvals_date_range" variant="outlined" size="small" onClick={() => moveTimesheet(0)} className={classes.buttonText}>
                    <Typography id="approvals_date_range_text" color="textPrimary" variant="body2" align="center">
                      {moment(startDateRef.current).format('DD MMMM YYYY') + ' - ' + moment(endDateRef.current).format('DD MMMM YYYY')}
                    </Typography>
                  </Button>

                  <Button id="approvals_date_range_next" variant="outlined" size="small" onClick={() => moveTimesheet(1)} className={classes.buttonRight}>
                    <ArrowForwardIosRoundedIcon />
                  </Button>
                </div>
              </Grid>

              <Grid item xs={4} sm={4} md={6} lg={5}>
                <div className={classes.add}>
                  {loadingRef.current ?
                    <Skeleton animation="wave" variant="rounded" width={300} height={30} sx={{ borderRadius: '8px' }} />
                    :
                    AdminPermission() ?
                      approvalLockDataRef.current ?
                        <LoadingButtonCustom
                          loading={lockLoading}
                          loadingPosition="start"
                          onClick={() => loadLock(approvalLockDataRef.current.id, approvalLockDataRef.current.lock === 'true' ? 'false' : 'true')}
                          startIcon={approvalLockDataRef.current.lock === 'true' ? <LockIcon /> : <LockOpenIcon />}
                          variant="contained"
                        >
                          Lock Approvals
                        </LoadingButtonCustom>
                        : ''
                      : ''}

                  {loadingRef.current ? '' :
                    AdminPermission() && configuration ?
                      configuration.enable_update_status ?
                        <ApprovedPaidButton
                          loading={changeLoading}
                          loadingPosition="start"
                          onClick={handleClickChangeStatus}
                          style={{ marginLeft: "5px" }}
                          startIcon={<PaymentIcon />}
                          variant="contained"
                        >
                          Update Status
                        </ApprovedPaidButton>
                        : ''
                      : ''
                  }
                </div>

                <Menu
                  id="status_filter_menu"
                  anchorEl={anchorE2}
                  keepMounted
                  open={Boolean(anchorE2)}
                  isopen={`${Boolean(anchorE2)}`}
                  onClose={handleCloseChangeStatus}
                  className={classes.updatestatus}
                >
                  {timesheetStatusType.map((status, index) => {
                    if (status.name !== "Deleted") {
                      return (
                        <div key={index}>
                          <MenuItem onClick={() => handleClickOpenDialogChangeStatus(status)}>
                            {status.name}
                          </MenuItem>
                        </div>
                      );
                    }
                  })
                  }
                </Menu>

                <Dialog id="openDialogPaid" isopen={`${openDialogChangeStatus}`} open={openDialogChangeStatus} onClose={handleCloseDialogChangeStatus} aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title"> {titleRef.current} </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {descriptionRef.current}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <YesPayrollButton id="yes_paid_btn" onClick={() => updateChangeStatus()} color="primary">
                      Yes
                    </YesPayrollButton>
                    <NoPayrollButton id="no_paid_btn" onClick={() => handleCloseDialogChangeStatus()} color="primary">
                      No
                    </NoPayrollButton>
                  </DialogActions>
                </Dialog>

                <Dialog id="openNotif" isopen={`${openNotif}`} open={openNotif} aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title"> Can't Update {titleRef.current} </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {notificationRef.current}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <YesPayrollButton id="ok_paid_btn" onClick={() => handleCloseNotif()} color="primary">
                      Ok
                    </YesPayrollButton>
                  </DialogActions>
                </Dialog>

                <Dialog open={openError}>
                  <DialogTitle style={{ backgroundColor: red[100] }}>
                    <div style=
                      {{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Avatar sx={{ bgcolor: red[200], color: red[600], width: 40, height: 40 }}>
                        <WarningIcon fontSize='medium' />
                      </Avatar>
                      <span style={{ paddingLeft: '5px' }}>Error Message</span>
                    </div>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      <Typography>
                        {errorText}
                      </Typography>
                    </DialogContentText>
                    <DialogActions>
                      <CancelButton
                        size="small"
                        variant="contained"
                        onClick={handleCloseError}
                      >
                        <Typography variant="h6">Ok</Typography>
                      </CancelButton>
                    </DialogActions>
                  </DialogContent>
                </Dialog>
              </Grid>
            </Grid>

            <Card elevation={0} className={classes.card}>
              <CustomCardContent className={classes.cardContent}>
                {loadingRef.current ?
                  <Grid container spacing={0}>
                    <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={3} sm={3} md={3} lg={2}>
                      <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', margin: '2px 2px' }} />
                    </CustomGridApproval>
                    <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2} sm={2} md={2} lg={3}>
                      <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', margin: '2px 2px' }} />
                    </CustomGridApproval>
                    <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2} sm={2} md={2} lg={2}>
                      <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', margin: '2px 2px' }} />
                    </CustomGridApproval>
                    <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2} sm={2} md={2} lg={2}>
                      <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', margin: '2px 2px' }} />
                    </CustomGridApproval>
                    <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={1} sm={1} md={1} lg={1}>
                      <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', margin: '2px 2px' }} />
                    </CustomGridApproval>
                  </Grid>
                  :
                  <Grid container spacing={0}>
                    <CustomGridApproval item xs={3} sm={3} md={3} lg={2}>
                      <Typography style={{ paddingLeft: '20px' }}>
                        {AdminPermission() && configuration ?
                          configuration.enable_update_status ?
                            <CodeCheckbox
                              id="codeAll"
                              disableRipple
                              sx={{
                                "&.Mui-checked": {
                                  color: "#2AC940"
                                },
                              }}
                              onChange={(e) =>
                                handleChangeCode(e)
                              }
                              checked={codeAll}
                            />
                            : ''
                          : ''}
                        <b>Code</b>
                      </Typography>
                    </CustomGridApproval>
                    <CustomGridApproval item xs={2} sm={2} md={2} lg={3}>
                      <Typography noWrap><b>Name</b></Typography>
                    </CustomGridApproval>
                    <CustomGridApproval item xs={2} sm={2} md={2} lg={2}>
                      <Typography noWrap><b>Phone</b></Typography>
                    </CustomGridApproval>
                    <CustomGridApproval item xs={2} sm={2} md={2} lg={2}>
                      <Typography noWrap style={{ paddingLeft: '10px' }}><b>Status</b></Typography>
                    </CustomGridApproval>
                    <CustomGridApproval item xs={1} sm={1} md={1} lg={1}>
                      <Typography noWrap><b>Total Time</b></Typography>
                    </CustomGridApproval>
                    <CustomGridApproval item xs={2} sm={2} md={2} lg={2}>
                    </CustomGridApproval>
                  </Grid>
                }
              </CustomCardContent>
            </Card>

            {loadingRef.current || changeLoading || moveLoading ?
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                </Grid>
              </Grid>
              :
              items.timesheets ?
                items.timesheets.length > 0 ?
                  <>
                    <ApprovalTimesheetList
                      employees={items.employees}
                      timesheets={items.timesheets}
                      filters={checked}
                      timesheetStatus={timesheetStatus}
                      timesheetStatusType={timesheetStatusType}
                      type={type}
                      role={role}
                      checkTimesheet={checkTimesheet}
                      configuration={configuration ? configuration.enable_update_status : 0}
                    />
                  </>
                  :
                  !moveLoading ?
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      style={{ opacity: '0.2', paddingTop: '6pc' }}
                    >
                      <Grid item xs={12} sm={12} lg={12}>
                        <img alt="No Data Found" height="230" width="230" src="/static/images/folder.png?v=0.0.1" />
                        <Typography variant="h3" style={{ textAlign: 'end' }}>No Data Found</Typography>
                      </Grid>

                    </Grid>
                    : ''
                : ''}
          </div>
        </div>
      </Container>
    </Page>
  );
};

export default ApprovalListView;