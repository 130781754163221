import React, { useState } from 'react';
import {
  Box,
  List,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import directus from '../../../services/directus';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../../views/Components/ErrorMessage';
import moment from 'moment';
import useStateRef from "react-usestateref";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import EventEmitter from 'src/utils/EventEmitter';
import TextField from '@mui/material/TextField';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import dayjs from 'dayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import EventIcon from '@mui/icons-material/Event';

import Enumerable from 'linq';
import Checkbox from '@mui/material/Checkbox';
import { LinearProgress } from '@mui/material';
import SaveAddSession from './SaveAddSession';
import {
  useStylesEditSession,
  FileChip,
  TextFieldCustom,
  TextFieldCustomerActivityCustom,
  TextFieldNotesCustom,
  TextFieldSessionOptionCustom,
  TextFieldSessionOptionValueEditCustom,
  AutocompleteCustom,
  UploadButton,
  GroupHeader,
  GroupItems,
  AutocompleteMaterialEquipmentCustom,
  FormControlCustom,
  SwitchCustom,
  FormControlLabelCustom,
  TimePickerCustom,
  CheckboxCustom,
} from './style.js';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddEquipment from './AddEquipment/AddEquipment';
import AddMaterial from './AddMaterial/AddMaterial';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const AddSession = (props) => {
  /* eslint-disable no-unused-vars*/
  const classes = useStylesEditSession();
  const { enqueueSnackbar } = useSnackbar();
  const [initialized, setInitialized] = React.useState(false);
  const [type, setType] = React.useState(null);
  const [loadingAddSession, setLoadingAddSession] = useState(null);
  const [loadingData, setLoadingData] = useState(null);
  const [selectedDate, setSelectedDate, selectedDateRef] = useStateRef(null);
  const [startTime, setStartTime, startTimeRef] = useStateRef(null);
  const [endTime, setEndTime, endTimeRef] = useStateRef(null);
  const [breakTime, setBreakTime] = React.useState(null);
  const [breakStartTime, setBreakStartTime] = React.useState(null);
  const [breakEndTime, setBreakEndTime] = React.useState(null);
  const [duration, setDuration] = React.useState(null);
  const [notes, setNotes, notesRef] = useStateRef(null);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [smoko, setSmoko] = React.useState(0);
  const [dateLoading, setDateLoading] = React.useState(false);

  const [lengthCustomer, setLengthCustomer] = React.useState('');
  const [lengthActivity, setLengthActivity] = React.useState('');
  const loading = open && options.length === 0;
  const [materialData, setMaterialData] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [allMaterialData, setAllMaterialData] = useState([]);
  const [allEquipmentData, setAllEquipmentData] = useState([]);

  const [file, setFile, fileRef] = useStateRef([]);
  const [delFile, setDelFile, delFileRef] = useStateRef([]);
  const [loadingFile, setLoadingFile] = React.useState(false);
  const [sessionOptions, setSessionOptions, sessionOptionsRef] = useStateRef([]);
  const [newSessionOptions, setNewSessionOptions, newSessionOptionsRef] = useStateRef([]);
  const [newList, setNewList, newListRef] = useStateRef();
  const [value, setValue, valueRef] = useStateRef([]);
  const [optionsList, setOptionsList, optionsListRef] = useStateRef([]);
  const [loadingSession, setLoadingSession] = React.useState(false);

  const [rosterStart, setRosterStart, rosterStartRef] = useStateRef(null);
  const [rosterEnd, setRosterEnd, rosterEndRef] = useStateRef(null);
  const [rosterBreakStart, setRosterBreakStart, rosterBreakStartRef] = useStateRef(null);
  const [rosterBreakEnd, setRosterBreakEnd, rosterBreakEndRef] = useStateRef(null);
  const [ordinaryHours, setOrdinaryHours, ordinaryHoursRef] = useStateRef(null);
  const [employeeId, setEmployeeId, employeeIdRef] = useStateRef(null);
  const [timesheetId, setTimesheetId] = useStateRef(null);

  const [autoActivity, setAutoActivity] = React.useState(null);
  const [autoCustomer, setAutoCustomer] = React.useState(null);
  const [createAnother, setCreateAnother] = React.useState(false);
  const [keepCustomer, setKeepCustomer] = React.useState(false);
  const [keepActivity, setKeepActivity] = React.useState(false);

  const [selectData, setSelectData] = React.useState([]);
  const [state, setState, stateRef] = useStateRef({
    select: '',
    data: null
  });

  const [timesheetStart, setTimesheetStart] = useState(null);
  const [timesheetEnd, setTimesheetEnd] = useState(null);
  const [timesheetStatus, setTimesheetStatus] = useState(null);
  const [timesheetExtra, setTimesheetExtra] = useState(null);


  React.useEffect(() => {
    async function fetchData() {
      if (!initialized) {
        setInitialized(true);
        loadTimesheets();
      }

      const saveAddSessionDrawer = async (eventData) => {
        if (eventData.text) {
          loadTimesheets();
          setNotes('');
          if(eventData.tickCustomer===false){setAutoCustomer(null)}
          if(eventData.tickActivity===false){setAutoActivity(null)}
          setStartTime(null);
          setEndTime(null);
          setMaterialData([]);
          setEquipmentData([]);
          setDuration('');
          setBreakTime('');
        }
      }
      const listenersaveAddSessionDrawer = EventEmitter.addListener('saveAddSessionDrawer', saveAddSessionDrawer);

      const moveTimesheetLoading = async (eventData) => {
        if (eventData.text === 'startLoading') {
          setLoadingAddSession(true);

        }
        else {
          loadTimesheets(eventData.timesheets);
          setLoadingAddSession(false);
        }
      }
      const listenerMoveTimesheetLoading = EventEmitter.addListener('moveTimesheets', moveTimesheetLoading);

      return () => {
        listenersaveAddSessionDrawer.remove();
        listenerMoveTimesheetLoading.remove();
        setInitialized(false);
      }
    }
    fetchData();
  }, []);


  const loadTimesheets = async (data) => {
    setLoadingAddSession(true);
    setTimesheetId(data ? data.id : props.timesheets.id);
    setEmployeeId(data ? data.employee.id : props.timesheets.employee.id);
    setSelectedDate(dayjs(data ? data.start_time : props.timesheets.start_time));
    setTimesheetStart(dayjs(data ? data.start_time : props.timesheets.start_time));
    setTimesheetEnd(dayjs(data ? data.end_time : props.timesheets.end_time));
    setTimesheetStatus(data? data.status:props.timesheets.status);
    setTimesheetExtra(data? data.extra:props.timesheets.extra);
    getSelectList();
    fillResourceData();
    setLoadingAddSession(false);
  };

  const getSelectList = async () => {
    setLoadingAddSession(true);
    setLoadingData(true);
    try {
      var selectResult = await directus.getItems('session_types', {
        fields: '*,session_options.*',
        filter: {
          status: { eq: 'published' }
        },
      });
    }

    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    setSelectData(selectResult.data);

    //eslint-disable-next-line array-callback-return
    selectResult.data.map(data => {
      if (data.id === props.configuration) {
        setState({
          ...state,
          data: data,
          select: data.name,
          checkbox_full_day: data.full_day,
          checkbox_public_holiday: data.public_holiday === true ? false : true,
        });
        sessionOptionData();
      }
      if (data.start_end_time_from_roster) {
        rosterData(selectedDateRef.current);
      }
    })
    setLoadingData(false);
    setLoadingAddSession(false);
  };

  const handleChangeType = (event) => {
    //eslint-disable-next-line array-callback-return
    selectData.map(data => {
      if (data.name === event.target.value) {
        setState({
          ...stateRef.current,
          select: data.name,
          data: data,
          checkbox_full_day: data.full_day,
          checkbox_public_holiday: data.public_holiday === true ? false : true,
        });

        if (data.start_end_time_from_roster) {
          rosterData();
        }
        else {
          setStartTime(null);
          setEndTime(null);
          setBreakStartTime(null);
          setBreakEndTime(null);
          setRosterStart(null);
          setRosterEnd(null);
          setOrdinaryHours(null);
        }
        sessionOptionData();
      }
    })
  };

  const handleChangeChecked = (event, id) => {
    const { name } = event.target;

    selectData.map(data => {
      if (data.id === id) {
        setState({
          ...state,
          select: data.name,
          data: data,
        });

        if (name === 'checkbox_full_day') {
          setState({
            ...state,
            checkbox_full_day: event.target.checked,
          });
        }
        else if (name === 'checkbox_public_holiday') {
          setState({
            ...state,
            checkbox_public_holiday: event.target.checked,
          });
        }

        if (data.start_end_time_from_roster) {
          rosterData(selectedDate);
        }
        else {
          setStartTime(null);
          setEndTime(null);
          setBreakStartTime(null);
          setBreakEndTime(null);
          setRosterStart(null);
          setRosterEnd(null);
          setOrdinaryHours(null);
        }
      }
    })
  };


  const sessionOptionData = async () => {
    setLoadingSession(true);
    let listId = [];
    let csaId = [];
    let awardId = [];
    let sessionData = [];
    var OptionData1, OptionData2;

    try {
      if (stateRef.current.data) {
        if (stateRef.current.data.session_options.length > 0) {
          stateRef.current.data.session_options.map((value) => {
            if (value.session_options_id !== null) {
              listId = [...listId, value.session_options_id];
            }
          })

          var EmployeeData = await directus.getItems('employees', {
            fields: 'customer_assigned_to.id,award.id',
            filter: {
              id: { in: employeeIdRef.current }
            },
            limit: -1,
          });

          if (EmployeeData.data.length > 0) {
            EmployeeData.data.map((data) => {
              if (data.customer_assigned_to) {
                if (data.customer_assigned_to.id !== null) {
                  csaId = [...csaId, data.customer_assigned_to.id];
                }
              }
              if (data.award) {
                if (data.award.id !== null) {
                  awardId = [...awardId, data.award.id];
                }
              }
            })
          }

          if (listId.length > 0 && csaId.length > 0) {
            OptionData1 = await directus.getItems('session_options', {
              fields: 'awards.*,customers.*,input_type,description,id,default_value,name,only_overtime,after_overtime,only_rostered_days',
              filter: {
                'id': { in: listId },
                'customers.customers_id': { in: csaId }
              },
              limit: -1,
            });
          }
          else {
            OptionData1 = null;
          }

          if (listId.length > 0 && awardId.length > 0) {
            OptionData2 = await directus.getItems('session_options', {
              fields: 'awards.*,customers.*,input_type,description,id,default_value,name,only_overtime,after_overtime,only_rostered_days',
              filter: {
                'id': { in: listId },
                'awards.awards_id': { in: awardId },
              },
              limit: -1,
            });
          }
          else {
            OptionData2 = null;
          }

          if (OptionData1 && OptionData2) {
            sessionData = OptionData1.data.concat(OptionData2.data);

            var result = Enumerable.from(sessionData)
              .distinct(d => JSON.stringify(d))
              .toArray();

            const output = [...new Map(result.map(o => [o.id, o])).values()];

            setSessionOptions(output);
          }

          else if (OptionData1 === null && OptionData2) {
            setSessionOptions(OptionData2.data);
          }
          else if (OptionData1 && OptionData2 === null) {
            setSessionOptions(OptionData1.data);
          }
        }
        else {
          setSessionOptions([]);
        }
      }
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
    getSessionOptionsData(sessionOptionsRef.current);
    setLoadingSession(false);
  }

  function getSessionOptionsData(sessionOptions) {
    let result = [];

    sessionOptions.map((data, index) => {
      if (data.only_rostered_days && (rosterStartRef.current === 'Invalid date' && rosterEndRef.current === 'Invalid date')) {
        (result[index] = { id: data.id, value: data.default_value, type: '7', data: data })
      }
      else {
        if (data.only_overtime) {
          if ((ordinaryHoursRef.current + (data.after_overtime * 60)) <= getDuration(moment(startTimeRef.current).format("1991-01-01THH:mm:ss"), moment(endTimeRef.current).format("1991-01-01THH:mm:ss"))) {
            //Check if there is overtime and check the type
            (data.input_type !== 'none' ?
              result[index] = { id: data.id, value: data.default_value, type: '1', data: data } //show
              : result[index] = { id: data.id, value: data.default_value, type: '2', data: data })
          }
          else {
            //Check if duration of time is less than roster duration and check the type
            (data.input_type !== 'none' ?
              result[index] = { id: data.id, value: data.default_value, type: '3', data: data }
              : result[index] = { id: data.id, value: data.default_value, type: '4', data: data })
          }
        }
        else {
          //Check if the only overtime is false and check the type
          (data.input_type !== 'none' ?
            result[index] = { id: data.id, value: data.default_value, type: '5', data: data } //show
            : result[index] = { id: data.id, value: data.default_value, type: '6', data: data })
        }
      }
    })
    setOptionsList(result);
  }

  const handleChangeSessionOptions = (event, id) => {
    const { name, value } = event.target;
    setOptionsList(
      optionsListRef.current.map((data) =>
      (id === data.id ?
        name === 'number' ?
          { ...data, value: value }
          :
          { ...data, value: event.target.checked === true ? 'true' : 'false' }
        : data)
      )
    );
  };

  const checkTimeValid = (data) => {
    if (moment(data).isValid() && moment(data).format('HH:mm') !== '00:00') {
      return true;
    }
    else {
      setDuration('');
    }
  }

  function timeConvertBlur() {
    if (duration !== '' || duration !== "0" || duration !== null || duration !== 0) {
      let parts = duration ? duration.split("h") : '';
      let parts2 = duration ? duration.split("m") : '';
      if (parts.length > 1 || parts2.length > 1) {
        if (parts.length === 1) {
          if (checkTimeValid(parseInt(parts[0].slice(0, -1), 10))) {
            setDuration(parseInt(parts[0].slice(0, -1), 10) + "m")
          }
        }
        else {
          if (checkTimeValid((parseInt(parts[0], 10) * 60 + (parseInt(parts[1].slice(0, -1) | 0, 10))))) {
            setDuration((parseInt(parts[0], 10) + "h ") + (parseInt(parts[1].slice(0, -1) | 0, 10) + "m"))
          }
        }
      }
      else {
        var decimalTimeString = duration;
        var n = new Date(0, 0);
        n.setMinutes(+decimalTimeString * 60);
        if (checkTimeValid(n)) {
          setDuration((moment(n).hour() + "h ") + moment(n).minute() + "m");
        }
      }
    }
  }

  function callCustomers() {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      // var employee = await getEmployee();
      try {
        var CustomerEmployeeResult = await directus.getItems('customers', {
          fields: '*',
          filter: {
            selectable: 1,
            status: 'published'
          },
          limit: -1,
          sort: "name"
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }

      const options = CustomerEmployeeResult.data.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
      });

      if (active) {
        setOptions(options);
      }
    })();

    return () => {
      active = false;
    };
  }

  /* eslint-disable react-hooks/exhaustive-deps*/
  React.useEffect(() => {
    callCustomers();
  }, [loading]);
  /* eslint-enable react-hooks/exhaustive-deps*/

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  // //Activity=============================================================================================================
  const [openActivity, setOpenActivity] = useState(false);
  const [optionsActivity, setOptionsActivity] = useState([]);
  const loadingActivity = openActivity && optionsActivity.length === 0;

  function callActivity() {
    let activeActivity = true;

    if (!loadingActivity) {
      return undefined;
    }

    try {
      (async () => {
        var Activities = await directus.getItems('activities', {
          fields: '*',
          limit: -1,
          filter: {
            selectable: 1,
            status: 'published'
          },
          sort: "name"
        });

        if (activeActivity) {
          setOptionsActivity(Object.keys(Activities.data).map((key) => Activities.data[key]));
        }
      })();
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    return () => {
      activeActivity = false;
    };
  }

  /* eslint-disable react-hooks/exhaustive-deps*/
  React.useEffect(() => {
    callActivity();
  }, [loadingActivity]);
  /* eslint-enable react-hooks/exhaustive-deps*/

  React.useEffect(() => {
    if (!openActivity) {
      setOptionsActivity([]);
    }
  }, [openActivity]);


  const handleStartTimeChange = (newValue) => {
    if(newValue)
    {
      newValue.setSeconds(0);
      setStartTime(newValue);
      getSessionOptionsData(sessionOptionsRef.current);
    }
  }

  const handleEndTimeChange = (newValue) => {
    if(newValue)
    {
      newValue.setSeconds(0);
      setEndTime(newValue);
      getSessionOptionsData(sessionOptionsRef.current);
    }
  }

  const handleBreakTimeChange = (e) => {
    setBreakTime(e.target.value);
  }

  const handleBreakStartChange = (newValue) => {
    if(newValue)
    { 
      newValue.setSeconds(0);     
      setBreakStartTime(newValue);
    }
  }

  const handleBreakEndChange = (newValue) => {
    if(newValue)
    {
      newValue.setSeconds(0);
      setBreakEndTime(newValue);
    }
  }

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  }

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    let smokoValue = null;
    if (name === 'smoko') {
      if (value > 20) {
        smokoValue = 20
      }

      if (smokoValue !== null) {
        setSmoko(smokoValue);
      }
      else {
        setSmoko(value);
      }
    }
    else if (name === 'duration') {
      setDuration(value);
    }
  }

  function handleDateChange(value) {
    setSelectedDate(value);
    if (stateRef.current.data) {
      if (stateRef.current.data.start_end_time_from_roster) {
        rosterData(value);
      }
    }
    sessionOptionData();
  }

  const filterOptionsCustomer = createFilterOptions({
    matchFrom: lengthCustomer.length < 2 ? 'start' : 'any',
    stringify: option => option.name,
  });

  const filterOptionsActivity = createFilterOptions({
    matchFrom: lengthActivity.length < 2 ? 'start' : 'any',
    stringify: option => option.name,
  });

  function changeAutoCustomer(value) {
    setAutoCustomer(value);
  }

  function changeAutoActivity(value) {
    setAutoActivity(value);
  }

  const handleChangeUpload = (e) => {

    if (e.target.files.length > 0) {
      setFile([...fileRef.current, e.target.files[0]]);
    }
  }

  const handleDeleteFile = (index) => {
    setFile([]);
    document.getElementById('contained-button-file').value = "";
  }

  const uploadButton = () => {
    return (
      <UploadButton
        id="attach_file_btn"
        name="file"
        variant="contained"
        component="span"
        startIcon={<FileUploadIcon />}
        onChange={(e) => handleChangeUpload(e)}
        disabled={fileRef.current.length > 0}
      >
        <Typography className={classes.fontstyle6}>
          {stateRef.current.data.upload_description}
        </Typography>
      </UploadButton>
    );
  }

  const uploadList = () => {
    return (
      <>
        {fileRef.current.length > 0 ?
          fileRef.current.map((filedata, index) => {
            return (
              <FileChip
                key={index}
                icon={<InsertDriveFileIcon />}
                label={filedata.name}
                className={classes.chipcustom}
                onDelete={() => handleDeleteFile(index)}
              />
            );
          })
          : ''}
      </>
    );
  }

  const fillResourceData = async () => {
    try {
      var allEquipment = await directus.getItems('resources', {
        fields: '*',
        filter: {
          type: { eq: 'equipment' },
          status: { eq: 'published' }
        },
        limit: -1,
        sort: "name"
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    setAllEquipmentData(allEquipment.data);

    try {
      var allMaterial = await directus.getItems('resources', {
        fields: '*',
        filter: {
          type: { eq: 'chemical' },
          status: { eq: 'published' }
        },
        limit: -1,
        sort: "name"
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    setAllMaterialData(allMaterial.data);
    return null;
  }
  //Equipment===============================================================================================================
  const addEquipment = (equip) => {
    if (equip === null) {
      let data = [];
      data.list_id = equipmentData.length + 1;
      data.value = 2;
      setEquipmentData([...equipmentData, data]);
    }
    else {
      let var_list_id = equipmentData.length + 1;
      let var_value = 2;
      setEquipmentData([...equipmentData, { list_id: var_list_id, ...equip, value: var_value }]);
    }
  };

  const deleteEquipment = (equipData) => {
    if (equipData.value === 2) {
      setEquipmentData(equipmentData.filter((equip) => equip.list_id !== equipData.list_id));
    }
    else {
      setEquipmentData(equipmentData.map((equip) => equip.list_id === equipData.list_id ?
        { list_id: equipData.list_id, ...equipData, value: 3, resourceDataId: equipData.resourceDataId }
        : equip)
      );
    }
  };

  const updateEquipment = (newData, oldData) => {
    setEquipmentData(
      equipmentData.map((equip) => (equip.list_id === oldData.list_id ?
        oldData.value === 2 ?
          { list_id: oldData.list_id, ...newData, value: 2 }
          :
          { list_id: oldData.list_id, ...newData, value: 1, resourceDataId: oldData.resourceDataId }
        : equip)
      )
    );
  };

  const LoopEquipmentData = (Data) => {

    return (
      equipmentData && allEquipmentData ?
        Data.map((equipData, index) => {
          if (equipData.value !== 3) {
            return (
              <Grid key={`equipment-wrapper-${index}`} container spacing={0}>
                <Grid item xs={11} zeroMinWidth>
                  <AutocompleteMaterialEquipmentCustom
                    key={index}
                    size="small"
                    options={allEquipmentData}
                    getOptionLabel={(option) => {
                      if (option == null || option === "") {
                        return "";
                      }
                      return option.name;
                    }}
                    id={`AutoComplete-equip-${index}`}
                    onChange={(event, newValue) => {
                      updateEquipment(newValue, equipData);
                    }}
                    groupBy={(option) => option.firstLetter}
                    disableClearable
                    renderInput={(params) =>
                      <Typography component='span' noWrap>
                        <TextFieldCustomerActivityCustom {...params} variant="outlined" />
                      </Typography>
                    }
                    renderGroup={(params) => (
                      <li key={`equipment-list-${index}`} className={classes.fontstyle6}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    )}
                  />
                </Grid>
                <Grid item xs={1}>
                  <RemoveCircleIcon
                    id="iconButton"
                    color="error"
                    style={{ fontSize: 20, float: 'right' }}
                    onClick={() => deleteEquipment(equipData)}
                  />
                </Grid>
              </Grid>
            );
          }
          else {
            return '';
          }
        })
        : ''
    );
  }
  //Equipment================================================================================================================
  //Material+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const addMaterial = (material, qty) => {
    if (material === null) {
      let data = [];
      data.list_id = materialData.length + 1;
      data.value = 2;
      data.quantity = 0;
      setMaterialData([...materialData, data]);
    }
    else {
      let var_quantity = qty;
      let var_list_id = materialData.length + 1;
      let var_value = 2;
      setMaterialData([...materialData, { list_id: var_list_id, ...material, value: var_value, quantity: var_quantity }]);
    }
  };

  const deleteMaterial = (chemiData) => {
    if (chemiData.value === 2) {
      setMaterialData(materialData.filter((material) => material.list_id !== chemiData.list_id));
    }
    else {
      setMaterialData(materialData.map((material) => material.list_id === chemiData.list_id ?
        { list_id: chemiData.list_id, ...chemiData, value: 3, resourceDataId: chemiData.resourceDataId, quantity: chemiData.quantity }
        : material)
      );
    }
  };

  const updateMaterial = (newData, oldData) => {
    setMaterialData(
      materialData.map((material) => (material.list_id === oldData.list_id ?
        oldData.value === 2 ?
          { list_id: oldData.list_id, ...newData, value: 2, quantity: oldData.quantity }
          :
          { list_id: oldData.list_id, ...newData, value: 1, resourceDataId: oldData.resourceDataId, quantity: oldData.quantity }
        : material)
      )
    );
  };

  const handleChange = (e, oldData) => {
    const { name, value } = e.target;
    setMaterialData(
      materialData.map((material) =>
      (material.list_id === oldData.list_id ?
        oldData.value === 2 ?
          { ...material, value: 2, [name]: value }
          :
          { ...material, value: 1, [name]: value }
        : material)
      )
    );
  }

  const LoopMaterialData = (Data) => {
    return (
      Data.map((materialData, index) => {
        if (materialData.value !== 3) {
          return (
            <Grid container key={`material-wrapper-${index}`} spacing={0}>
              <Grid item xs={8}>
                <AutocompleteMaterialEquipmentCustom
                  key={index}
                  size="small"
                  options={allMaterialData}
                  getOptionLabel={(option) => {
                    if (option == null || option === "") {
                      return "";
                    }
                    return option.name;
                  }}
                  groupBy={(option) => option.firstLetter}
                  id={`AutoComplete-material-${index}`}
                  onChange={(event, newValue) => {
                    updateMaterial(newValue, materialData);
                  }}
                  disableClearable
                  renderInput={(params) =>
                    <TextFieldCustomerActivityCustom
                      {...params}
                      variant="outlined"
                    />}

                  renderGroup={(params) => (
                    <li key={`material-list-${index}`} className={classes.fontstyle6}>
                      <GroupHeader>{params.group}</GroupHeader>
                      <GroupItems>{params.children}</GroupItems>
                    </li>
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <TextFieldSessionOptionValueEditCustom name="quantity" onChange={(event) => handleChange(event, materialData)} value={materialData.quantity} variant="outlined" label='Kg/L' size="small" type="number" step="any" />
              </Grid>
              <Grid item xs={1}>
                <RemoveCircleIcon
                  id="iconButton"
                  color="error"
                  style={{ fontSize: 20 }}
                  onClick={() => deleteMaterial(materialData)}
                />
              </Grid>
            </Grid>
          );
        }
        else {
          return '';
        }
      })
    );
  }
  //Material+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const rosterData = async (selectDate) => {
    setDateLoading(true);
    try {
      var rosterList = await directus.getItems('roster',
        {
          fields: 'break, day, start_time, end_time, ordinary_hours',
          filter: {
            // employee: { eq: props.employeeIdChosen },
            employee: { eq: employeeIdRef.current },
            day: { eq: selectDate ? moment(selectDate).format('dddd') : moment(selectedDate).format('dddd').toLowerCase() },
            status: { eq: 'published' },
          },
        });
    }

    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    if (rosterList.data.length > 0) {
      rosterList.data.map((data) => {
        let end_time = '';
        if (!stateRef.current.data.leave) {
          end_time = data.end_time;
        }
        else {
          end_time = moment('1/1/1991 ' + data.start_time).add(data.ordinary_hours, 'hours').add(data.break, 'minutes').format("HH:mm:ss");
        }

        setRosterStart(moment('1/1/1991 ' + data.start_time).format());
        setRosterEnd(moment('1/1/1991 ' + end_time).format());
        setOrdinaryHours(data.ordinary_hours*60);
        setStartTime(moment('1/1/1991 '+ data.start_time).format());
        setEndTime(moment('1/1/1991 '+ end_time ).format());
        setBreakStartTime(getMiddleStartEndTime(data.start_time, end_time,data.break).start_time.format());
        setBreakEndTime(getMiddleStartEndTime(data.start_time, end_time, data.break).end_time.format());
      })
    }
    else {
      setRosterStart(null);
      setRosterEnd(null);
      setOrdinaryHours(null);
      setStartTime(null);
      setEndTime(null);
      setBreakStartTime(null);
      setBreakEndTime(null);
    }
    setDateLoading(false);
  }

  function getMiddleStartEndTime(a, b, c) {
    var basedate = '1/1/1991 ';
    var momentStart = moment(basedate + a);
    var momentEnd = moment(basedate + b);
    var duration = moment.duration(momentEnd.diff(momentStart));
    var diff = duration.asMinutes() / 2 - (c / 2);
    var start = moment(basedate + a).add(diff, 'minutes').format();
    var end = moment(start).add(c, 'minutes').format();

    return { start_time: moment(start), end_time: moment(end) };
  }

  function getDuration(a, b) {
    var momentStart = moment(a);
    var momentEnd = moment(b);
    var duration = moment.duration(momentEnd.diff(momentStart));
    var diff = duration.asMinutes();
    return diff;
  }


  return (
    <Box className={classes.List}>
      <List>
        <ListItem>
          <ListItemText>
            <Typography className={classes.tabstyle}>Add Timesheet Entry</Typography>
          </ListItemText>
        </ListItem>        
        <Divider style={{ margin: '0px 16px 10px 16px' }} />
        {loadingAddSession ?
          <Card className={classes.cardCustom}>
            <CardContent>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item xs={12} style={{ marginBottom: '5px' }}>
                  <Skeleton variant="rounded" width={50} height={16} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                  <Skeleton variant="rounded" width={260} height={24} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '5px' }}>
                  <Skeleton variant="rounded" width={50} height={16} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                  <Skeleton variant="rounded" width={260} height={24} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '5px' }}>
                  <Skeleton variant="rounded" width={50} height={16} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                  <Skeleton variant="rounded" width={260} height={24} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '5px' }}>
                  <Skeleton variant="rounded" width={50} height={16} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                  <Skeleton variant="rounded" width={260} height={54} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '5px' }}>
                  <Skeleton variant="rounded" width={50} height={16} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                  <Skeleton variant="rounded" width={260} height={54} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '5px' }}>
                  <Skeleton variant="rounded" width={50} height={16} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                  <Skeleton variant="rounded" width={260} height={54} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '5px' }}>
                  <Skeleton variant="rounded" width={50} height={16} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                  <Skeleton variant="rounded" width={260} height={54} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          :
          <Card className={classes.cardCustom}>
            <CardContent>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                {stateRef.current.data ?
                  <>
                    {stateRef.current.data.full_day ?
                      <Grid item xs={12}>
                        <FormControlLabelCustom
                          control={
                            <SwitchCustom
                              name="checkbox_full_day"
                              checked={stateRef.current.checkbox_full_day}
                              onChange={(e) => handleChangeChecked(e, state.data.id)}
                            />}
                          label={<Typography className={classes.fontstyle5}>Full Day</Typography>}
                          labelPlacement="end"
                        />
                      </Grid>
                      : ''}
                    {stateRef.current.data.public_holiday ?
                      <Grid item xs={12}>
                        <FormControlLabelCustom
                          control={
                            <SwitchCustom
                              name="checkbox_public_holiday"
                              checked={stateRef.current.checkbox_public_holiday}
                              onChange={(e) => handleChangeChecked(e, state.data.id)}
                            />}
                          label={<Typography className={classes.fontstyle5}> Did you work on the public holiday?</Typography>}
                          labelPlacement="end"
                        />
                      </Grid>
                      : ''}
                  </>
                  : ''}
                <Grid item xs={12}>
                  <Typography className={classes.fontstyle5}>Type</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControlCustom variant="outlined" fullWidth>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="type_select"
                      value={selectData ? selectData.length > 0 ? stateRef.current.select : '' : ''}
                      onChange={handleChangeType}
                    >
                      {!loadingData ?
                        Object.keys(selectData).map(function (key) {
                          if (selectData[key].name !== 'Clock In') {
                            return <MenuItem className={classes.MenuItemCustom} id={`type_${selectData[key].name.replace(/\s/g, '')}`} key={key} value={selectData[key].name}>{selectData[key].name}</MenuItem>
                          }
                        })
                        : <CircularProgress color="inherit" size={15} />}
                    </Select>
                  </FormControlCustom>
                </Grid>

                <Grid item xs={12}>
                  <Typography className={classes.fontstyle5}>Date</Typography>
                </Grid>
                <Grid item xs={12}>
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <EventIcon sx={{ position: 'absolute', right: '16px', padding: '3px', color: '#757575' }} />
                      <MobileDatePicker
                        format="D MMMM YYYY"
                        value={selectedDateRef.current ?? ''}
                        name="selected-date"
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} />}
                        className={classes.DatePickerCustom}
                        maxDate={timesheetEnd}
                        minDate={timesheetStart}
                      />
                    </LocalizationProvider>
                  </>
                </Grid>

                {stateRef.current.data ?
                  <>
                    {stateRef.current.data.show_customer ?
                      <>
                        <Grid item xs={12}>
                          <Typography className={classes.fontstyle5}>Customer</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <AutocompleteCustom
                            disablePortal
                            id="autoCustomer"
                            open={open}
                            onFocus={() => {
                              setOpen(true);
                            }}
                            onClose={() => {
                              setOpen(false);
                            }}
                            value={autoCustomer?autoCustomer:''}
                            options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => {
                              if (option == null || option === "") {
                                return "";
                              }
                              return option.name;
                            }}
                            onChange={(event, newValue) => {
                              changeAutoCustomer(newValue);
                            }}
                            filterOptions={filterOptionsCustomer}
                            size="small"
                            loading={loading}
                            renderInput={(params) => (
                              <TextFieldCustomerActivityCustom
                                {...params}
                                variant="outlined"
                                onChange={(e) => setLengthCustomer(e.target.value)}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {loading ? <CircularProgress color="inherit" size={15} /> : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}

                              />
                            )}
                            renderGroup={(params) => (
                              <li key={`customer-list${params.key}`} className={classes.fontstyle6}>
                                <GroupHeader>{params.group}</GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                              </li>
                            )}
                          />
                        </Grid>
                      </>
                      : ''}

                    {stateRef.current.data.show_activity ?
                      <>
                        <Grid item xs={12}>
                          <Typography className={classes.fontstyle5}>Activity</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <AutocompleteCustom
                            disablePortal
                            id="autoActivity"
                            open={openActivity}
                            onFocus={() => {
                              setOpenActivity(true);
                            }}
                            onClose={() => {
                              setOpenActivity(false);
                            }}
                            value={autoActivity?autoActivity:''}
                            getOptionSelected={(option, value) => option.name === value.name}
                            getOptionLabel={(option) => {
                              if (option == null || option === "") {
                                return "";
                              }
                              return option.name;
                            }}
                            options={optionsActivity}
                            onChange={(event, newValue) => {
                              changeAutoActivity(newValue);
                            }}
                            filterOptions={filterOptionsActivity}
                            size="small"
                            loading={loadingActivity}
                            groupBy={(option) => option.firstLetter}
                            renderInput={(params) => (
                              <TextFieldCustomerActivityCustom
                                {...params}
                                variant="outlined"
                                onChange={(e) => setLengthActivity(e.target.value)}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {loadingActivity ? <CircularProgress color="inherit" size={15} /> : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                            renderGroup={(params) => (
                              <li key={`activity-list${params.key}`} className={classes.fontstyle6}>
                                <GroupHeader>{params.group}</GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                              </li>
                            )}
                          />
                        </Grid>
                      </>
                      : ''}

                    {stateRef.current.data.show_start_end_time ?
                      !stateRef.current.checkbox_full_day && stateRef.current.checkbox_public_holiday ?
                        <>
                          <Grid item xs={12}>
                            <Typography className={classes.fontstyle5}>Start Time</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TimePickerCustom name='start-time' handleChange={handleStartTimeChange} value={startTimeRef.current} />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography className={classes.fontstyle5}>End Time</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TimePickerCustom name='end-time' handleChange={handleEndTimeChange} value={endTimeRef.current} />
                          </Grid>
                        </>
                        : ''
                      : ''
                    }

                    {stateRef.current.data.show_break_start_end_time ?
                      !stateRef.current.checkbox_full_day && stateRef.current.checkbox_public_holiday ?
                        <>
                          <Grid item xs={12}>
                            <Typography className={classes.fontstyle5}>Break Start Time</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TimePickerCustom name='break-start-time' handleChange={handleBreakStartChange} value={breakStartTime} />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography className={classes.fontstyle5}>Break End Time</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TimePickerCustom name='break-end-time' handleChange={handleBreakEndChange} value={breakEndTime} />
                          </Grid>
                        </>
                        : ''
                      : ''}

                    {stateRef.current.data.show_break_time ?
                      !stateRef.current.checkbox_full_day && stateRef.current.checkbox_public_holiday ?
                        <>
                          <Grid item xs={12}>
                            <Typography className={classes.fontstyle5}>Break Time</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TextFieldCustom
                              fullWidth
                              type='number'
                              id="selected-date"
                              value={breakTime ?? ''}
                              onChange={handleBreakTimeChange}
                            />
                          </Grid>
                        </>
                        : ''
                      : ''}

                    {stateRef.current.data.show_customer ?
                      <>
                        {/* material data */}
                        <Grid item xs={12}>
                          {allMaterialData ?
                            <AddMaterial
                              addMaterial={addMaterial}
                              allMaterialData={allMaterialData}
                            />
                            : ''}
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: '10px' }}>
                          {LoopMaterialData(materialData)}
                        </Grid>

                        {/* equipment data */}
                        <Grid item xs={12}>
                          {allEquipmentData ?
                            <AddEquipment
                              addEquipment={addEquipment}
                              allEquipmentData={allEquipmentData}
                            />
                            : ''}
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: '10px' }}>
                          {LoopEquipmentData(equipmentData)}
                        </Grid>
                      </>
                      : ''}

                    {stateRef.current.data.show_duration || stateRef.current.data.show_duration_admin_only?
                      !stateRef.current.checkbox_full_day && stateRef.current.checkbox_public_holiday ?
                        <>
                          <Grid item xs={12}>
                            <Typography className={classes.fontstyle5}>Duration</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TextFieldCustom
                              fullWidth
                              name="duration"
                              placeholder="1h 30m"
                              value={duration ?? ''}
                              disabled={props.timesheets.employee.user.role !== 7?true:false}
                              onChange={handleChangeInput}
                              onBlur={timeConvertBlur}
                            />
                          </Grid>
                        </>
                        : ''
                      : ''}

                    {stateRef.current.data.upload_description ?
                      <>
                        <Grid item xs={12}>
                          <Typography className={classes.fontstyle6}>{stateRef.current.data.upload_description}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {loadingFile ?
                            <span display="inline-flex"><CircularProgress size={20} /></span>
                            :
                            <form id="my-form" className={classes.form}>
                              <input type="file" name="file"
                                onChange={(e) => handleChangeUpload(e)}
                                id="contained-button-file"
                                className={classes.input}
                              />
                              <label htmlFor="contained-button-file">
                                {fileRef.current.length === 0 ?
                                  uploadButton()
                                  : ''
                                }
                              </label>
                            </form>
                          }
                          {uploadList()}
                        </Grid>
                      </>
                      : ''}
                  </>
                  : ''}
                <Grid item xs={12}>
                  <Typography className={classes.fontstyle5}>Notes</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldNotesCustom
                    fullWidth
                    id='notes'
                    name="notes"
                    onChange={handleNotesChange}
                    value={notesRef.current ?? ''}
                    variant="outlined"
                    rows={4}
                    multiline
                  />
                </Grid>

                {stateRef.current.data ?
                  stateRef.current.data.session_options.length > 0 ?
                    <>
                      <Grid item xs={12}>
                        {optionsListRef.current ?
                          loadingSession ?
                            <LinearProgress sx={{ m: '10px' }} />
                            :
                            <>
                              {optionsListRef.current.map((optionlist, index) => {
                                return (
                                  optionlist.type === "1" || optionlist.type === "5" ?
                                    <React.Fragment key={`option-list-wrapper${index}`}>
                                      <Grid container spacing={0} justifyContent="space-evenly" alignItems="center">
                                        <Grid item xs={10}>
                                          <TextFieldSessionOptionCustom
                                            fullWidth
                                            multiline
                                            id="selected-date"
                                            defaultValue={optionlist.data.description}
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                          />
                                        </Grid>
                                        <Grid item xs={2}>
                                          <Grid container spacing={0} justifyContent="center" alignItems="center">
                                            {optionlist.data.input_type === "checkbox" ?
                                              <FormGroup>
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      id="checkbox"
                                                      name="checkbox"
                                                      disableRipple
                                                      size="small"
                                                      style={{ marginLeft: '28px' }}
                                                      sx={{
                                                        "&.Mui-checked": {
                                                          color: "#05a0af"
                                                        }
                                                      }}
                                                      checked={optionlist.value === "true"}
                                                      onChange={(e) => handleChangeSessionOptions(e, optionlist.data.id)}
                                                    />}
                                                />
                                              </FormGroup>
                                              :
                                              <TextFieldSessionOptionValueEditCustom
                                                id="outlined-number"
                                                name="number"
                                                type="number"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                variant="outlined"
                                                size="small"
                                                value={optionlist.value ? optionlist.value : 0}
                                                onChange={(e) => handleChangeSessionOptions(e, optionlist.data.id)}
                                              />
                                            }
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Divider style={{ marginBottom: '5px' }} />
                                    </React.Fragment>
                                    : '');
                              })
                              }
                            </>
                          : ''}
                      </Grid>
                    </>
                    : ''
                  : ''}

                {stateRef.current.data ?
                  <>
                    {stateRef.current.data.show_customer || stateRef.current.data.show_activity ?
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<CheckboxCustom id="create_another_cb" checked={createAnother} onChange={e => { setCreateAnother(e.target.checked); }} />}
                          label={<Typography className={classes.fontstyle5}>Create Another</Typography>}
                        />
                      </Grid>
                      : ''}

                    {createAnother ?
                      <>
                        {stateRef.current.data.show_customer?
                          <Grid item xs={6}>
                            <FormControlLabel
                              control={<Checkbox id="keep_customer_cb" checked={keepCustomer} onChange={e => { setKeepCustomer(e.target.checked); }} />}
                              label={<Typography className={classes.fontstyle5}>Keep Customer</Typography>}
                            />
                          </Grid>
                        :''}
                        {stateRef.current.data.show_activity?
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={<Checkbox id="keep_activity_cb" checked={keepActivity} onChange={e => { setKeepActivity(e.target.checked); }} />}
                            label={<Typography className={classes.fontstyle5}>Keep Activity</Typography>}
                          />
                        </Grid>
                        :''}                        
                      </>
                    : ''}
                  </>
                  : ''}

                <Grid item xs={12}>
                  <SaveAddSession
                    type={stateRef.current}
                    selectedDate={selectedDate}
                    sessionNotes={notes}
                    startTime={moment(startTime).format("HH:mm:ss")}
                    endTime={moment(endTime).format("HH:mm:ss")}
                    breakStartTime={moment(breakStartTime).format("HH:mm:ss")}
                    breakEndTime={moment(breakEndTime).format("HH:mm:ss")}
                    sessionDuration={duration}
                    sessionBreakTime={breakTime}
                    allmaterialData={materialData}
                    allEquipmentData={equipmentData}
                    customer={autoCustomer}
                    activity={autoActivity}
                    session={stateRef.current}
                    employeeId={employeeId}
                    timesheetId={timesheetId}
                    fileUpload={fileRef.current}
                    optionsList={optionsListRef.current}
                    tickCustomer={keepCustomer}
                    tickActivity={keepActivity}
                    timesheetStartDate={timesheetStart}
                    timesheetEndDate={timesheetEnd}
                    timesheetStatus={timesheetStatus}
                    enableLateSessions={props.enable_late_sessions}
                    timesheetExtra={timesheetExtra}
                    employeeRole={props.timesheets.employee.user.role}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        }


      </List>
    </Box>
  );
};


export default AddSession;
