import React, { useEffect, useState } from 'react';
import {
  Box,
  List,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import directus from '../../../services/directus';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../../views/Components/ErrorMessage';
import linq from "linq";
import moment from 'moment';
import useStateRef from "react-usestateref";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import EventEmitter from 'src/utils/EventEmitter';
import TextField from '@mui/material/TextField';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import EditIcon from '@mui/icons-material/Edit';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Tooltip from '@mui/material/Tooltip';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import dayjs from 'dayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Enumerable from 'linq';
import Checkbox from '@mui/material/Checkbox';
import { LinearProgress } from '@mui/material';
import SaveEditSession from './SaveEditSession';
import {
  useStylesEditSession,
  FileChip,
  TextFieldCustom,
  TextFieldCustomerActivityCustom,
  TextFieldNotesCustom,
  TextFieldSessionOptionCustom,
  TextFieldSessionOptionValueEditCustom,
  AutocompleteCustom,
  UploadButton,
  GroupHeader,
  GroupItems,
  AutocompleteMaterialEquipmentCustom,
  FormControlLabelCustom,
  SwitchCustom,
  TimePickerCustom,
} from './style.js';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddEquipment from './AddEquipment/AddEquipment';
import AddMaterial from './AddMaterial/AddMaterial';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EventIcon from '@mui/icons-material/Event';

const TimesheetEntry = (props) => {
  /* eslint-disable no-unused-vars*/
  const classes = useStylesEditSession();
  const { enqueueSnackbar } = useSnackbar();
  const [sessionID, setSessionID] = React.useState(null);

  const [type, setType] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [breakTime, setBreakTime] = React.useState(null);
  const [breakStartTime, setBreakStartTime] = React.useState(null);
  const [breakEndTime, setBreakEndTime] = React.useState(null);
  const [duration, setDuration] = React.useState(null);
  const [notes, setNotes] = React.useState(null);
  const [uploadDescription, setUploadDescription] = React.useState(null);

  const [timesheetStart, setTimesheetStart] = useState(null);
  const [timesheetEnd, setTimesheetEnd] = useState(null);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [smoko, setSmoko] = React.useState(props.session.break_time ? props.session.break_time : 0);


  const [lengthCustomer, setLengthCustomer] = React.useState('');
  const [lengthActivity, setLengthActivity] = React.useState('');
  const [fullDay, setFullDay] = React.useState(false);
  const [publicHoliday, setPublicHoliday] = React.useState(true);
  const [mode, setMode, modeRef] = useStateRef('read');

  const loading = open && options.length === 0;
  let material = [];
  let equipment = [];
  let session_options_list = [];

  const [sessionData, setSessionData] = useStateRef(props.session);
  const [materialData, setMaterialData] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [allMaterialData, setAllMaterialData] = useState([]);
  const [allEquipmentData, setAllEquipmentData] = useState([]);

  const [file, setFile, fileRef] = useStateRef([]);
  const [delFile, setDelFile, delFileRef] = useStateRef([]);
  const [loadingFile, setLoadingFile] = React.useState(false);
  const [sessionOptions, setSessionOptions, sessionOptionsRef] = useStateRef([]);
  const [newSessionOptions, setNewSessionOptions, newSessionOptionsRef] = useStateRef([]);
  const [optionsList, setOptionsList, optionsListRef] = useStateRef([]);
  const [loadingSession, setLoadingSession] = React.useState(false);

  const [rosterStart, setRosterStart, rosterStartRef] = useStateRef(null);
  const [rosterEnd, setRosterEnd, rosterEndRef] = useStateRef(null);
  const [rosterBreakStart, setRosterBreakStart, rosterBreakStartRef] = useStateRef(null);
  const [rosterBreakEnd, setRosterBreakEnd, rosterBreakEndRef] = useStateRef(null);
  const [ordinaryHours, setOrdinaryHours, ordinaryHoursRef] = useStateRef(null);
  const [activity, setActivity] = React.useState(null);
  const [customer, setCustomer] = React.useState(null);
  
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      loadNewSessionOptionData(sessionData);
      if(sessionData !== null){
        rosterData(sessionData.session_date);
      }
    }

    const compareRoster = (eventData) => {
      getSessionOptionsData(sessionOptionsRef.current, eventData.editStartTime, eventData.editEndTime);
    }

    const compareDate = async (eventData) => {
      loadNewSessionOptionData(sessionData, 1);

      await rosterData(eventData.selectedDate);
      getSessionOptionsData(sessionOptionsRef.current, eventData.editStartTime, eventData.editEndTime);
    }

    const disableEdit = async (eventData) => {
      handleClickOpen(eventData.text);
    }

    const listenerDisableEdit = EventEmitter.addListener('dashboardDrawerDisableEdit', disableEdit);
    const listener = EventEmitter.addListener('compareDate', compareDate);
    const listener1 = EventEmitter.addListener('compareRoster', compareRoster);

    return () => {
      listenerDisableEdit.remove();
      listener.remove();
      listener1.remove();
      isSubscribed = false
    }
  }, [sessionData]);

  const dashboardDrawer = (data, session, timesheets) => {
    EventEmitter.emit('dashboardDrawer', {
      text: data,
      session: session,
      timesheets: timesheets,
      page: props.page
    })

    EventEmitter.emit('dashboardDrawerDisableEdit', {
      text: "read"
    })
  }

  //load new session options data
  const loadNewSessionOptionData = async (sessions, condition = 0) => {
    setLoadingSession(true);
    let newData = [];
    let newInfo = [];
    let countSessionOptions = 0;
    if (sessions.options) {
      sessions.options.map((session_options) => {
        if (session_options.session_option !== null) {
          session_options_list[countSessionOptions] = { ...session_options };
          countSessionOptions = countSessionOptions + 1;
        }
      })
    }

    let listId = [];
    let csaId = [];
    let awardId = [];
    let sessionData = [];
    var OptionData1, OptionData2;

    try {
      if (sessions.session_type.session_options) {
        if (sessions.session_type.session_options.length > 0) {
          sessions.session_type.session_options.map((value) => {
            if (value.session_options_id !== null) {
              listId = [...listId, value.session_options_id];
            }
          })

          var EmployeeData = await directus.getItems('employees', {
            fields: 'customer_assigned_to.id,award.id',
            filter: {
              'id': { in: props.timesheets.employee.id }
            },
            limit: -1,
          });

          if (EmployeeData.data.length > 0) {
            EmployeeData.data.map((data) => {
              if (data.customer_assigned_to) {
                if (data.customer_assigned_to.id !== null) {
                  csaId = [...csaId, data.customer_assigned_to.id];
                }
              }
              if (data.award) {
                if (data.award.id !== null) {
                  awardId = [...awardId, data.award.id];
                }
              }
            })
          }

          if (listId.length > 0 && csaId.length > 0) {
            OptionData1 = await directus.getItems('session_options', {
              fields: 'awards.*,customers.*,input_type,description,id,default_value,name,only_overtime,after_overtime,only_rostered_days',
              filter: {
                'id': { in: listId },
                'customers.customers_id': { in: csaId }
              },
              limit: -1,
            });
          }
          else {
            OptionData1 = null;
          }

          if (listId.length > 0 && awardId.length > 0) {
            OptionData2 = await directus.getItems('session_options', {
              fields: 'awards.*,customers.*,input_type,description,id,default_value,name,only_overtime,after_overtime,only_rostered_days',
              filter: {
                'id': { in: listId },
                'awards.awards_id': { in: awardId }
              },
              limit: -1,
            });
          }
          else {
            OptionData2 = null;
          }

          if (OptionData1 && OptionData2) {
            sessionData = OptionData1.data.concat(OptionData2.data);

            var result = Enumerable.from(sessionData)
              .distinct(d => JSON.stringify(d))
              .toArray();

            const output = [...new Map(result.map(o => [o.id, o])).values()];

            setNewSessionOptions(output);
          }
          else if (OptionData1 === null && OptionData2) {
            setNewSessionOptions(OptionData2.data);
          }
          else if (OptionData1 && OptionData2 === null) {
            setNewSessionOptions(OptionData1.data);
          }
        }
        else {
          setNewSessionOptions([]);
        }
      }
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    if (newSessionOptionsRef.current.length > 0) {
      let count = 1;
      newSessionOptionsRef.current.map(data => {
        const filterInput = linq.from(session_options_list)
          .where(x => x.session_option.id === data.id).toArray();

        if (filterInput.length === 0) {
          newData = [...newData,
          {
            id: count,
            session_option:
            {
              id: data.id,
              after_overtime: data.after_overtime,
              description: data.description,
              default_value: data.default_value,
              input_type: data.input_type,
              name: data.name,
              only_overtime: data.only_overtime,
              only_rostered_days: data.only_rostered_days,
            },
            value: data.default_value
          }];
          count++;
        }
      })
      newInfo = session_options_list.concat(newData);
      setSessionOptions(newInfo);
      if (condition === 0) {
        getSessionOptionsData(newInfo);
      }
    }
    else {
      setSessionOptions(session_options_list);
      if (condition === 0) {
        getSessionOptionsData(session_options_list);
      }
    }
    setLoadingSession(false);
  }

  function getSessionOptionsData(sessionOption, editStartTime = null, editEndTime = null) {
    let result = [];
    sessionOption.map((data, index) => {
      if (data.session_option.only_rostered_days && ((rosterStartRef.current === 'Invalid date' && rosterEndRef.current === 'Invalid date') || (rosterStartRef.current === null && rosterEndRef.current === null))) {
        (result[index] = { type: '7', id: data.id, session_option: data.session_option, value: data.value })
      }
      else {

        if (data.session_option.only_overtime) {
          if ((ordinaryHoursRef.current + (data.session_option.after_overtime * 60)) <= getDuration(moment(editStartTime ? editStartTime : '1991-01-01 ' + sessionData.session_start_time).format("1991-01-01THH:mm:ss"), moment(editEndTime ? editEndTime : '1991-01-01 ' + sessionData.session_end_time).format("1991-01-01THH:mm:ss"))) {
            //Check if there is overtime and check the type
            (data.session_option.input_type !== 'none' ?
              result[index] = { type: '1', id: data.id, session_option: data.session_option, value: data.value } //show
              : result[index] = { type: '2', id: data.id, session_option: data.session_option, value: data.value })
          }
          else {
            //Check if duration of time is less than roster duration and check the type
            (data.session_option.input_type !== 'none' ?
              result[index] = { type: '3', id: data.id, session_option: data.session_option, value: data.value }
              : result[index] = { type: '4', id: data.id, session_option: data.session_option, value: data.value })
          }
        }
        else {
          //Check if the only overtime is false and check the type
          (data.session_option.input_type !== 'none' ?
            result[index] = { type: '5', id: data.id, session_option: data.session_option, value: data.value } //show
            : result[index] = { type: '6', id: data.id, session_option: data.session_option, value: data.value })
        }
      }
    })
    setOptionsList(result);
  }

  const rosterData = async (session) => {

    try {
      var rosterList = await directus.getItems('roster',
        {
          fields: 'break, day, start_time, end_time, ordinary_hours',
          filter: {
            employee: { eq: props.timesheets?props.timesheets.employee.id:null },
            day: { eq: moment(session).format('dddd').toLowerCase() },
            status: { eq: 'published' },
          }
        });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
    
    if (rosterList.data.length > 0 && props.session.session_type.start_end_time_from_roster) {
      rosterList.data.map((data) => {
        let end_time = '';
        if (!sessionData.session_type.leave) {
          end_time = data.end_time;
        }
        else {
          end_time = moment('1/1/1991 ' + data.start_time).add(data.ordinary_hours, 'hours').add(data.break, 'minutes').format("HH:mm:ss");
        }

        // let end_time = '';
        // end_time = moment('1/1/1991 '+data.start_time).add(data.ordinary_hours, 'hours').add(data.break, 'minutes').format("HH:mm:ss");

        setRosterStart(moment('1/1/1991 ' + data.start_time).format());
        setRosterEnd(moment('1/1/1991 ' + end_time).format());
        setRosterBreakStart(getMiddleStartEndTime(data.start_time, end_time, data.break).start_time.format());
        setRosterBreakEnd(getMiddleStartEndTime(data.start_time, end_time, data.break).end_time.format());
        setOrdinaryHours(data.ordinary_hours * 60);

      });
    }
    else {
      setRosterStart(null);
      setRosterEnd(null);
      setRosterBreakStart(null);
      setRosterBreakEnd(null);
      setOrdinaryHours(null);
    }
  }

  function getMiddleStartEndTime(a, b, c) {
    var basedate = '1/1/1991 ';
    var momentStart = moment(basedate + a);
    var momentEnd = moment(basedate + b);
    var duration = moment.duration(momentEnd.diff(momentStart));
    var diff = duration.asMinutes() / 2 - (c / 2);
    var start = moment(basedate + a).add(diff, 'minutes').format();
    var end = moment(start).add(c, 'minutes').format();

    return { start_time: moment(start), end_time: moment(end) };
  }


  function getDuration(a, b) {
    var momentStart = moment(a);
    var momentEnd = moment(b);
    var duration = moment.duration(momentEnd.diff(momentStart));
    var diff = duration.asMinutes();

    return diff;
  }


  const handleChangeSessionOptions = (event, id) => {
    const { name, value } = event.target;
    setOptionsList(
      optionsListRef.current.map((data) =>
      (id === data.session_option.id ?
        name === 'number' ?
          { ...data, value: value }
          :
          { ...data, value: event.target.checked === true ? 'true' : 'false' }
        : data)
      )
    );
  };

  function time_convert(num) {
    var hours = Math.floor(num / 60);
    var minutes = num % 60;

    return hours + "h " + minutes + "m";
  }

  const checkTimeValid = (data) => {
    if (moment(data).isValid() && moment(data).format('HH:mm') !== '00:00') {
      return true;
    }
    else {
      setDuration('');
    }
  }

  function timeConvertBlur() {
    if (duration !== '' || duration !== "0" || duration !== null || duration !== 0) {
      let parts = duration.split("h");
      let parts2 = duration.split("m");
      if (parts.length > 1 || parts2.length > 1) {
        if (parts.length === 1) {
          if (checkTimeValid(parseInt(parts[0].slice(0, -1), 10))) {
            setDuration(parseInt(parts[0].slice(0, -1), 10) + "m")
          }
        }
        else {
          if (checkTimeValid((parseInt(parts[0], 10) * 60 + (parseInt(parts[1].slice(0, -1) | 0, 10))))) {
            setDuration((parseInt(parts[0], 10) + "h ") + (parseInt(parts[1].slice(0, -1) | 0, 10) + "m"))
          }
        }
      }
      else {
        var decimalTimeString = duration;
        var n = new Date(0, 0);
        n.setMinutes(+decimalTimeString * 60);
        if (checkTimeValid(n)) {
          setDuration((moment(n).hour() + "h ") + moment(n).minute() + "m");
        }
      }
    }
  }

  function callCustomers() {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      // var employee = await getEmployee();
      try {
        var CustomerEmployeeResult = await directus.getItems('customers', {
          fields: '*',
          filter: {
            selectable: 1,
            status: 'published'
          },
          limit: -1,
          sort: "name"
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }

      const options = CustomerEmployeeResult.data.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
      });

      if (active) {
        setOptions(options);
      }
    })();

    return () => {
      active = false;
    };
  }

  /* eslint-disable react-hooks/exhaustive-deps*/
  React.useEffect(() => {
    callCustomers();
  }, [loading]);
  /* eslint-enable react-hooks/exhaustive-deps*/

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  //Activity=============================================================================================================
  const [openActivity, setOpenActivity] = useState(false);
  const [optionsActivity, setOptionsActivity] = useState([]);
  const loadingActivity = openActivity && optionsActivity.length === 0;

  function callActivity() {
    let activeActivity = true;

    if (!loadingActivity) {
      return undefined;
    }

    try {
      (async () => {
        var Activities = await directus.getItems('activities', {
          fields: '*',
          limit: -1,
          filter: {
            selectable: 1,
            status: 'published'
          },
          sort: "name"
        });

        if (activeActivity) {
          setOptionsActivity(Object.keys(Activities.data).map((key) => Activities.data[key]));
        }
      })();
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    return () => {
      activeActivity = false;
    };
  }

  /* eslint-disable react-hooks/exhaustive-deps*/
  React.useEffect(() => {
    callActivity();
  }, [loadingActivity]);
  /* eslint-enable react-hooks/exhaustive-deps*/

  React.useEffect(() => {
    if (!openActivity) {
      setOptionsActivity([]);
    }
  }, [openActivity]);


  const handleStartTimeChange = (newValue) => {
    if(newValue)
    {
      newValue.setSeconds(0);
      setStartTime(newValue);
      getSessionOptionsData(sessionOptionsRef.current,newValue,null);
    }
  }

  const handleEndTimeChange = (newValue) => {
    if(newValue)
    {
      newValue.setSeconds(0);
      setEndTime(newValue);
      getSessionOptionsData(sessionOptionsRef.current,null,newValue);
    }
  }

  const handleBreakTimeChange = (e) => {
    setBreakTime(e.target.value);
  }

  const handleBreakStartChange = (newValue) => {
    if(newValue)
    {
      newValue.setSeconds(0);
      setBreakStartTime(newValue);
    }
  }

  const handleBreakEndChange = (newValue) => {
    if(newValue)
    {
      newValue.setSeconds(0);
      setBreakEndTime(newValue);
    }
  }

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  }

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    let smokoValue = null;
    if (name === 'smoko') {
      if (value > 20) {
        smokoValue = 20
      }

      if (smokoValue !== null) {
        setSmoko(smokoValue);
      }
      else {
        setSmoko(value);
      }
    }
    else if (name === 'duration') {
      setDuration(value);
    }
  }

  function handleDateChange(value) {
    setSelectedDate(value);
  }

  const filterOptionsCustomer = createFilterOptions({
    matchFrom: lengthCustomer.length < 2 ? 'start' : 'any',
    stringify: option => option.name,
  });

  const filterOptionsActivity = createFilterOptions({
    matchFrom: lengthActivity.length < 2 ? 'start' : 'any',
    stringify: option => option.name,
  });

  const handleChangeChecked = (event) => {
    const { name } = event.target;

    if (name === 'checkbox_full_day') {
      setFullDay(event.target.checked);
      if (event.target.checked) {
        setStartTime(null);
        setEndTime(null);
        setBreakStartTime(null);
        setBreakEndTime(null);
      }
      else {
        setDuration(null);
      }
    }
    else if (name === 'checkbox_public_holiday') {
      setPublicHoliday(event.target.checked);
      if (event.target.checked === false) {
        setStartTime(null);
        setEndTime(null);
        setBreakStartTime(null);
        setBreakEndTime(null);
      }
      else {
        setDuration(null);
      }
    }
  }

  const handleClickOpen = (session, timesheets, mode) => {
    if (mode === 'edit') {
      setMode('edit');
      setSessionID(session.id);
      setSessionData(session);
      setType(session.session_type.name);
      setUploadDescription(session.session_type.upload_description);
      fillResourceData(session);
      setSelectedDate(dayjs(session.session_date));
      setCustomer(session.customer ? session.customer.name : null);
      setActivity(session.activity ? session.activity.name : null);
      setStartTime(session.session_start_time ? moment(session.session_date + "T" + session.session_start_time).toDate() : null);
      setEndTime(session.session_end_time ? moment(session.session_date + "T" + session.session_end_time).toDate() : null);
      setBreakTime(session.break_time);
      setBreakStartTime(session.break_start_time ? moment(session.session_date + ' ' + session.break_start_time).toDate() : null);
      setBreakEndTime(session.break_end_time ? moment(session.session_date + ' ' + session.break_end_time).toDate() : null);
      setDuration(session.duration ? time_convert(session.duration) : '');
      setNotes(session.notes);
      setTimesheetStart(dayjs(timesheets.start_time));
      setTimesheetEnd(dayjs(timesheets.end_time));
    }
    else {
      setMode('read');
    }
  }

  function changeCustomer(value) {
    setCustomer(value);
  }

  function changeActivity(value) {
    setActivity(value);
  }

  const handleChangeUpload = (e) => {

    if (e.target.files.length > 0) {
      setFile([...fileRef.current, e.target.files[0]]);
    }
  }

  const handleDeleteFile = (index) => {
    setFile([]);
    document.getElementById('contained-button-file').value = "";
  }

  function onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    console.log(percentCompleted + "% Done");
  }

  const handleClick = (id) => {
    window.open(id);
  };

  const handleDelete = async (id) => {
    setDelFile([...delFileRef.current, id]);
  };


  const uploadButton = () => {
    return (
      <UploadButton
        id="attach_file_btn"
        name="file"
        variant="contained"
        component="span"
        startIcon={<FileUploadIcon />}
        onChange={(e) => handleChangeUpload(e)}
        disabled={fileRef.current.length > 0}
      >
        {props.session.session_type.upload_description}
      </UploadButton>
    );
  }

  const uploadList = () => {
    return (
      <>
        {props.session.attachments ?
          <>
            {props.session.attachments.map((file, index) => {
              return (
                file.directus_file !== null ?
                  delFileRef.current.length === 0 ?
                    <Grid key={`attachments-wrapper-${index}`} item xs={12}>
                      <FileChip
                        key={`attachments-${index}`}
                        icon={<InsertDriveFileIcon fontSize="small" />}
                        label={file.directus_file ? file.directus_file.filename_download : ''}
                        onClick={(id) => handleClick(file.directus_file ? file.directus_file.data.full_url : '')}
                        onDelete={() => handleDelete(file.id)}
                      />
                    </Grid>
                    : ''
                  :
                  delFileRef.current.length === 0 ?
                    <Grid item xs={12}>
                      <FileChip
                        key={`attachments-undefined-${index}`}
                        icon={<InsertDriveFileIcon fontSize="small" />}
                        label={file.directus_file ? file.directus_file.filename_download : 'undefined file'}
                        onDelete={() => handleDelete(file.id)}
                      />
                    </Grid>
                    : ''
              );
            })}

            {fileRef.current.length > 0 ?
              fileRef.current.map((filedata, index) => {
                return (
                  <Grid key={index} item xs={12}>
                    <FileChip
                      key={index}
                      icon={<InsertDriveFileIcon fontSize="small" />}
                      label={filedata.name}
                      onDelete={() => handleDeleteFile(index)}
                    />
                  </Grid>
                );
              })
              : ''}
          </>
          : ''}
      </>
    );
  }

  const fillResourceData = async (sessionData) => {
    let countMaterial = 0;
    let countEquipment = 0;
    if (sessionData.resources) {
      sessionData.resources.map((resourceData) => {
        if (resourceData.resource) {
          if (resourceData.resource.type === 'chemical') {
            material[countMaterial] = { list_id: countMaterial, ...resourceData.resource, value: 0, resourceDataId: resourceData.id, quantity: resourceData.quantity }
            countMaterial = countMaterial + 1;
          }
          else if (resourceData.resource.type === 'equipment') {
            equipment[countEquipment] = { list_id: countEquipment, ...resourceData.resource, value: 0, resourceDataId: resourceData.id }
            countEquipment = countEquipment + 1;
          }
        }
        return null;
      })
    }

    setMaterialData(material);
    setEquipmentData(equipment);

    try {
      var allEquipment = await directus.getItems('resources', {
        fields: '*',
        filter: {
          type: { eq: 'equipment' },
          status: { eq: 'published' }
        },
        limit: -1,
        sort: "name"
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    setAllEquipmentData(allEquipment.data);

    try {
      var allMaterial = await directus.getItems('resources', {
        fields: '*',
        filter: {
          type: { eq: 'chemical' },
          status: { eq: 'published' }
        },
        limit: -1,
        sort: "name"
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    setAllMaterialData(allMaterial.data);
    return null;
  }
  //Equipment===============================================================================================================
  const addEquipment = (equip) => {
    if (equip === null) {
      let data = [];
      data.list_id = equipmentData.length + 1;
      data.value = 2;
      setEquipmentData([...equipmentData, data]);
    }
    else {
      let var_list_id = equipmentData.length + 1;
      let var_value = 2;
      setEquipmentData([...equipmentData, { list_id: var_list_id, ...equip, value: var_value }]);
    }
  };

  const deleteEquipment = (equipData) => {
    if (equipData.value === 2) {
      setEquipmentData(equipmentData.filter((equip) => equip.list_id !== equipData.list_id));
    }
    else {
      setEquipmentData(equipmentData.map((equip) => equip.list_id === equipData.list_id ?
        { list_id: equipData.list_id, ...equipData, value: 3, resourceDataId: equipData.resourceDataId }
        : equip)
      );
    }
  };

  const updateEquipment = (newData, oldData) => {
    setEquipmentData(
      equipmentData.map((equip) => (equip.list_id === oldData.list_id ?
        oldData.value === 2 ?
          { list_id: oldData.list_id, ...newData, value: 2 }
          :
          { list_id: oldData.list_id, ...newData, value: 1, resourceDataId: oldData.resourceDataId }
        : equip)
      )
    );
  };

  const LoopEquipmentData = (Data) => {

    return (
      equipmentData && allEquipmentData ?
        Data.map((equipData, index) => {
          if (equipData.value !== 3) {
            return (
              <Grid key={index} container spacing={0} style={{ marginTop: '6px'}}>
                <Grid item xs={11} zeroMinWidth>
                  <AutocompleteMaterialEquipmentCustom
                    key={index}
                    size="small"
                    options={allEquipmentData}
                    getOptionLabel={(option) => option.name ? option.name : ''}
                    id={`AutoComplete-equip-${index}`}
                    onChange={(event, newValue) => {
                      updateEquipment(newValue, equipData);
                    }}
                    groupBy={(option) => option.firstLetter}
                    disableClearable
                    renderInput={(params) =>
                      <TextFieldCustomerActivityCustom {...params}
                        variant="outlined"
                        label={equipData.name !== null ? equipData.name : ''}
                      />
                    }
                    renderGroup={(params) => (
                      <li key={`equipment-list-${params.key}`} className={classes.fontstyle6}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    )}
                  />
                </Grid>
                <Grid item xs={1}>
                  <RemoveCircleIcon
                    id="iconButton"
                    color="error"
                    style={{ fontSize: 20, float: 'right' }}
                    onClick={() => deleteEquipment(equipData)}
                  />
                </Grid>
              </Grid>
            );
          }
          else {
            return '';
          }
        })
        : ''
    );
  }
  //Equipment================================================================================================================
  //Material+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const addMaterial = (material, qty) => {
    if (material === null) {
      let data = [];
      data.list_id = materialData.length + 1;
      data.value = 2;
      data.quantity = 0;
      setMaterialData([...materialData, data]);
    }
    else {
      let var_quantity = qty;
      let var_list_id = materialData.length + 1;
      let var_value = 2;
      setMaterialData([...materialData, { list_id: var_list_id, ...material, value: var_value, quantity: var_quantity }]);
    }
  };

  const deleteMaterial = (chemiData) => {
    if (chemiData.value === 2) {
      setMaterialData(materialData.filter((material) => material.list_id !== chemiData.list_id));
    }
    else {
      setMaterialData(materialData.map((material) => material.list_id === chemiData.list_id ?
        { list_id: chemiData.list_id, ...chemiData, value: 3, resourceDataId: chemiData.resourceDataId, quantity: chemiData.quantity }
        : material)
      );
    }
  };

  const updateMaterial = (newData, oldData) => {
    setMaterialData(
      materialData.map((material) => (material.list_id === oldData.list_id ?
        oldData.value === 2 ?
          { list_id: oldData.list_id, ...newData, value: 2, quantity: oldData.quantity }
          :
          { list_id: oldData.list_id, ...newData, value: 1, resourceDataId: oldData.resourceDataId, quantity: oldData.quantity }
        : material)
      )
    );
  };

  const handleChange = (e, oldData) => {
    const { name, value } = e.target;
    setMaterialData(
      materialData.map((material) =>
      (material.list_id === oldData.list_id ?
        oldData.value === 2 ?
          { ...material, value: 2, [name]: value }
          :
          { ...material, value: 1, [name]: value }
        : material)
      )
    );
  }

  const LoopMaterialData = (Data) => {
    return (
      Data.map((materialData, index) => {
        if (materialData.value !== 3) {
          return (
            <Grid key={index} container spacing={0} style={{ marginTop: '6px'}}>
              <Grid item xs={8}>
                <AutocompleteMaterialEquipmentCustom
                  key={index}
                  size="small"
                  options={allMaterialData}
                  getOptionLabel={(option) => option.name ? option.name : ''}
                  groupBy={(option) => option.firstLetter}
                  id={`AutoComplete-material-${index}`}
                  onChange={(event, newValue) => {
                    updateMaterial(newValue, materialData);
                  }}
                  disableClearable
                  renderInput={(params) =>
                    <TextFieldCustomerActivityCustom
                      {...params}
                      variant="outlined"
                      label={materialData.name !== null ? materialData.name : ''}
                    />}
                  renderGroup={(params) => (
                    <li key={`material-list-${params.key}`} className={classes.fontstyle6}>
                      <GroupHeader>{params.group}</GroupHeader>
                      <GroupItems>{params.children}</GroupItems>
                    </li>
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <TextFieldSessionOptionValueEditCustom name="quantity" onChange={(event) => handleChange(event, materialData)} value={materialData.quantity} variant="outlined" label='Kg/L' size="small" type="number" step="any" />
              </Grid>
              <Grid item xs={1}>
                <RemoveCircleIcon
                  id="iconButton"
                  color="error"
                  style={{ fontSize: 20 }}
                  onClick={() => deleteMaterial(materialData)}
                />
              </Grid>
            </Grid>
          );
        }
        else {
          return '';
        }
      })
    );
  }
  //Material+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return (
    <Box className={classes.List}>
      <List>
        <ListItem>
          <ListItemText>
            <Typography className={classes.tabstyle}>Timesheet Entry Details</Typography>
          </ListItemText>
          {(props.page === 'timesheet' && props.timesheets.status === 'pending') || (props.page === 'session' && props.timesheets.status === 'awaiting_approval') ?
            mode === 'read' ?
              <EditIcon style={{ width: '15px', height: '15px', cursor: 'pointer' }} onClick={() => handleClickOpen(props.session, props.timesheets, 'edit')} />
              :
              <DoDisturbOnIcon style={{ width: '15px', height: '15px', cursor: 'pointer' }} onClick={() => handleClickOpen(props.session, props.timesheets, 'read')} />
            :
            <ArrowForwardIcon
              style={{ width: '15px', height: '15px', cursor: 'pointer' }}
              onClick={() => dashboardDrawer(5, props.session, props.timesheets, 'read')}
            />
          }
        </ListItem>

        <Divider style={{ margin: '0px 16px 10px 16px' }} />

        <Card className={classes.cardCustom}>
          <CardContent>
            {props.session ?
              mode === 'edit' ?
                //edit session
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  {props.session.session_type.public_holiday ?
                    <Grid item xs={12}>
                      <FormControlLabelCustom
                        control={
                          <SwitchCustom
                            name="checkbox_public_holiday"
                            checked={publicHoliday}
                            onChange={(e) => handleChangeChecked(e)}
                          />}
                        label={<Typography className={classes.fontstyle5}> Did you work on the public holiday?</Typography>}
                        labelPlacement="end"
                      />
                    </Grid>
                    : ''}

                  {props.session.session_type.full_day ?
                    <Grid item xs={12}>
                      <FormControlLabelCustom
                        control={
                          <SwitchCustom
                            name="checkbox_full_day"
                            checked={fullDay}
                            onChange={(e) => handleChangeChecked(e)}
                          />}
                        label={<Typography className={classes.fontstyle5}> Full day</Typography>}
                        labelPlacement="end"
                      />
                    </Grid>
                    : ''}

                  <Grid item xs={12}>
                    <Typography className={classes.fontstyle5}>Type</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldCustom
                      fullWidth
                      className={classes.fontstyle6}
                      id="selected-date"
                      value={type ?? ''}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography className={classes.fontstyle5}>Date</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <EventIcon sx={{ position: 'absolute', right: '16px', padding: '3px', color: '#757575' }} />
                      <MobileDatePicker
                        format="DD MMMM YYYY"
                        value={selectedDate ?? ''}
                        name="selected-date"
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} />}
                        maxDate={timesheetEnd}
                        minDate={timesheetStart}
                        className={classes.DatePickerCustom}
                      />
                    </LocalizationProvider>
                  </Grid>

                  {props.session.session_type.show_customer ?
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.fontstyle5}>Customer</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <AutocompleteCustom
                          disablePortal
                          id="customer"
                          open={open}
                          onFocus={(event) => {
                            setOpen(true);
                          }}
                          onClose={() => {
                            setOpen(false);
                          }}
                          options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                          groupBy={(option) => option.firstLetter}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, newValue) => {
                            changeCustomer(newValue);
                          }}
                          filterOptions={filterOptionsCustomer}
                          size="small"
                          loading={loading}
                          renderInput={(params) => (
                            <TextFieldCustomerActivityCustom
                              {...params}
                              label={props.session.customer ? props.session.customer.name : ''}
                              variant="outlined"
                              onChange={(e) => setLengthCustomer(e.target.value)}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={15} /> : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                          renderGroup={(params) => (
                            <li key={`customer-list${params.key}`} className={classes.fontstyle6}>
                              <GroupHeader>{params.group}</GroupHeader>
                              <GroupItems>{params.children}</GroupItems>
                            </li>
                          )}
                        />
                      </Grid>
                    </>
                    : ''}

                  {props.session.session_type.show_activity ?
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.fontstyle5}>Activity</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <AutocompleteCustom
                          disablePortal
                          id="activity"
                          open={openActivity}
                          onFocus={(event) => {
                            setOpenActivity(true);
                          }}
                          onClose={() => {
                            setOpenActivity(false);
                          }}
                          getOptionSelected={(option, value) => option.name === value.name}
                          getOptionLabel={(option) => option.name}
                          options={optionsActivity}
                          onChange={(event, newValue) => {
                            changeActivity(newValue);
                          }}
                          filterOptions={filterOptionsActivity}
                          size="small"
                          loading={loadingActivity}
                          groupBy={(option) => option.firstLetter}
                          renderInput={(params) => (
                            <TextFieldCustomerActivityCustom
                              {...params}
                              label={props.session.activity ? props.session.activity.name : ''}
                              variant="outlined"
                              onChange={(e) => setLengthActivity(e.target.value)}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loadingActivity ? <CircularProgress color="inherit" size={15} /> : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                          renderGroup={(params) => (
                            <li key={`activity-list${params.key}`} className={classes.fontstyle6}>
                              <GroupHeader>{params.group}</GroupHeader>
                              <GroupItems>{params.children}</GroupItems>
                            </li>
                          )}
                        />
                      </Grid>
                    </>
                    : ''}

                  {publicHoliday && !fullDay ?
                    <>
                      {props.session.session_type.show_start_end_time ?
                        <>
                          <Grid item xs={12}>
                            <Typography className={classes.fontstyle5}>Start Time</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TimePickerCustom name='start-time' handleChange={handleStartTimeChange} value={startTime} />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography className={classes.fontstyle5}>End Time</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TimePickerCustom name='end-time' handleChange={handleEndTimeChange} value={endTime} />
                          </Grid>
                        </>
                        : ''}

                      {props.session.session_type.show_duration || (props.session.session_type.show_duration_admin_only)?
                        <>
                          <Grid item xs={12}>
                            <Typography className={classes.fontstyle5}>Duration</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TextFieldCustom
                              fullWidth
                              name="duration"
                              placeholder="1h 30m"
                              value={duration ?? ''}
                              disabled={props.timesheets.employee.user.role !== 7}
                              onChange={handleChangeInput}
                              onBlur={timeConvertBlur}
                            />
                          </Grid>
                        </>
                        : ''}

                      {props.session.session_type.show_break_time ?
                        <>
                          <Grid item xs={12}>
                            <Typography className={classes.fontstyle5}>Break Time</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TextFieldCustom
                              fullWidth
                              type='number'
                              id="selected-date"
                              value={breakTime ?? ''}
                              onChange={handleBreakTimeChange}
                            />
                          </Grid>
                        </>
                        : ''}

                      {props.session.session_type.show_break_start_end_time ?
                        <>
                          <Grid item xs={12}>
                            <Typography className={classes.fontstyle5}>Break Start Time</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TimePickerCustom name='break-start-time' handleChange={handleBreakStartChange} value={breakStartTime} />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography className={classes.fontstyle5}>Break End Time</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TimePickerCustom name='break-end-time' handleChange={handleBreakEndChange} value={breakEndTime} />
                          </Grid>
                        </>
                        : ''}
                    </>
                    : ''}

                  {props.session.session_type.show_customer ?
                    <>
                      {/* material data */}
                      <Grid item xs={12}>
                        {allMaterialData ?
                          <AddMaterial
                            addMaterial={addMaterial}
                            allMaterialData={allMaterialData}
                          />
                          : ''}
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: '10px' }}>
                        {LoopMaterialData(materialData)}
                      </Grid>

                      {/* equipment data */}
                      <Grid item xs={12}>
                        {allEquipmentData ?
                          <AddEquipment
                            addEquipment={addEquipment}
                            allEquipmentData={allEquipmentData}
                          />
                          : ''}
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: '10px' }}>
                        {LoopEquipmentData(equipmentData)}
                      </Grid>
                    </>
                    : ''}

                  <Grid item xs={12}>
                    {props.session.options.length > 0 ?
                      optionsListRef.current ?
                        loadingSession ?
                          <LinearProgress sx={{ m: '10px' }} />
                          :
                          <>
                            {optionsListRef.current.map((optionlist, index) => {
                              return (
                                optionlist.type === "1" || optionlist.type === "5" ?
                                  <React.Fragment key={`option-list-wrapper${index}`}>
                                    <Grid container spacing={0} justifyContent="space-evenly" alignItems="center">
                                      <Grid item xs={9}>
                                        <TextFieldSessionOptionCustom
                                          fullWidth
                                          multiline
                                          id="selected-date"
                                          defaultValue={optionlist.session_option.description}
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={3} style={{ paddingLeft: '2px' }}>
                                        <Grid container spacing={0} justifyContent="center" alignItems="center">
                                          {optionlist.session_option.input_type === "checkbox" ?
                                              <FormGroup>
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      id="checkbox"
                                                      name="checkbox"
                                                      disableRipple
                                                      size="small"
                                                      style={{ marginLeft: '28px' }}
                                                      sx={{
                                                        "&.Mui-checked": {
                                                          color: "#05a0af"
                                                        }
                                                      }}
                                                      checked={optionlist.value === "true"}
                                                      onChange={(e) => handleChangeSessionOptions(e, optionlist.session_option.id)}
                                                    />}
                                                />
                                              </FormGroup>
                                            :
                                              <TextFieldSessionOptionValueEditCustom
                                                id="outlined-number"
                                                name="number"
                                                type="number"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                variant="outlined"
                                                size="small"
                                                value={optionlist.value ? optionlist.value : 0}
                                                onChange={(e) => handleChangeSessionOptions(e, optionlist.session_option.id)}
                                              />
                                          }
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Divider style={{ marginBottom: '5px' }} />
                                  </React.Fragment>
                                  : '');
                            })
                            }
                          </>
                        : ''
                      : ''
                    }
                  </Grid>

                  {props.session.session_type.upload_description ?
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.fontstyle5}>{props.session.session_type.upload_description}</Typography>
                      </Grid>

                      <Grid item xs={12}>
                        {loadingFile ?
                          <span display="inline-flex"><CircularProgress size={20} /></span>
                          :
                          <>
                            <form id="my-form" className={classes.form}>
                              <input type="file" name="file"
                                onChange={(e) => handleChangeUpload(e)}
                                id="contained-button-file"
                                className={classes.input}
                              />
                              <label htmlFor="contained-button-file">
                                {props.session.attachments.length > 0 ?
                                  delFileRef.current.length > 0 && fileRef.current.length === 0 ?
                                    uploadButton()
                                    : ''
                                  :
                                  fileRef.current.length === 0 ?
                                    uploadButton()
                                    : ''
                                }
                              </label>
                            </form>
                            {uploadList()}
                          </>}
                      </Grid>
                    </>
                    : ''}

                  <Grid item xs={12}>
                    <Typography className={classes.fontstyle5}>Notes</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldNotesCustom
                      fullWidth
                      id='notes'
                      name="notes"
                      onChange={handleNotesChange}
                      value={notes ?? ''}
                      variant="outlined"
                      rows={4}
                      multiline
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <SaveEditSession
                      sessionID={sessionID}
                      selectedDate={selectedDate}
                      sessionNotes={notes}
                      startTime={moment(startTime).format("HH:mm:ss")}
                      endTime={moment(endTime).format("HH:mm:ss")}
                      breakStartTime={moment(breakStartTime).format("HH:mm:ss")}
                      breakEndTime={moment(breakEndTime).format("HH:mm:ss")}
                      sessionDuration={duration}
                      sessionBreakTime={breakTime}
                      allmaterialData={materialData}
                      allEquipmentData={equipmentData}
                      customer={customer}
                      activity={activity}
                      session={sessionData}
                      employeeId={props.timesheets.employee.id}
                      timesheets={props.timesheets}
                      publicHoliday={publicHoliday}
                      fullDay={fullDay}
                      optionsList={optionsListRef.current}
                      timesheetStartDate={timesheetStart}
                      timesheetEndDate={timesheetEnd}  
                      employeeRole={props.timesheets.employee.user.role}
                    />
                  </Grid>
                </Grid>
                :
                // read session
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >

                  <Grid item xs={12}>
                    <Typography className={classes.fontstyle5}>Type</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.readSessionStyle}>{props.session.session_type.name}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.fontstyle5}>Date</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.readSessionStyle}>{moment(props.session.session_date).format("D MMMM YYYY")}</Typography>
                  </Grid>

                  {props.session.session_type.show_customer ?
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.fontstyle5}>Customer</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.readSessionStyle}>{props.session.customer ? props.session.customer.name : ''}</Typography>
                      </Grid>
                    </>
                    : ''}

                  {props.session.session_type.show_activity ?
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.fontstyle5}>Activity</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.readSessionStyle}>{props.session.activity ? props.session.activity.name : ''}</Typography>
                      </Grid>
                    </>
                    : ''}

                  {props.session.session_type.show_start_end_time ?
                    (props.session.session_type.public_holiday || props.session.session_type.full_day) && props.session.session_start_time === null && props.session.session_end_time === null ?
                      ''
                      :
                      <>
                        {props.session.session_start_time ?
                          <>
                            <Grid item xs={12}>
                              <Typography className={classes.fontstyle5}>Start Time</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography className={classes.readSessionStyle}>{moment(props.session.session_start_time, "HH:mm:ss").format('LT')}</Typography>
                            </Grid>
                          </>
                          : ''}
                        {props.session.session_end_time ?
                          <>
                            <Grid item xs={12}>
                              <Typography className={classes.fontstyle5}>End Time</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography className={classes.readSessionStyle}>{moment(props.session.session_end_time, "HH:mm:ss").format('LT')}</Typography>
                            </Grid>
                          </>
                          : ''}
                      </>
                    : ''}

                  {props.session.session_type.show_duration?
                    props.session.duration ?
                      <>
                        <Grid item xs={12}>
                          <Typography className={classes.fontstyle5}>Duration</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.readSessionStyle}>{time_convert(props.session.duration)}</Typography>
                        </Grid>
                      </>
                      : ''
                    :
                    (props.session.session_type.public_holiday || props.session.session_type.full_day) && props.session.session_start_time === null && props.session.session_end_time === null ?
                      <>
                        <Grid item xs={12}>
                          <Typography className={classes.fontstyle5}>Duration</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.readSessionStyle}>{time_convert(props.session.duration)}</Typography>
                        </Grid>
                      </>
                    :
                    (props.session.session_type.show_duration_admin_only && props.timesheets.employee.user.role === 7)?
                      <>
                        <Grid item xs={12}>
                          <Typography className={classes.fontstyle5}>Duration</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.readSessionStyle}>{time_convert(props.session.duration)}</Typography>
                        </Grid>
                      </>
                  : ''}

                  {props.session.session_type.show_break_time ?
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.fontstyle5}>Break Time</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.readSessionStyle}>{props.session.break_time + ' mins'}</Typography>
                      </Grid>
                    </>
                    : ''}

                  {props.session.session_type.show_break_start_end_time ?
                    (props.session.session_type.public_holiday || props.session.session_type.full_day) && props.session.session_start_time === null && props.session.session_end_time === null ?
                      ''
                      :
                      <>
                        <Grid item xs={12}>
                          <Typography className={classes.fontstyle5}>Break Start Time</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.readSessionStyle}>{props.session.break_start_time ? moment(props.session.session_date + ' ' + props.session.break_start_time).format("HH:mm A") : ''}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.fontstyle5}>Break End Time</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.readSessionStyle}>{props.session.break_end_time ? moment(props.session.session_date + ' ' + props.session.break_end_time).format("HH:mm A") : ''}</Typography>
                        </Grid>
                      </>
                    : ''}

                  {/* Check if there are chemical resources */}
                  {props.session.resources.some((resourceData) => resourceData.resource && resourceData.resource.type === 'chemical') && (
                    <Grid item xs={12}>
                      <Typography className={classes.fontstyle5}>Materials</Typography>
                    </Grid>
                  )}

                  {/* Map through the resources */}
                  {props.session.resources.map((resourceData, index) => {
                    if (resourceData.resource && resourceData.resource.type === 'chemical') {
                      return (
                        <React.Fragment key={`list-resource-chemical-${index}`}>
                          <Grid item xs={10}>
                            <Typography className={classes.readSessionStyle}>{resourceData.resource.name}</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography className={classes.readSessionStyle}>{resourceData.quantity}</Typography>
                          </Grid>
                        </React.Fragment>
                      );
                    }
                    return null;
                  })}

                  {/* Check if there are chemical resources */}
                  {props.session.resources.some((resourceData) => resourceData.resource && resourceData.resource.type === 'equipment') && (
                    <Grid item xs={12}>
                      <Typography className={classes.fontstyle5}>Equipment</Typography>
                    </Grid>
                  )}

                  {/* Map through the resources */}
                  {props.session.resources.map((resourceData, index) => {
                    if (resourceData.resource && resourceData.resource.type === 'equipment') {
                      return (
                        <React.Fragment key={`list-resource-equipment-${index}`}>
                          <Grid item xs={12}>
                            <Typography className={classes.readSessionStyle}>{resourceData.resource.name}</Typography>
                          </Grid>
                        </React.Fragment>
                      );
                    }
                    return null;
                  })}
                  
                  {props.session.options.some((optionlist) => optionlist.session_option !== null && optionlist.session_option.input_type !== 'none') && (
                    <Grid item xs={12}>
                      <Typography className={classes.fontstyle5}>Session Options</Typography>
                    </Grid>
                  )}

                  {props.session.options.length > 0 ?
                    props.session.options.map((optionlist, index) => {
                      return (
                        optionlist.session_option !== null ?
                          optionlist.session_option.input_type !== 'none' ?
                            <React.Fragment key={`option-list-wrapper${index}`}>
                              <Grid container spacing={0} justifyContent="space-evenly" alignItems="center">
                                <Grid item xs={9}>
                                  <Typography className={classes.readSessionStyle}>{optionlist.session_option.description}</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ paddingLeft: '2px' }}>
                                  <Typography className={classes.readSessionStyle}>
                                    {optionlist.session_option.input_type === "checkbox" || optionlist.session_option.input_type === '' ?
                                      optionlist.value === 'true' ?
                                        'Yes'
                                        : 'No'
                                      : optionlist.value ? optionlist.value : '-'
                                    }
                                  </Typography>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                            : ''
                          : ''
                      )
                    })
                    : ''}


                  {props.session.session_type.upload_description ?
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.fontstyle5}>{props.session.attachments ? props.session.attachments.length > 0 ? props.session.session_type.upload_description : '' : ''}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {props.session.attachments ?
                          props.session.attachments.map((file, index) => {
                            return (
                              file.directus_file !== null ?
                                <FileChip
                                  key={`file-${index}`}
                                  icon={<InsertDriveFileIcon fontSize="small" />}
                                  label={file.directus_file ? file.directus_file.filename_download : 'File'}
                                  onClick={(id) => handleClick(file.directus_file.data.full_url)}
                                />
                                :
                                <Tooltip placement="top" title="your file is undefined. Please reupload your file.">
                                  <FileChip
                                    key={`file-undefined-${index}`}
                                    icon={<InsertDriveFileIcon fontSize="small" />}
                                    label="undefined file"
                                  />
                                </Tooltip>
                            );
                          })
                          : ''}
                      </Grid>
                    </>
                    : ''}

                  {props.session.notes ?
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.fontstyle5}>Notes</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.readSessionStyle}>{props.session.notes}</Typography>
                      </Grid>
                    </>
                    : ''}
                </Grid>
              : ''}
          </CardContent>
        </Card>
      </List>
    </Box>
  );
};


export default TimesheetEntry;
