import React from 'react';
import {
  Typography,
  Card,
  Grid,
  Box,
} from '@material-ui/core';
import {
  useStylesList,
  CustomCardContent,
  CustomGrid,
} from './style.js';
import EventEmitter from 'src/utils/EventEmitter';
import dayjs from 'dayjs';
import moment from 'moment';


const ReportingSessionList = (props) => {
  /* eslint-disable no-unused-vars*/
  const classes = useStylesList();
  let filteredData = props.sessions;

  const dashboardDrawer = (data, session) => {
    EventEmitter.emit('dashboardDrawer', {
      text: data,
      session: session,
      timesheets: null,
    })
  }

  function durationTime(started, ended) {
    if (ended != null) {
      var diff;
      if (started > ended) {
        diff = moment.duration(moment(moment('1991-01-02T' + ended).format('YYYY-MM-DDTHH:mm:00')).diff(moment('1991-01-01T' + started).format('YYYY-MM-DDTHH:mm:00')));
      }
      else {
        diff = moment.duration(moment(moment('1991-01-01T' + ended).format('YYYY-MM-DDTHH:mm:00')).diff(moment('1991-01-01T' + started).format('YYYY-MM-DDTHH:mm:00')));
      }
      return diff.hours() * 60 + diff.minutes();
    }
    return 0;
  }

  function durationWords(mins, force_hours = false) {
    mins = Math.abs(mins);
    var hours = Math.floor(mins / 60);
    var minutes = Math.floor(mins) % 60; // remove seconds

    if (hours === 0 && !force_hours) {
      return (<span><b>{minutes}</b> min</span>);
    }
    else {
      return (<span><b>{hours}</b> hr <b>{minutes}</b> min</span>);
    }
  }

  const sessionlist = (session, index, status, statusName) => {
    return (
      <div key={index}>
        <Card 
          id={`session${index}`} 
          elevation={0} 
          className={classes.card}
          onClick={() => dashboardDrawer(5, session)}
          style={{cursor: 'pointer'}}
        >
          <CustomCardContent className={classes.cardContent} style={{color:session.employee ? session.employee.status==='deleted'?'rgba(255, 0, 0, 0.77)':'#00000f':'#00000f'}}>
            <CustomGrid container spacing={0}>
              <Grid item xs={1} style={{paddingLeft:'20px'}}>
                <Typography noWrap id={`employee_code_${index}`} className={classes.font}>
                  {session.session_date}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography noWrap id={`employee_phone_${index}`} className={classes.nonChargeableStyle} style={{ background : session.session_type.non_chargable ? 'rgba(255, 206, 177, 0.74)' : ''}}>
                  {session.session_type?session.session_type.name:''}
                </Typography>
              </Grid>
              <Grid item xs={2} style={{paddingLeft:'20px'}}>
                <Typography noWrap id={`employee_user_${index}`} className={classes.font}>
                  {session.employee ? session.employee.user ? session.employee.user.first_name : '' : ''} {session.employee ? session.employee.user ? session.employee.user.last_name : '' : '' }
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography noWrap id={`employee_phone_${index}`} className={classes.font}>
                  {session.customer?session.customer.name:''}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography noWrap id={`employee_phone_${index}`} className={classes.font}>
                  {session.activity?session.activity.name:''}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography noWrap id={`employee_total_time_${index}`} className={classes.font}>
                  {session.session_start_time?
                    dayjs(session.session_date+' '+session.session_start_time).format('hh:mm A')
                  :''}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography noWrap id={`employee_total_time_${index}`} className={classes.font}>
                  {session.session_end_time?
                    dayjs(session.session_date+' '+session.session_end_time).format('hh:mm A')
                  :''}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Box
                  sx={{
                    width: '165px',
                    height: '25px',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '-webkit-fill-available',
                    backgroundColor: 
                      statusName === 'Approved'? '#DAF0DC':
                      statusName === 'Rejected'? '#FFDBD9':
                      statusName === 'Not Yet Submitted'? '#D4E3F2':
                      statusName === 'Waiting Approval'? '#FFF0DD':
                      statusName === 'Sent to Payroll'? 'deepskyblue':
                      statusName === 'Paid'? '#acebac': 'white'
                  }}
                >
                  <Typography noWrap id={`employee_status_${index}`} className={classes.font}>
                    {statusName}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Typography noWrap id={`employee_phone_${index}`} className={classes.font}>
                  {session.duration===0?
                    durationWords(durationTime(session.session_start_time, session.session_end_time))
                  :
                    durationWords(session.duration)}
                </Typography>
              </Grid>
            </CustomGrid>
          </CustomCardContent>
        </Card>
        <div style={{ height: 10 + 'px' }}></div>
      </div>
    );
  }

  return (
    <div>
      {filteredData.map((session, index) => {

        let status = '';
        let statusName = '';
        let match_status = props.timesheetStatusType.find(o => o.value === session.status);
      
        if (match_status) {
          status = { backgroundColor: match_status.background_color, color: session.employee ? session.employee.status==='deleted' ? 'rgba(255, 0, 0, 0.77)' : '#00000f' : '#00000f'};
          statusName = match_status.name;
        }
        return sessionlist(session, index, status, statusName);
      })}
    </div>
  );
};

export default ReportingSessionList;