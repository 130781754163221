import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Grid,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
} from '@material-ui/core';
import {
  SubmitButton,
  CancelButton,
  pageOptions,
  CustomTooltip,
  useStylesEmployees,
  AddButton,
  AddAwardRulesButton,
  AwardChip,
  AwardChipValue,
  CustomAccordion,
  CustomAccordionSummary,
  GridCustom,
  TextFieldCustom,
  AutoCompleteCustom,
} from './style.js';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { AwardsViewModel } from "../../ViewModel/Settings/AwardsViewModel";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import SettingsSkeleton from './SettingsSkeleton';

export default function Awards() {
  const [
    //states
    {
      open, title, Awards,
      mode, loading, disable,
      pageNumberRef, totalPage, page,
      pageNumber, text, localconfiguration,
      code, name, description, payrunFilterChosenRef, payrunRef,
      awardRulesFilterChosenRef, loadingProcessRef
    },
    //function
    {
      handleSubmit, setPayrunFilterChosen,
      handleClickOpen, handleClose, SaveAwards,
      handleChange, handleChangeInput, pageChange,
      LoopAwardRulesData
    }
  ] = AwardsViewModel();

  const classes = useStylesEmployees();

  /* eslint-enable array-callback-return*/

  const handleClickList = (e, isHaveRelation) => {
    if (!isHaveRelation) {
      e.stopPropagation();
    }
  }

  return (
    <div>
      {Awards ?
        <div >
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ backgroundColor: '#f2f2f6', paddingRight: '40px' }}>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={1}>
                      <Typography variant="h5">Code</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h5">Name</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="h5">Description</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5">Payrun</Typography>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                      {localconfiguration.awards_settings ?
                        <AddButton size="small" color="secondary" aria-label="add" onClick={() => handleClickOpen('', 'add')}>
                          <AddIcon />
                        </AddButton>
                        : ''
                      }
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
          </List>

          {loadingProcessRef.current ?
            <SettingsSkeleton status='page_load' />
            :
            <div style={{ marginBottom: '20px' }}>
              {Awards.map((data, index) => {
                const isHaveRelation = data.award_rules.length > 0;
                return (
                  <CustomAccordion key={index}>
                    <CustomAccordionSummary
                      id={`awards_list_${index}`}
                      expandIcon={isHaveRelation ? <ExpandMoreIcon /> : ''}
                      style={{ userSelect: 'auto', cursor: isHaveRelation ? 'pointer' : 'auto', alignItems: 'flex-start' }}
                    >
                      <Grid container onClick={(event) => handleClickList(event, isHaveRelation)} onFocus={(event) => event.stopPropagation()} style={{ paddingRight: isHaveRelation ? '0px' : '36px' }}>
                        <GridCustom item xs={1} md={1} lg={1} style={{ paddingRight: "5px" }}>
                          <Typography noWrap variant="h6" id={`awards_code_${index}`}>{data.code}</Typography>
                        </GridCustom>
                        <GridCustom item xs={4} md={4} lg={4} style={{ paddingRight: "5px" }}>
                          <Typography noWrap variant="h6" id={`awards_name_${index}`}>{data.name}</Typography>
                        </GridCustom>
                        <GridCustom item xs={3} md={3} lg={3}>
                          <Typography noWrap variant="h6" id={`awards_desc_${index}`}>{data.description}</Typography>
                        </GridCustom>
                        <GridCustom item xs={2} md={2} lg={2}>
                          <Typography noWrap variant="h6" id={`awards_payrun_${index}`}>{data.payrun}</Typography>
                        </GridCustom>
                        <Grid item xs={2} md={2} lg={2} style={{ textAlign: 'right' }}>
                          <IconButton id={`edit_awards_${index}`} aria-label="edit" onClick={(event) => { event.stopPropagation(); handleClickOpen(data, 'edit'); }}>
                            <EditIcon fontSize="small" />
                          </IconButton>
                          {localconfiguration.awards_settings ?
                            <IconButton id={`delete_awards_${index}`} aria-label="delete" onClick={(event) => { event.stopPropagation(); handleClickOpen(data, 'delete'); }} >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                            : ''}
                        </Grid>
                      </Grid>
                    </CustomAccordionSummary>
                    <AccordionDetails>
                      <Grid container key={index} style={{ paddingBottom: '1px' }}>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                          {isHaveRelation > 0 ?
                            <div>
                              <Grid container key={index}>
                                <Grid item xs={1} style={{ maxWidth: '8.333333% !important' }}>
                                  <Typography noWrap variant="h6">Award Rules: &nbsp;</Typography>
                                </Grid>
                                <Grid item xs={11}>
                                  {/* eslint-disable-next-line array-callback-return */}
                                  {data.award_rules.map((list, index) => {
                                    return (
                                      <CustomTooltip key={index} title={
                                        <Typography variant="h6">
                                          {list.award_rules_id.name}
                                        </Typography>}>
                                        <AwardChip key={index} variant="outlined" size="small" label={<Typography variant="h6" id={`sp_cus_${index}`}>
                                          {list.award_rules_id.code ? list.award_rules_id.code : list.award_rules_id.name ? list.award_rules_id.code : ""}
                                          &nbsp;
                                          <AwardChipValue label={list.value} color="primary" />
                                        </Typography>} color="primary" />
                                      </CustomTooltip>
                                    );
                                  })}
                                  {/* eslint-enable-next-line array-callback-return */}
                                </Grid>
                              </Grid>
                            </div>
                            : ''}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </CustomAccordion>
                );
              })}
              <Dialog
                id="Awards_dialog"
                isopen={`${open}`}
                open={open}
                fullWidth={true}
                onClose={handleClose}
              >
                <DialogTitle disableTypography={true} id="form-dialog-title"><Typography id="Awards_dialog_title" component="div" variant="h6"><h2>{title}</h2></Typography></DialogTitle>
                <DialogContent>
                  <Typography variant="h6">{text ? text : ''}</Typography>
                  {mode !== 'delete' ?
                    <Grid container spacing={1}>
                      {localconfiguration.awards_settings ?
                        <>
                          <Grid item xs={12} style={{ marginBottom: '10px' }}>
                            <TextFieldCustom
                              size='small'
                              id="code_text"
                              label="Code"
                              variant="outlined"
                              fullWidth={true}
                              name="code"
                              value={code}
                              onChange={handleChangeInput}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ marginBottom: '10px' }}>
                            <TextFieldCustom
                              size='small'
                              id="name_text"
                              label="Name"
                              variant="outlined"
                              fullWidth={true}
                              name="name"
                              value={name}
                              onChange={handleChangeInput}
                            />
                          </Grid>
                        </>
                        : ''}
                      <>
                        <Grid item xs={12} style={{ marginBottom: '10px' }}>
                          <TextFieldCustom
                            size='small'
                            id="description_text"
                            label="Description"
                            variant="outlined"
                            fullWidth={true}
                            name="description"
                            value={description ?? ''}
                            onChange={handleChangeInput}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {payrunRef.current && payrunFilterChosenRef.current ?
                            <AutoCompleteCustom
                              size='small'
                              aria-label='Select Payrun'
                              id="combo-box-timezone"
                              options={payrunRef.current}
                              getOptionLabel={(option) => option.value ?? ''}
                              getOptionSelected={(option, value) => option.value === value.value}
                              fullWidth={true}
                              renderInput={(params) => <TextFieldCustom {...params} label="Select Payrun" variant="outlined" />}
                              onChange={(event, newValue) => {
                                newValue === null ?
                                  setPayrunFilterChosen({ id: 0, name: '', value: '' })
                                  :
                                  setPayrunFilterChosen({ id: newValue.id, name: newValue.name, value: newValue.value })
                              }}
                              value={payrunFilterChosenRef.current}
                              style={{ marginBottom: '10px' }}
                            />
                            : ''}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography varian="h7">
                            <AddAwardRulesButton size="small" color="secondary" aria-label="add award rules" onClick={handleSubmit}>
                              <AddIcon />
                            </AddAwardRulesButton>
                            Add Award Rules
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {awardRulesFilterChosenRef.current.length > 0 ?
                            <> {LoopAwardRulesData(awardRulesFilterChosenRef.current)}</>
                            : ''}
                        </Grid>
                      </>
                    </Grid>
                    : ''}
                </DialogContent>
                <DialogActions style={{ height: "60px" }}>
                  {loading ?
                    <SettingsSkeleton status='component_load' />
                    :
                    <div>
                      <CancelButton
                        id="cancel_btn"
                        size="small"
                        variant="contained"
                        onClick={handleClose}
                        style={{ marginRight: '5px' }}
                      >
                        Cancel
                      </CancelButton>
                      <SubmitButton
                        id="save_btn"
                        disabled={mode === 'delete' ? false : disable}
                        size="small"
                        variant="contained"
                        onClick={() => SaveAwards()}
                      >
                        {mode === 'delete' ? 'Delete' : 'Save'}
                      </SubmitButton>
                    </div>
                  }

                </DialogActions>
              </Dialog>
            </div>
          }
        </div>
        :
        <SettingsSkeleton status='page_load' />
      }

      {Awards ?
        <Grid
          item xs={12}
          style={{ textAlign: 'left', marginBottom: '10px', fontFamily: 'Manrope' }}
        >
          <span style={{ paddingRight: '20px' }}>
            Rows per page:
          </span>
          <span style={{ paddingRight: '20px' }}>
            <TextField
              id="standard-select-currency"
              select
              value={pageNumber}
              onChange={handleChange}
            >
              {pageOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </span>

          <span id="page_number" style={{ paddingRight: '20px' }}>
            {(pageNumberRef.current * (page - 1)) + 1}- {(pageNumberRef.current * page) > totalPage ? totalPage : pageNumberRef.current * page} of {totalPage}
          </span>

          <Button
            id="prev_btn"
            variant="outlined"
            size="small"
            aria-label='Back Button'
            disabled={page === 1}
            className={classes.buttonLeft}
            onClick={() => pageChange(-1)}
          >
            <ArrowBackIosRoundedIcon />
          </Button>

          <Button
            id="next_btn"
            variant="outlined"
            size="small"
            aria-label='Next Button'
            className={classes.buttonRight}
            disabled={page >= Math.ceil(totalPage / pageNumberRef.current)}
            onClick={() => pageChange(1)}
          >
            <ArrowForwardIosRoundedIcon />
          </Button>
        </Grid>
        :
        " "}
    </div>
  );
}