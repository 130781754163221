import { makeStyles } from '@material-ui/core';
import {
withStyles,
Fab,
Button,
Tooltip,
TextField,
Chip,
Grid,
Switch
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { KeyboardDatePicker } from "@material-ui/pickers";
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@mui/material/FormControl';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from "@mui/system";

export const SendResetPWButton = withStyles({
  root: {
    borderRadius: '8px',
    '&:hover': {
      borderRadius: '8px',
    },
  },
})(Button);

export const FormControlCustom = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      height: '34px',
      fontFamily: "Manrope",
    },
    '& .MuiFormLabel-root': {
      top: '-3px',
    },
    '& input': {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    }
  },
})(FormControl);

export const CustomSwitch = withStyles(() => ({
  root: {
    width: 32,
    height: 20,
    padding: 0,
    marginLeft: '10px',
    marginRight: '10px',
    "& .MuiSwitch-switchBase": {
      padding: 0,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",

        "& + .MuiSwitch-track": {
          backgroundColor: "white",
          opacity: 1,
          boxSizing: "border-box",
          border: "1px solid rgba(0, 0, 0, 0.1)"
        }
      }
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 14,
      height: 14,
      margin: "3px 2px 3px 0px",
      color: "#5DB6FF"
    },
    "& .MuiSwitch-track": {
      borderRadius: "10px",
      border: "1px solid rgba(0, 0, 0, 0.1)",
      backgroundColor: "#958d8d",
      padding: 0
    }
  }
}))(Switch);

export const AutoCompleteCustom = withStyles({
  root: {
    '& .MuiAutocomplete-input': {
      marginTop: '-3px'
    },
  },
})(Autocomplete);

export const KeyboardDatePickerCustom = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      height: '34px',
      fontFamily: "Manrope",
    },
    '& .MuiFormLabel-root': {
      top: '-3px',
    },
    '& input': {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    }
  },
})(KeyboardDatePicker);

export const TextFieldCustom = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      height: '34px',
      fontFamily: "Manrope",
    },
    '& .MuiFormLabel-root': {
      top: '-3px',
    },
    '& input': {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    }
  },
})(TextField);

export const TextFieldCustom2 = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      fontFamily: "Manrope",
    },
    '& .MuiFormLabel-root': {
      top: '-3px',
    },
  },
})(TextField);

export const GridCustom = withStyles({
  root: {
    paddingTop: '5px',
  },
})(Grid);

export const CustomAccordion = withStyles({
  root: {
    borderRadius: '8px',
    marginBottom: '10px',
    '&.MuiAccordion-root:before': {
      position: 'fixed'
    },
    '&.MuiAccordion-root.Mui-expanded': {
      marginTop: '0px'
    }
  }
})(Accordion);

export const CustomAccordionSummary = withStyles({
  root: {
    minHeight: '35px',
    maxHeight: '35px',
    margin: '0px 20px !important',
    padding: '0px !important',

    '&.Mui-expanded': {
      minHeight: '35px',
    },
    '& .MuiAccordionSummary-content': {
      margin: '0px 0px',
      height: '36px',
      paddingTop: '1px',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0px 0px !important',
    },
    '& .MuiIconButton-root': {
      padding: '6px 12px',
    }
  },
})(AccordionSummary);

export const CustomGridApproval = withStyles(() => ({
  root: {
    height: '35px',
    paddingTop: '10px',
    marginBottom: '10px'
  },
}))(Grid);

export const LeaveSupervisorChip = withStyles(() => ({
  root: {
    backgroundColor: '#FA9917',
    height: '30px',
    width: '30px',
    border: '0px',
    color: '#FA9917',
    '&:hover': {
      backgroundColor: '#FA9917',
    },
    paddingLeft: '12px',
    marginRight: '10px',
    marginTop: '0px'
  },
}))(Chip);


export const AwardChip = withStyles(() => ({
  root: {
    height: '31px',
    width: 'auto',
    color: 'black',
    border: '1px solid #0d47a1',
    textAlign: 'center',
    marginRight: '3px',
    marginTop: '0px',
    '& .MuiChip-labelSmall': {
      paddingRight: '1px',
    }
  },
}))(Chip);

export const AwardChipValue = withStyles(() => ({
  root: {
    backgroundColor: '#2e73b8',
    height: '25px',
    width: 'auto',
  },
}))(Chip);

export const AddButton = withStyles(() => ({
  root: {
    backgroundColor: '#3399FF',
    width: '30px',
    height: '30px',
    minHeight: '30px',
    boxShadow: '0 0 black',
    left: '8%',
  },
}))(Fab);
export const AddAwardRulesButton = withStyles(() => ({
  root: {
    backgroundColor: '#3399FF',
    width: '25px',
    height: '25px',
    minHeight: '25px',
    boxShadow: '0 0 black',
    marginRight: '5px',
    bottom: '2px',
    left: '2px'
  },
}))(Fab);

export const SubmitButton = withStyles(() => ({
  root: {
    borderRadius: '8px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#22a033',
    },
  }
}))(Button);


export const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "16px",
    maxWidth: 500,
    backgroundColor: "black"
  }
})(Tooltip);

export const CancelButton = withStyles({
  root: {
    borderRadius: '8px',
    backgroundColor: '#e0e0e0de',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#bdbdbdde',
    },
  },
})(Button);

export const LoadingButtonCustom = styled(LoadingButton)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: 'coral',
  height: '30px',
  boxShadow: 'none',
  color: 'white',
  '&:hover': {
    backgroundColor: 'coral',
  },
}));

export const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    paddingTop: 20,
    fontFamily: 'Manrope',

  },
  Appbar: {
    backgroundColor: '#FFFFFF',
    color: '#374355',
    height: 35,
    width: 'fit-content',
    marginBottom: '20px',
    borderRadius: '8px',
    boxShadow: '0px 0px !important',
    '& .Mui-selected': {
      backgroundColor: '#d4e3f2',
      border: '1px solid #5DB6FF',
    },
    '& .MuiTabs-scroller': {
      position: 'initial !important',
    },
  },

  tab: {
    '& .MuiBox-root': {
      padding: '10px 0px 0px 0px',
    },
  },

  tabs: {
    '& .MuiTab-root': {
      minWidth: '130px !important',
    },
  },
  first: {
    maxHeight: 35,
    minHeight: 10,
    fontFamily: 'Manrope',
    fontWeight: 'bold',
    width: 'fit-content',
    borderBottomLeftRadius: '8px',
    borderTopLeftRadius: '8px',
    border: '1px solid #E5E5E5',
  },
  middle: {
    maxHeight: 35,
    minHeight: 10,
    fontFamily: 'Manrope',
    fontWeight: 'bold',
    border: '1px solid #E5E5E5',
  },
  last: {
    maxHeight: 35,
    minHeight: 10,
    fontFamily: 'Manrope',
    fontWeight: 'bold',
    width: 'fit-content',
    borderBottomRightRadius: '8px',
    borderTopRightRadius: '8px',
    border: '1px solid #E5E5E5',
  },
  alignright: {
    textAlign: 'right',
  },
  cardSubDetail:
  {
    padding: '0px !important',
    borderRadius: '8px',
    backgroundColor: '#FFF',
  },
  cardSubDetailContent:
  {
    padding: '0px !important',
  },
}));

export const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
      borderRadius: '8px',
      height: '34px',
      fontFamily: "Manrope",
    },
    '& .MuiFormLabel-root': {
      top: '-3px',
    },
    '& input': {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    }
  },
})(TextField);

export const RosterButton = withStyles({
  root: {
    borderRadius: '8px',
    backgroundColor: '#ffb41e',
    color: '#ffffff',
    height: '25px',
    width: '100px',
    '&:hover': {
      backgroundColor: '#ffb41e',
    },
  },
})(Button);

export const SyncButton = withStyles({
  root: {
    marginLeft: '5px',
    borderRadius: '8px',
    backgroundColor: '#8e92e8',
    color: '#ffffff',
    height: '25px',
    width: 'auto',
    '&:hover': {
      backgroundColor: '#8e92e8',
    },
  },
})(Button);


export const pageOptions = [
  {
    value: 10,
    label: "10",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 100,
    label: "100",
  },
];

export const inputTypeOptions = [
  {
    value: "checkbox",
    label: "Checkbox",
  },
  {
    value: "numeric",
    label: "Numeric",
  },
  {
    value: "none",
    label: "None",
  },
];

export const useStylesEmployees = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px !important',
    fontFamily: 'Manrope',
  },
  card: {
    borderRadius: '8px',
    backgroundColor: '#ffffff',
  },
  linear: {
    color: '#FA9917',
    marginTop: '10px',
    marginBottom: '10px'
  },
  circular: {
    color: '#FA9917',
    marginLeft: '45%'
  },
  circularSync: {
    color: '#FA9917',
    marginLeft: '1%',
    marginBottom: '-1%',
  },
  circularSave: {
    color: '#FA9917',
  },
  buttonLeft: {
    backgroundColor: 'white',
    padding: 0,
    width: '60px',
    borderRadius: '24px 0 0 24px',
    borderRight: 0,
    boxShadow: '0 0 black',
    border: 0,
    height: 30,
  },
  buttonRight: {
    backgroundColor: 'white',
    padding: 0,
    width: '60px',
    borderRadius: '0 24px 24px 0',
    boxShadow: '0 0 black',
    border: 0,
    height: 30,
  },
  disableButtonLeft: {
    backgroundColor: 'white',
    width: '60px',
    borderRadius: '24px 0 0 24px',
    borderRight: 0,
    border: '0 !important',
  },
  filter: {
    '& .MuiListItem-button:hover': {
      backgroundColor: '#39F',
      color: 'white'
    },
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: '#39F',
      color: 'white'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    expandIcon: {
      '& .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.MuiIconButton-edgeEnd': {
        paddingTop: '22px',
      }
    },
    alignright: {
      textAlign: 'right',
    },
    cardSubDetail:
    {
      padding: '0px !important',
      borderRadius: '8px',
      backgroundColor: '#FFF',
    },
    cardSubDetailContent:
    {
      padding: '0px !important',
    },
  },

}));