import React from 'react';
import { Grid } from '@material-ui/core';
import Skeleton from '@mui/material/Skeleton';
import { CustomGridApproval } from './style.js';

export default function Employees(props) {

  if (props.status === 'page_load') {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rounded" height={35} style={{ borderRadius: '8px', marginBottom: '20px', marginTop: '10px' }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rounded" height={35} style={{ borderRadius: '8px', marginBottom: '10px' }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rounded" height={35} style={{ borderRadius: '8px', marginBottom: '10px' }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rounded" height={35} style={{ borderRadius: '8px', marginBottom: '10px' }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rounded" height={35} style={{ borderRadius: '8px', marginBottom: '10px' }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rounded" height={35} style={{ borderRadius: '8px', marginBottom: '10px' }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rounded" height={35} style={{ borderRadius: '8px', marginBottom: '10px' }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rounded" height={35} style={{ borderRadius: '8px', marginBottom: '10px' }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rounded" height={35} style={{ borderRadius: '8px', marginBottom: '10px' }} />
        </Grid>
      </Grid>
    );
  }
  else if (props.status === 'dialog_load') {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius: '8px' }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius: '8px' }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius: '8px' }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius: '8px' }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius: '8px' }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius: '8px' }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius: '8px' }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius: '8px' }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius: '8px' }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius: '8px' }} />
        </Grid>
      </Grid>
    )
  }
  else if (props.status === 'button_load') {
    return (
      <Grid container spacing={0} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Skeleton animation="wave" variant="rounded" height={30} width={120} style={{ borderRadius: '8px', marginRight: '10px' }} />
        <Skeleton animation="wave" variant="rounded" height={30} width={120} style={{ borderRadius: '8px' }} />
      </Grid>
    );
  }
  else if (props.status === 'component_load') {
    return (
      <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius: '8px' }} />
    );
  }
  else if (props.status === 'configuration_load') {
    return (
      <>
        <Grid container spacing={3} style={{ marginBottom: '35px' }}>
          <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
            <Skeleton animation="wave" variant="rounded" width={150} height={34} style={{ borderRadius: '8px', marginLeft: 'auto' }} />
          </CustomGridApproval>
          <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
            <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius: '8px' }} />
          </CustomGridApproval>
        </Grid>
        <Grid container spacing={4} style={{ marginBottom: '35px' }}>
          <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
            <Skeleton animation="wave" variant="rounded" width={165} height={34} style={{ borderRadius: '8px', marginLeft: 'auto' }} />
          </CustomGridApproval>
          <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={3}>
            <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius: '8px' }} />
          </CustomGridApproval>
        </Grid>
        <Grid container spacing={4} style={{ marginBottom: '35px' }}>
          <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
            <Skeleton animation="wave" variant="rounded" width={210} height={34} style={{ borderRadius: '8px', marginLeft: 'auto' }} />
          </CustomGridApproval>
          <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
            <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius: '8px' }} />
          </CustomGridApproval>
        </Grid>
        <Grid container spacing={4} style={{ marginBottom: '35px' }}>
          <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
            <Skeleton animation="wave" variant="rounded" width={155} height={34} style={{ borderRadius: '8px', marginLeft: 'auto' }} />
          </CustomGridApproval>
          <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={1}>
            <Skeleton animation="wave" variant="rounded" width={100} height={34} style={{ borderRadius: '8px' }} />
          </CustomGridApproval>
        </Grid>
        <Grid container spacing={4} style={{ marginTop: '10px' }}>
          <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
          </CustomGridApproval>
          <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={1}>
            <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius: '8px' }} />
          </CustomGridApproval>
        </Grid>
      </>
    );
  }
}
