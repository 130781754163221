
import directus from '../../services/directus';
import { TimezoneController } from '../../Controller/Settings/ReuseController.js';

export const EmployeesController = async (data, page, filter, filterEmployee, showDeletedEmployee ,pageNumber) => {
  let filterData;
  let typeFilter;
  let newFilter;
  let deleteEmployeeFilter;

  // ============== Search is not empty ==============//
  if (data) 
  {
    if(showDeletedEmployee){
      deleteEmployeeFilter = { in: ['published', 'deleted']};
    }
    else{
      deleteEmployeeFilter = { eq: 'published'};
    }

    filterData = {
      fields: 'leave_approver.*.*.*, related.*.*.*, customers.customer.*,user.*.*, *.*',
      meta: "filter_count",
      limit: localStorage.getItem("employees_page")?localStorage.getItem("employees_page"):50,
      page: page,
      filter:
      {
        status: deleteEmployeeFilter,
        'user.first_name': { like: data },
        'user.last_name': { 'logical': 'or', like: data },
        'code': { 'logical': 'or', like: data },
        'user.last_mobile_user_agent': { 'logical': 'or', like: data }
      },
      sort: "code"
    };
    
    var EmployeesResults = await directus.getItems('employees', filterData);    
    
    EmployeesResults.data.map((EmployeeResultsData, x) => {
      EmployeeResultsData.leave_approver = EmployeeResultsData.leave_approver.filter(LeaveApproverData => LeaveApproverData.child !== null);
      EmployeeResultsData.customers = EmployeeResultsData.customers.filter(CustomerData => CustomerData.customer !== null);
      EmployeeResultsData.related = EmployeeResultsData.related.filter(RelatedData => RelatedData.child !== null);    
    })

    return EmployeesResults;

  }
  // ============== Empty search ==============//
  else 
  {
    if (filter === 1) {
      typeFilter = 'type=Full-Time'
      newFilter = 'filter[type][eq]=Full-Time'

    }
    else if (filter === 2) {
      typeFilter = 'type=Casual'
      newFilter = 'filter[type][eq]=Casual'
    }
    else if (filter === 3) {

      if (filterEmployee === true) {
        typeFilter = '';
      }
      else {
        newFilter = '&filter[type][neq]=null';
      }
    }

    if(showDeletedEmployee){
      deleteEmployeeFilter = { in: ['published', 'deleted']};
    }
    else{
      deleteEmployeeFilter = { eq: 'published'};
    }

    var employee_fields = '*,customers.customer.name, customers.customer.code, customers.customer.id,';
        employee_fields += 'award.id,award.name,public_holiday_region.id,public_holiday_region.name,customer_assigned_to.id,customer_assigned_to.name,';
        employee_fields += 'related.child.id,related.child.code,related.child.user.first_name,related.child.user.last_name,';
        employee_fields += 'leave_approver.child.id,leave_approver.child.code,leave_approver.child.user.first_name,leave_approver.child.user.last_name,';
        employee_fields += 'user.*,user.role.name,rdo_hours_enable,banked_hours_enable,auto_adjust_to_roster,';
        employee_fields += 'rosters.id,rosters.employee,rosters.day,rosters.start_time,rosters.end_time,rosters.break,rosters.ordinary_hours,rosters.rdo_accrual';

    if (filterEmployee === true) {
      var MissingLeaveApproverTrue = await directus.api.get('/custom/employees?missing_leave_approver=true&' + typeFilter + '&meta=*&limit=' + pageNumber, {
        fields: employee_fields,
        meta: "filter_count",
        limit: localStorage.getItem("employees_page")?localStorage.getItem("employees_page"):50,
        filter:
        {
          status: deleteEmployeeFilter
        },
        page: page,
      });
  
      MissingLeaveApproverTrue.data.map((MissingLeaveApproverData, x) => {
        MissingLeaveApproverData.customers = MissingLeaveApproverData.customers.filter(CustomerData => CustomerData.customer !== null);
        MissingLeaveApproverData.related = MissingLeaveApproverData.related.filter(RelatedData => RelatedData.child !== null);
        MissingLeaveApproverData.leave_approver = MissingLeaveApproverData.leave_approver.filter(LeaveApproverData => LeaveApproverData.child !== null);
      })

      // sort customer code
      MissingLeaveApproverTrue.data.map((missingLeave)=>{
        missingLeave.customers.sort((a, b) => a.customer.code > b.customer.code ? 1 : -1);
        missingLeave.related.sort((a, b) => a.child !== null && b.child !== null ? a.child.code > b.child.code ? 1 : -1 :'');
        missingLeave.leave_approver.sort((a, b) => a.child !== null && b.child !== null ? a.child.code > b.child.code ? 1 : -1 :'');
      })

      return MissingLeaveApproverTrue;
    }
    else if (filterEmployee === false) {

      var MissingLeaveApproverFalse = await directus.api.get('/custom/employees?' + newFilter + '&meta=*&limit=' + pageNumber, {
        fields: employee_fields,
        page: page,
        meta: "filter_count",
        limit: localStorage.getItem("employees_page")?localStorage.getItem("employees_page"):50,
        filter:
        {
          status: deleteEmployeeFilter,
        },  
      });

      MissingLeaveApproverFalse.data.map((MissingLeaveApproverData, x) => {
        MissingLeaveApproverData.customers = MissingLeaveApproverData.customers.filter(CustomerData => CustomerData.customer !== null);
        MissingLeaveApproverData.related = MissingLeaveApproverData.related.filter(RelatedData => RelatedData.child !== null);
        MissingLeaveApproverData.leave_approver = MissingLeaveApproverData.leave_approver.filter(LeaveApproverData => LeaveApproverData.child !== null);
      })

      // sort customer code
      MissingLeaveApproverFalse.data.map((missingLeave)=>{
        missingLeave.customers.sort((a, b) => a.customer !== null && b.customer !== null ? a.customer.code > b.customer.code ? 1 : -1 :'');
        missingLeave.related.sort((a, b) => a.child !== null && b.child !== null ? a.child.code > b.child.code ? 1 : -1 :'');
        missingLeave.leave_approver.sort((a, b) => a.child !== null && b.child !== null ? a.child.code > b.child.code ? 1 : -1 :'');
      })
      
      return MissingLeaveApproverFalse;
    }
  }
};

export const TimezoneListController = async () => {
  let timezoneResult = [];
  var timezone_data = await TimezoneController();
  const timezone_list = Object.entries(timezone_data.data.options.choices);
  timezone_list.map((data, index) => {
    timezoneResult[index] = { id: data[0], name: data[1], value: data[0] };
  })  
  return timezoneResult;
};

export const EmploymentTypeListController = async () => {
  let employmentTypeResult = [];
  var emp_data = await directus.getField("employees", "type");
  const emp_list = Object.entries(emp_data.data.options.choices);
  emp_list.map((data, index) => {
    employmentTypeResult[index] = { id: data[0], name: data[1], value: data[0] };
  })  

  return employmentTypeResult;
};

export const AwardsController = async () => {
  const result = await directus.getItems('awards');
  return result.data;
};

export const EmployeesFilterController = async () => {
  var result = await directus.getItems('employees', {
    filter: { status: { eq: 'published' } },
    fields: 'related.*.*.*, user.*.*,*',
    sort: 'code',
    limit: -1
  });
  return result.data;
};

export const RoleController = async () => {
  let result = [];
  var role_data = await directus.getRoles();

  role_data.data.map((data, index) => {
    if(data.name === 'Admin'||data.name === 'Employee'||data.name === 'Supervisor')
    {
      result[index] = { id: data.id, name: data.name};
    }
  });
  return result;
}

export const controlLeaveApprover = async() => {
  var EmployeesResults = await EmployeesController();
  EmployeesResults.data.map((EmployeeResultsData, x) => {
    EmployeeResultsData.leave_approver.map((LeaveApproverData, y) => {
      if (LeaveApproverData.child === null) {
        const result = EmployeesResults.data[x].leave_approver.splice(y, 1);
        return result;
      }
    })
  })
};

export const controlRelatedEmployee = async() => {
  var EmployeesResults = await EmployeesController();
  EmployeesResults.data.map((EmployeeResultsData, x) => {
    EmployeeResultsData.leave_approver.map((LeaveApproverData, y) => {
      controlLeaveApprover(LeaveApproverData);
    })
  
  EmployeeResultsData.related.map((RelatedData, y) => {
    if (RelatedData.child === null) {
      const result = EmployeesResults.data[x].related.splice(y, 1);
      return result;
    }
  })
  })
}


