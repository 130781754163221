/* eslint-disable no-irregular-whitespace */
import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  Container,
  CircularProgress,
  Card,
  CardContent,
  Grid,
  Button,
  Tooltip,
  withStyles,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
import {
  useStyles,
  styles,
  FlagTextField,
  FlagTextFieldDisabled,
  DuplicateButton,
  SubmitButton,
  CancelButton,
  NoButton,
  InfoChip,
  DuplicateChip,
  DeleteButton,
  FlagButton,
  DescChip,
  CustomGridApproval,
  DatePickerCustom
} from './style.js';
import AddIcon from '@material-ui/icons/Add';
import { AddChip } from './style.js';
import EventEmitter from 'src/utils/EventEmitter';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Page from 'src/components/Page';
import directus from '../../services/directus';
import InfoIcon from '@material-ui/icons/Info';
import SessionList from './SessionList';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useStateRef from "react-usestateref";
import moment from 'moment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FlagIcon from '@material-ui/icons/Flag';
import { AdminPermission, SupervisorPermission } from 'src/utils/Permission';
import isValid from "date-fns/isValid";
import isSameDay from "date-fns/isSameDay";
import isWithinInterval from "date-fns/isWithinInterval";
import format from "date-fns/format";
import { IconButton } from "@material-ui/core";
import clsx from "clsx";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../Components/ErrorMessage';
import DeleteIcon from '@mui/icons-material/Delete';
import Skeleton from '@mui/material/Skeleton';
import { DirectusEmployee } from 'src/views/Components/LocalstorageData.js';


let dateClone = '';
let selectedDateClone = '';
let start = moment(new Date()).toDate() >= moment(new Date()).weekday(3).toDate() ? moment(new Date()).weekday(3).toDate() : moment(new Date()).weekday(3).add(-7, 'days').toDate();
let end = moment(new Date()).toDate() >= moment(new Date()).weekday(3).toDate() ? moment(moment(new Date()).weekday(3)).add(6, 'days').toDate() : moment(moment(new Date()).weekday(3)).add(-1, 'days').toDate();
const directusEmployeeData = DirectusEmployee();


//* used as content in the dialog when duplicating timesheet data
const CustomElements = () => {
  const classes = styles();

  const [selectedDate, setSelectedDate] = React.useState(new Date());

  /**
   * TODO: set selectedDate with the value of the date parameter
   * @param date : when the user selects a date on the date picker the date parameter will have the value of the date chosen by the user
   */
  const handleWeekChange = (date) => {
    setSelectedDate(moment(date).toDate());
  };

  /**
   * TODO: to display the week date range according to the date selected by the user, which has been set in the start and end variables in the renderWrappedWeekDay function
   * @param date : the first time, the date parameter will be worth today's date, but when the user selects a date on the date picker the date parameter will have the value of the date chosen by the user.
   * @param invalidLabel : undefined
   */
  const formatWeekSelectLabel = (date, invalidLabel) => {
    let dateClone = moment(date).toDate();
    return dateClone && isValid(date) ?
      `${format(start, "MMM do")} - ${format(end, "do")}`
      : invalidLabel;
  };

  /**
   * * This function will check each date currently displayed on the calendar
   * * working days start on Wednesday and the last day is Tuesday, so Wednesday and Tuesday are the reference for setting dates in one week.
   * @param date : the date being checked
   * @param selectedDate : the date selected by the user
   * @param dayInCurrentMonth : (true or false) whether the date being checked is in the current month
   */
  const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    dateClone = moment(date).toDate();

    selectedDateClone = moment(selectedDate).toDate();
    start = '';
    end = '';

    //* get Wednesday date of a selected week
    const dateCheck = moment(selectedDateClone).weekday(3).toDate();

    if (selectedDateClone >= dateCheck) {
      //* if the selected date is greater than the Wednesday date of that week or if the selected date is on a Wednesday, Thursday, Friday, or Saturday, then the start variable will be set with the Wednesday date of that week. And the end variable will be set with the next Tuesday's date.
      start = moment(selectedDateClone).weekday(3).toDate();
      end = moment(moment(selectedDateClone).weekday(3)).add(6, 'days').toDate();

    }
    else {
      //* if the selected date is smaller than the Wednesday date of that week or if the selected date is on a Sunday, Monday or Tuesday then the start variable will be set to the previous week's Wednesday date and the end variable will be set to the Tuesday of the week's date that selected.
      start = moment(selectedDateClone).weekday(3).add(-7, 'days').toDate();
      end = moment(moment(selectedDateClone).weekday(3)).add(-1, 'days').toDate();

    }

    //* dayIsBetween has the value true or false => depending on whether the date being checked is in the range of start and end dates
    const dayIsBetween = isWithinInterval(dateClone, { start, end });

    //* isFirstDay and isLastDay are true or false => depends on whether the date being checked is the same (day and month and year) as the date set at the start and end variable
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);

    //* makes the date being checked highlighted if one of the variables dayIsBetween, isFirstDay, isLastday has a value of 'true'
    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    //* make a date that is not in the current month gray and when that date is highlighted it also remains gray or by adding the class nonCurrentMonthDay or highlightNonCurrentMonthDay
    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    return (
      <div
        className={wrapperClassName}
      >
        <IconButton
          className={dayClassName}
        >
          <span> {format(dateClone, "d")} </span>
        </IconButton>
      </div>
    );
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePickerCustom
          size='small'
          id="select_destination"
          inputVariant="outlined"
          style={{ width: '44%' }}
          label="Select Destination"
          value={selectedDate}
          onChange={handleWeekChange}
          renderDay={renderWrappedWeekDay}
          labelFunc={formatWeekSelectLabel}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}

/**
 * @param props are the values ​​passed to the component
 * @returns title and close button in the message dialog
 */
const FlagDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other} style={{ alignItems: 'middle' }}>
      <Grid
        container
        spacing={0.5}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={11} md={11}>
          <Typography variant="h4">{children}</Typography>
        </Grid>
        <Grid item xs={1} md={1}>
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
              style={{ float: 'right' }}
            >
              <CloseIcon />
            </IconButton>)
            : null}
        </Grid>
      </Grid>
    </DialogTitle>
  );
};


const ApprovalSession = () => {
  /* eslint-disable no-unused-vars*/
  const { state } = useLocation();
  const navigate = useNavigate();
  const childRef = useRef();
  const classes = useStyles();
  const [EndDateState, setEndDateState] = useState(null);
  const [StartDateState, setStartDateState] = useState(null);
  const [items, setItems] = useState({ employees: null, timesheets: null, sessions: null, select: null });
  const [left, setLeft] = useStateRef(null);
  const [right, setRight] = useStateRef(null);
  const [lastName, setLastName] = useState(null);
  const [status, setStatus] = useState(null);
  const [rdoHoursEnable, setRdoHoursEnable] = useState(null);
  const [employeeIdChosen, setEmployeeIdChosen] = useState(null);
  const [sessionData, setSessionData] = useState(null);
  const [timesheetIdChosen, setTimesheetIdChosen] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  //* useStateRef(state) => the state parameter refers to the data that is sent when the user clicks the 'Review' button. The value is {id:index, arrow: props.timesheets}
  const [EmpRef, setNextEmp, NextEmpRef] = useStateRef(state);
  const [duplicateOpen, setDuplicateOpen] = React.useState(false);
  const [deleteTimesheetOpen, setDeleteTimesheetOpen] = React.useState(false);
  const [flagOpen, setFlagOpen] = React.useState(false);
  const [forceOpen, setForceOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [flagLoading, setFlagLoading] = useState(false);
  const [openFlagLoading, setOpenFlagLoading] = useState(false);
  const [forceLoading, setForceLoading] = useState(false);
  const [statusOpen, setStatusOpen] = React.useState(false);
  const [selectedTimesheet, setSelectedTimesheet] = useState(null);
  const [description, setDescription, descriptionRef] = useStateRef('');
  const [timesheetReview, setTimesheetReview, timesheetReviewRef] = useStateRef(null);
  const [unreadMessage, setUnreadMessage, unreadMessageRef] = useStateRef('');
  const [countLatLong, setCountLatLong] = React.useState(0);
  const [marker, setMarker, MarkerRef] = useStateRef([]);
  const { enqueueSnackbar } = useSnackbar();
  /* eslint-enable no-unused-vars*/

  var directusUser = JSON.parse(window.localStorage.getItem('directus_user'));
  // var employeeID = JSON.parse(window.localStorage.getItem('directus_employee')).id;
  var enableTimesheetBankHours = JSON.parse(window.localStorage.getItem('config_ts')).enable_timesheet_bank_hours;
  var config_ts = JSON.parse(window.localStorage.getItem('config_ts'));

  /**
   * TODO :
   * * 1. set selectedTimesheet with the value of the data parameter
   * * 2. set the values ​​of start and end variables, with checking if today's date >= date of the third day (Wednesday) of this week?
   * *    > If true, then set the start variable with the date of the third day (Wednesday) of the current week and set the end variable with the 6th day after the Wednesday of the current week (meaning the next Tuesday date)
   * *    > If false, then set the start variable with the previous week's Wednesday date (weekday(3).add(-7, 'days')) and the end variable set with the current week's Tuesday date or the day before the current week's Wednesday date.
   * * 3. open duplicate dialog 
   * * this function is executed when the user clicks on the Duplicate button, which is next to the Admin Message button
   * @param data is the timesheet data that is being reviewed
   */
  const handleClickDuplicate = (data) => {
    setSelectedTimesheet(data);
    start = moment(new Date()).toDate() >= moment(new Date()).weekday(3).toDate() ? moment(new Date()).weekday(3).toDate() : moment(new Date()).weekday(3).add(-7, 'days').toDate();
    end = moment(new Date()).toDate() >= moment(new Date()).weekday(3).toDate() ? moment(moment(new Date()).weekday(3)).add(6, 'days').toDate() : moment(moment(new Date()).weekday(3)).add(-1, 'days').toDate();
    setDuplicateOpen(true);
  };

  /**
   * TODO: set selectedTimesheet with the timesheet data to delete and open a dialog to confirm whether to delete or not
   * * This function is executed when the user clicks the delete icon button that appears if the session data is empty and the login is admin
   * @param data is the timesheet data that the user wants to delete
   */
  const handleClickDeleteTimesheet = (data) => {
    setSelectedTimesheet(data);
    setDeleteTimesheetOpen(true);
  }

  /**
   * TODO: close dialog
   * * when the user clicks on the close button present on the delete timesheet confirmation dialog, this function is executed
   */
  const handleDeleteTimesheet = () => {
    setDeleteTimesheetOpen(false);
  };

  // TODO: closes the timesheet duplicate dialog
  const handleDuplicateClose = () => {
    setDuplicateOpen(false);
  };

  // TODO: closes the confirmation dialog that appears when the user wants to duplicate data on a week that already has session data
  const handleForceClose = () => {
    setForceOpen(false);
  };

  // TODO: closes the error dialog that appears when the user tries to duplicate on the same date
  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  // TODO: closes the information dialog 'Duplicate is not allowed when there are already approved sessions'
  const handleStatusClose = () => {
    setStatusOpen(false);
  };

  // TODO: set description state with what is typed in the message field
  const handleChangeDescription = (e) => {
    const { value } = e.target;
    setDescription(value);
  }

  /**
   * TODO: delete timesheet data based on id from parameter data, if successful show notification of success deleted and navigate to '/approvals'
   * * If the user clicks the delete button which is next to the duplicate button to delete the timesheet data, this function will be executed 
   * @param data is the timesheet data that the user wants to delete
   */
  const deleteTimesheet = async (data, condition = true) => {
    setDeleteLoading(true);

    try {
      await directus.deleteItem('timesheets', data.id);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    setDeleteLoading(false);
    setDeleteTimesheetOpen(false);

    enqueueSnackbar('success deleted ' + data.employee.user.first_name + ' timesheet', { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 2700 });

    navigate('/approvals');

  }

  /**
   * * 1. get timesheets data from directus with filter based on: employee id from the obtained data parameter, start_time less than or equal to (lte) the date that has been set in the start variable, and end_time greater than or equal to (gte) the date that has been set on the end variable
   * * 2. if there has been a session on the week of the selected date, then :
   * *    > If the destination date range is equal to the current date range, show an error message: 'Duplicate is not allowed when the date destination is same with the current date'.
   * *    > If the selected date range has an approved session, show an error message: 'Duplicate is not allowed when there are already approved sessions'
   * *    > Besides that, show message: 'There is already an existing timesheet for the selected week. This process will replace it. Are you sure?'
   * * 3. if there are no sessions on the week of the selected date, then :
   * *    > post new timesheet duplicate data based on the same id of selected timesheet and with start_date and end_date post data
   * 
   * * This function is executed when the user clicks the Duplicate button, in the dialog that appears when they want to duplicate the timesheet
   * @param data is the timesheet data that is being reviewed
   * TODO: 
   */
  const duplicateFunction = async (data, condition = true) => {
    setDuplicateLoading(true);

    try {
      var checkTimesheet = await directus.getItems('timesheets', {
        fields: 'id, status',
        filter: {
          employee: { eq: data.employee.id },
          start_time: { lte: moment(start).format('YYYY-MM-DD 00:00:00') },
          end_time: { gte: moment(end).format('YYYY-MM-DD 00:00:00') },
        },
        limit: 1
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    if (checkTimesheet.data.length > 0) {
      if (moment(start).format('YYYY-MM-DD 00:00:00') === moment(StartDateState).format('YYYY-MM-DD 00:00:00') &&
        moment(end).format('YYYY-MM-DD 00:00:00') === moment(EndDateState).format('YYYY-MM-DD 00:00:00')) {
        setErrorOpen(true);
        setDuplicateLoading(false);

      }
      else if (checkTimesheet.data[0].status === "approved") {
        setStatusOpen(true);
        setDuplicateLoading(false);

      }
      else {
        setDuplicateLoading(false);
        setForceOpen(true);

      }

    }
    else {
      try {
        var DuplicateResult = await directus.api.post(`/custom/timesheets/duplicate/${selectedTimesheet.id}?force=true`,
          { start_date: moment(start).format('YYYY-MM-DD'), end_date: moment(end).format('YYYY-MM-DD') },
        );
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }

      setDuplicateLoading(false);
      setDuplicateOpen(false);
    }
  };

  /**
   * TODO: force duplicate timesheet data with post new timesheet duplicate data based on the same id of selected timesheet and with start_date and end_date post data
   * * when the user wants to duplicate timesheet data for the week that already has a timesheet, a confirmation message will appear whether to replace it. If the user clicks the 'Yes' button this function will be executed
   * @param data is selected timesheet
   * @param condition is true
   */
  const forceDuplicateFunction = async (data, condition = true) => {
    setDuplicateOpen(false);

    if (condition === true) {


      setForceLoading(true);
      try {
        var DuplicateResult = await directus.api.post(`/custom/timesheets/duplicate/${selectedTimesheet.id}?force=true`,
          { start_date: moment(start).format('YYYY-MM-DD'), end_date: moment(end).format('YYYY-MM-DD') });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
      setForceLoading(false)

    }
    setForceOpen(false);
    handleForceClose();
  }

  //* Customization : tooltip material UI
  const CustomTooltip = withStyles({
    tooltip: {
      fontSize: "16px",
      maxWidth: 500,
      backgroundColor: "grey"
    }
  })(Tooltip);

  //* initializes the month variable with an array of month names
  let month = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      loadTimesheets();
      EventEmitter.emit('topBar', { text: 'ApprovalsReview' });
    }

    const saveEditSessionDrawer = async () => {
      setSaveLoading(true);
      loadTimesheets();
    }
    const listenersaveEditSessionDrawer = EventEmitter.addListener('saveEditSessionDrawer', saveEditSessionDrawer);


    const saveAddSessionDrawer = async (eventData) => {
      setSaveLoading(true);
      setLoading(eventData.text);
      loadTimesheets();
    }
    const listenersaveAddSessionDrawer = EventEmitter.addListener('saveAddSessionDrawer', saveAddSessionDrawer);

    return () => {
      listenersaveAddSessionDrawer.remove();
      listenersaveEditSessionDrawer.remove();
      isSubscribed = false
    }
  }, []);
  /**
   * @param dataId : when this function is called from useEffect then the dataId parameter = null, but if this function is called from another function, for example, the user clicks the next or back button to switch timesheets then the dataId parameter will have the value of the destination timesheet id
   * TODO: 
   * * > if parameter dataId = null then get timesheet id from location pathname (URL)
   * * > get timesheets data from directus based on the timesheet id being reviewed with some required fields
   * * > NextEmpRef.current is a collection of timesheet data on the approvals page that will be reviewed. This data is initialized with the value of the state that is sent when the 'Review' button on the approvals page is clicked
   * * > check if NextEmpRef.current is not empty:
   * *   - if not empty, then: 
   * *      1. check if the timesheet being reviewed is not the 1st data or 0th index:
   * *        * if true, set the Left state with the timesheet data value before the timesheet data is reviewed (id - 1).
   * *        * if false, set the Left state to null
   * *      2. check if the timesheet being reviewed is not the last data or index to (length - 1):
   * *        * if true, set the Right state with the timesheet data value after the timesheet data is reviewed (id + 1).
   * *        * if false, set the Right state to null
   * *   - if empty, then set the Left and Right states with data obtained from directus, and if no data is obtained from directus set with the null value
   * * > then, set startDateState and endDateState by:
   * *     1. get only the date using substring
   * *     2. split the day, month, and year into an array using split
   * *     3. set startDateState and endDateState with the specified format
   * * > then, set values ​​for state : lastName, status, rdoHoursEnable, employeeIdChosen, timesheetIdChosen
   * * > then, call the loadSession function by passing employee id as the first parameter and timesheet id as the second parameter
   * * > the last, call the TimesheetReview function by passing dataId as its parameter
   */
  const loadTimesheets = async (dataId) => {
    var TimesheetsResult='';

    setLoading(true);
    let timesheetId = dataId ? dataId : window.location.pathname.split('/').pop();

    let filterTimesheet;

    if (dataId) {
      filterTimesheet = { id: { eq: dataId } }
    }
    else {
      filterTimesheet = { id: { eq: timesheetId } }
    }

    try {
      TimesheetsResult = await directus.getItems('timesheets', {
        fields: 'id, start_time, end_time, status, banked_minutes, claim_banked_minutes,'
          + 'submission_notes, reviewer_notes, employee.id, employee.code, employee.rdo_hours_enable,'
          + 'employee.status, employee.award.name, employee.award.id, employee.user.id,extra,'
          + 'employee.user.first_name, employee.user.last_name,employee.banked_hours_enable,status,'
          + 'employee.user.role',
        filter: filterTimesheet
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    if (NextEmpRef.current) {
      NextEmpRef.current.id > 0 ? setLeft(NextEmpRef.current.arrow[NextEmpRef.current.id - 1]) : setLeft(null);
      NextEmpRef.current.id < NextEmpRef.current.arrow.length - 1 ? setRight(NextEmpRef.current.arrow[NextEmpRef.current.id + 1]) : setRight(null);
    }
    else {
      try {
        var LeftData = await directus.getItems('timesheets', {
          fields: 'id',
          filter: {
            end_time: { lte: TimesheetsResult.data[0].start_time },
            employee: { eq: TimesheetsResult.data[0].employee.id },
            status: 'awaiting_approval'
          },
          single: 1,
          sort: "start_time"
        });
      } catch (e) {
        console.log(e.message);
      }

      try {
        var RightData = await directus.getItems('timesheets', {
          fields: 'id',
          filter: {
            start_time: { gte: TimesheetsResult.data[0].end_time },
            employee: { eq: TimesheetsResult.data[0].employee.id },
            status: 'awaiting_approval'
          },
          single: 1,
          sort: "start_time"
        });
      } catch (e) {
        console.log(e.message);
      }

      LeftData ? setLeft(LeftData.data) : setLeft(null);
      RightData ? setRight(RightData.data) : setRight(null);
    }

    setItems(prevItems => ({
      ...prevItems,
      timesheets: TimesheetsResult.data
    }));


    let startDate = TimesheetsResult.data[0].start_time;
    let subStrStartDate = startDate.substring(0, 10);
    let StartDateResult = subStrStartDate.split("-");
    setStartDateState(StartDateResult[2] + " " + month[parseInt(StartDateResult[1])] + " " + StartDateResult[0]);

    let endDate = TimesheetsResult.data[0].end_time;
    let subStrEndDate = endDate.substring(0, 10);
    let EndDateResult = subStrEndDate.split("-");
    setEndDateState(EndDateResult[2] + " " + month[parseInt(EndDateResult[1])] + " " + EndDateResult[0]);

    setLastName(" " + TimesheetsResult.data[0].employee.user.last_name);
    setStatus(TimesheetsResult.data[0].employee.status);
    setRdoHoursEnable(TimesheetsResult.data[0].employee.rdo_hours_enable);
    setEmployeeIdChosen(TimesheetsResult.data[0].employee.id);
    setTimesheetIdChosen(timesheetId);
    loadSession(TimesheetsResult.data[0].employee.id, timesheetId);
    TimesheetReview(dataId);

    if (dataId) {
      EventEmitter.emit('moveTimesheets', { text: 'endLoading', timesheets: TimesheetsResult.data[0] })
    }

  };

  /**
   * TODO: 
   * * > get :
   * *  - all top-level fields,
   * *  - second-level relational fields within customer,
   * *  - second-level relational fields within activity,
   * *  - second-level and third-level relational fields within resources, attachments and session_type
   * *  - second-level relational fields within options.session_option,
   * *  - options.value fields, and
   * *  - options.id fields,
   * *  - from sessions item in directus
   * *  - by filtering employee equal to employeeId and timesheet equal to timesheetId
   * * > set the Item state to the value of the session data length.
   * * > call the callChild function by passing session data as a parameter
   * @param employeeId is the employee id of the timesheet being reviewed
   * @param timesheetId is the timesheet id of the timesheet being reviewed 
   */
  const loadSession = async (employeeId, timesheetId) => {
    let filData = {
      employee: { eq: employeeId },
      timesheet: { eq: timesheetId }
    }

    let session_fields = 'id, status, end_time, break_time, break_end_time, break_start_time,session_start_longitude,session_start_latitude,session_end_longitude,session_end_latitude,';
    session_fields += 'session_date, session_start_time, session_end_time, notes, duration, type, leave_status, leave_note, extra,';
    session_fields += 'resources.quantity, resources.id, resources.type, resources.resource.id, resources.resource.name, resources.resource.type,';
    session_fields += 'session_type.id, session_type.name, session_type.include_in_total, session_type.internal, session_type.show_duration,session_type.show_duration_admin_only,';
    session_fields += 'session_type.show_start_end_time, session_type.show_customer, session_type.show_activity, session_type.show_break_time,';
    session_fields += 'session_type.show_break_start_end_time, session_type.start_end_time_from_roster, session_type.full_day,';
    session_fields += 'session_type.public_holiday, session_type.upload_description, session_type.session_options.session_options_id,';
    session_fields += 'customer.id, customer.name, activity.id, activity.name, attachments.id, attachments.directus_file.filename_download,';
    session_fields += 'attachments.directus_file.data.full_url, options.id, options.value, options.session_option.description,';
    session_fields += 'options.session_option.input_type, options.session_option.id, options.session_option.only_overtime,options.session_option.after_overtime,';

    try {
      var SessionResult = await directus.getItems('sessions', {
        fields: session_fields,
        filter: filData,
        sort: "session_date, session_start_time"
      });
      setSessionData(SessionResult.data.length);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
    setItems(prevItems => ({
      ...prevItems,
      sessions: SessionResult.data
    }));

    setLoading(false);
    setSaveLoading(false);
    callChild(SessionResult.data);
    checkLatLong(SessionResult.data);

  };

  // TODO: sends session data to the changeSessionData function in the sessionList file
  function callChild(data) {
    childRef.current.getAlert(data);
  }

  /**
   * TODO:
   * * run the loadTimesheets function by sending the id of the left state (if status = 1) or the id of the right state (if status = 2) as the parameter value
   * * if status = 4 or status = 5 :
   * * > setNextEmp by changing the id value minus 1 (if status = 4) or adding 1 (if status = 5)
   * * > then, navigate to left timesheet data (if status = 4) or navigate to right timesheet data (if status = 5)
   * * > then, run the loadTimesheets function by sending the id of the left state (if status = 4) or the id of the right state (if status = 5) as the parameter value
   * 
   * * NextEmpRef.current ==> has an id value or index of the timesheet that is being reviewed and a collection of timesheet data on the approvals page that will be reviewed
   * * left state ==> is the data on the left of the timesheet data being reviewed or one data before the data being reviewed
   * * right state ==> is the data on the right of the timesheet data being reviewed or one data after the data being reviewed
   * 
   * @param status :
   * * - has the value '4' => when the user clicks the arrow back button, which fulfills the conditions: NextEmpRef.current is not empty and the id of the timesheet being reviewed is greater than 0
   * * - has the value '1' => when the user clicks the arrow back button, which fulfills the conditions: NextEmpRef.current is empty and left state is not empty
   * * - has the value '5' => when the user clicks the arrow forward button, which fulfills the conditions: NextEmpRef.current is not empty and the id of the timesheet being reviewed is less than the length or amount of data in the collection of data timesheet on the approval page to be reviewed
   * * - has the value '2' => when the user clicks the arrow forward button, which fulfills the conditions: NextEmpRef.current is empty and right state is not empty
   * 
   */
  const moveTimesheet = async (status) => {
    if (status === 3) {
      setLoading(true);

      try {
        var addTimesheets = await directus.createItem('timesheets', {
          employee: employeeIdChosen,
          start_time: moment(items.timesheets[0].end_time).add(1, 'd').format("YYYY-MM-DD 00:00:00"),
          end_time: moment(items.timesheets[0].end_time).add(8, 'd').format("YYYY-MM-DD 23:59:59"),
          total_minutes: 0,
          status: 'pending'
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
      navigate('/approvals/' + addTimesheets.data.id)
      loadTimesheets(addTimesheets.data.id)
    }
    else if (status === 1) {
      loadTimesheets(left.id)
    }
    else if (status === 2) {
      loadTimesheets(right.id)
    }
    else if (status === 4) {
      setNextEmp({ id: NextEmpRef.current.id - 1, arrow: NextEmpRef.current.arrow });
      navigate(`/approvals/${left.id}`, { state: { id: NextEmpRef.current.id - 1, arrow: NextEmpRef.current.arrow } })
      loadTimesheets(left.id)
    }
    else if (status === 5) {
      setNextEmp({ id: NextEmpRef.current.id + 1, arrow: NextEmpRef.current.arrow });
      navigate(`/approvals/${right.id}`, { state: { id: NextEmpRef.current.id + 1, arrow: NextEmpRef.current.arrow } })
      loadTimesheets(right.id)
    }
    EventEmitter.emit('moveTimesheets', { text: 'startLoading' })
  }


  /**
   * * this function is called in the loadTimesheets and SaveFlag function
   * @param dataId has a timesheet id value that will be reviewed if called from the loadTimesheets function and will be null if called from the SaveFlag function
   * TODO: Gets the timesheet_review data from directus based on the timesheet id being reviewed and sets it to timesheetReview. After that count unread message and set its value to UnreadMessage state
   */
  const TimesheetReview = async (dataId) => {
    let timesheetId = dataId ? dataId : window.location.pathname.split('/').pop();

    try {
      var TimesheetReview = await directus.getItems('timesheet_review', {
        fields: 'id, description, created_on, read_by.employees_id.id, employee.id, employee.user.first_name, employee.user.last_name, employee.user.id, employee.user.role',
        filter: {
          'timesheet.id': timesheetId
        },
        sort: "created_on"
      },
      );

      setTimesheetReview(TimesheetReview.data);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    let countReadBy = 0;

    timesheetReviewRef.current.map(data => {
      data.read_by.map(info => {
        if (info.employees_id === directusEmployeeData.id) {
          countReadBy = countReadBy + 1;
        }
      })
    })

    let length = (timesheetReviewRef.current.length) - countReadBy;

    setUnreadMessage(length);
  }

  // TODO: set unreadMessage to 0 after opening the admin message dialog
  const UpdateReadMessage = async () => {
    let unReadData = [];
    timesheetReviewRef.current.map(data => {
      let check = 0;

      if (data.read_by) {
        data.read_by.map(info => {
          if (info.employees_id.id === directusEmployeeData.id) {
            check = check + 1;
          }
        })

        if (check === 0) {
          unReadData = [...unReadData, { timesheet_review_id: data.id, employees_id: directusEmployeeData.id }]
        }
      }
      else {
        unReadData = [...unReadData, { timesheet_review_id: data.id, employees_id: directusEmployeeData.id }]
      }

    })

    await directus.createItems("timesheet_review_employees", unReadData);

    setUnreadMessage(0);
  }

  /**
   * TODO: open the message dialog and set selectedTimesheet with the value from the data param. If there is an unread message, set the unreadMessage state to 0 after the message dialog is opened.
   * @param data is a data timesheet that will be reviewed
   */
  const handleClickFlag = async (data) => {
    setOpenFlagLoading(true);

    if (unreadMessageRef.current > 0) {
      UpdateReadMessage();
    }

    setOpenFlagLoading(false);

    setSelectedTimesheet(data);
    setFlagOpen(true);
  };


  // TODO: close the message dialog
  const handleFlagClose = () => {
    setFlagOpen(false);
  };

  /**
   * @param data is a data timesheet that will be reviewed
   * TODO: Set the description to empty, then create the item timesheet_review in Directus with timesheet equal to the parameter id, description equal to what was typed in the message column, and employee equal to the employee id currently logged in.
   */
  const SaveFlag = async (data) => {

    setFlagLoading(true);

    setDescription('');

    try {
      var addTimesheetReview = await directus.createItem('timesheet_review', {
        timesheet: data.id,
        description: description,
        employee: directusEmployeeData.id,
      });

      await directus.createItems("timesheet_review_employees", { timesheet_review_id: addTimesheetReview.data.id, employees_id: directusEmployeeData.id });

    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
    setFlagLoading(false);
    TimesheetReview();
  }


  /**
   * TODO: get hours and minutes from banked_minutes or claim_banked_minutes
   * * This function is executed when TimesheetBankHours is enabled and if banked_minutes or claim_banked_minutes in the timesheet data has a value
   * @param num : if the timesheet data being reviewed has a value of banked_minutes or claim_banked_minutes then the num parameter will have a value of banked_minutes or claim_banked_minutes
   * @returns text captions : hours and minutes
   */
  function time_convert(num) {
    num = Math.abs(num);
    var hours = Math.floor(num / 60);
    var minutes = num % 60;

    return (<span><b>{Math.abs(hours)}</b> hr <b>{minutes}</b> min</span>);
  }

  const checkLatLong = (session) => {
    let count = 0;
    let newMarker = [];
    session.map((data, index) => {
      if (data.session_start_latitude !== 0 && data.session_start_longitude !== 0) {
        newMarker.push({
          id: index + 1,
          name: data.session_date,
          start_time: data.session_start_time,
          end_time: data.session_end_time,
          duration: data.duration,
          customer: data.customer ? data.customer.name : '',
          activity: data.activity ? data.activity.name : '',
          position: { lat: data.session_start_latitude, lng: data.session_start_longitude }
        });
        count++;
      }
    })

    setMarker(newMarker);
    setCountLatLong(count);
  }

  const dashboardDrawer = (data, timesheets) => {
    window.DashboardGlobal = true;
    EventEmitter.emit('contentWidth', {
      data: window.DashboardGlobal
    })

    EventEmitter.emit('dashboardDrawer', {
      text: data,
      timesheets: timesheets,
    })
  }


  return (
    <div>
      <Page className={classes.root} title="Approval">
        <Container maxWidth={false}>
          {loading  && !saveLoading?
            <>
              <Grid container spacing={0}>
                <Grid item xs={4}>
                  <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', marginLeft: '13px' }} />
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={7} sm={6} md={6} lg={6}>
                  <Skeleton animation="wave" variant="rounded" width={300} height={25} style={{ borderRadius: '8px', marginLeft: '13px', marginTop: '10px' }} />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} style={{ whiteSpace: 'nowrap' }}>
                  <Grid container spacing={0} justifyContent="flex-end">
                    <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', display: 'inline-block', width: '70%' }} />
                    <Skeleton animation="wave" variant="rounded" height={24} sx={{ width: '45px', borderRadius: '24px 0 0 24px', display: 'inline-block', marginRight: '1px', marginLeft: '3px' }} />
                    <Skeleton animation="wave" variant="rounded" height={24} sx={{ width: '45px', borderRadius: '0 24px 24px 0', display: 'inline-block' }} />
                    <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', display: 'inline-block', width: '40px', marginRight: '8px', marginLeft: '3px' }} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={1}>
                  <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', margin: '2px 2px' }} />
                </CustomGridApproval>
                <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
                  <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', margin: '2px 2px' }} />
                </CustomGridApproval>
                <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
                  <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', margin: '2px 2px' }} />
                </CustomGridApproval>
                <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={1}>
                  <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', margin: '2px 2px' }} />
                </CustomGridApproval>
                <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={1}>
                  <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', margin: '2px 2px' }} />
                </CustomGridApproval>
                <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
                  <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', margin: '2px 2px' }} />
                </CustomGridApproval>
                <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
                  <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', margin: '2px 2px' }} />
                </CustomGridApproval>
                <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={1}>
                  <Skeleton animation="wave" variant="rounded" height={25} style={{ borderRadius: '8px', margin: '2px 2px' }} />
                </CustomGridApproval>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{ borderRadius: '8px', marginBottom: '10px' }} />
                </Grid>
              </Grid>
            </>
            :
            items.timesheets ?
              <div className="control-pane">
                <div className="control-section">
                  <Grid container spacing={3} justifyContent="space-between" className={classes.titleContainer}>
                    <Typography color="textPrimary" variant="h3" style={{ fontSize: '22px', fontWeight: '700' }}>
                      <span id="timesheet_title" style={status === 'deleted' ? { color: 'rgb(255 0 0 / 77%)', textShadow: '0px 1px #a05d49' } : {}}>{items.timesheets[0].employee.user.first_name}{lastName}'s Timesheet
                        {items.timesheets[0].employee.code !== null ? <span>&nbsp;(<span>{items.timesheets[0].employee.code}</span>)</span> : ''}
                      </span>
                      &nbsp;
                      {items.timesheets[0].submission_notes ?
                        <span>
                          <CustomTooltip title={<Typography variant="h6">{items.timesheets[0].submission_notes}</Typography>}>
                            <DescChip
                              size="small"
                              icon={<InfoIcon />}
                              label="Notes"
                            />
                          </CustomTooltip>
                        </span>
                        : ''}
                      {items.timesheets[0].reviewer_notes ?
                        <span>
                          <CustomTooltip title={<Typography variant="h6">{items.timesheets[0].reviewer_notes}</Typography>}>
                            <InfoChip
                              id="rejected_chip"
                              size="small"
                              icon={<InfoIcon />}
                              label="Rejected"
                            />
                          </CustomTooltip>

                        </span>
                        : ''}

                      {enableTimesheetBankHours ?
                        <>
                          {items.timesheets[0].banked_minutes ?
                            <Chip
                              size="small"
                              style={{ backgroundColor: "#d4e3f2" }}
                              label={
                                <Typography variant="h6">
                                  {rdoHoursEnable ? "RDO Time Accrued : " : "Banked Hours:"} {time_convert(items.timesheets[0].banked_minutes)}
                                </Typography>
                              }
                            />
                            : ''}

                          {items.timesheets[0].claim_banked_minutes ?
                            <Chip
                              size="small"
                              style={{ backgroundColor: "#FFF4E6" }}
                              label={
                                <Typography variant="h6">
                                  {rdoHoursEnable ? "RDO Time Taken : " : "Claim Banked Hours:"} {time_convert(items.timesheets[0].claim_banked_minutes)}
                                </Typography>
                              }
                            />
                            : ''}
                        </>
                        : ''}
                    </Typography>
                  </Grid>
                  <Grid container spacing={3} style={{ marginBottom: "10px" }} className={classes.gridContainer}>
                    <Grid item xs={5}>
                      <Typography id="timesheet_date_range" variant="h4" style={status === 'deleted' ? { color: "#ff0000", fontSize: '20px', fontWeight: '400' } : { fontSize: '20px', fontWeight: '400' }}>
                        {StartDateState} - {EndDateState}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <div>
                        {AdminPermission() || SupervisorPermission() ?
                          <span>
                            {openFlagLoading ? '' :
                              <FlagButton
                                size="medium"
                                startIcon={<FlagIcon />}
                                onClick={() => handleClickFlag(items.timesheets[0])}
                              >
                                Admin Message
                                <Chip size="small" label={unreadMessageRef.current} style={{ marginLeft: '5px', backgroundColor: 'white', color: 'black' }} />
                              </FlagButton>
                            }

                            <DuplicateChip
                              id="duplicate_timesheet_btn"
                              size="medium"
                              disabled={status === 'deleted'}
                              icon={<FileCopyIcon />}
                              label="Duplicate"
                              onClick={() => handleClickDuplicate(items.timesheets[0])}
                            />
                          </span>
                          : ''}

                        {config_ts.enable_late_sessions ?
                          items.timesheets[0].status !== 'pending' ?
                            <AddChip
                              id="add_session_btn"
                              size="medium"
                              icon={<AddIcon />}
                              label="Add Timesheet Entry"
                              onClick={() => dashboardDrawer(6, items.timesheets[0])}
                            />
                            : ''
                          :
                          items.timesheets[0].status === 'awaiting_approval' ?
                            <AddChip
                              id="add_session_btn"
                              size="medium"
                              icon={<AddIcon />}
                              label="Add Timesheet Entry"
                              onClick={() => dashboardDrawer(6, items.timesheets[0])}
                            />
                            : ''
                        }

                      </div>
                      <div style={{ minWidth: '100px', maxWidth: '100px' }}>
                        <span style={{ padding: '0px 0px 0px 4px' }}>
                          {
                            NextEmpRef.current ?
                              NextEmpRef.current.id > 0 ?
                                <Button
                                  variant="outlined"
                                  className={classes.buttonLeft}
                                  onClick={() => moveTimesheet(4)}
                                >
                                  <ArrowBackIosRoundedIcon />
                                </Button>
                                :
                                <Button variant="outlined" size="small" disabled={true} className={classes.disableButtonLeft}>
                                  <ArrowBackIosRoundedIcon />
                                </Button>
                              :
                              left ?
                                <Link to={`/approvals/${left.id}`}>
                                  <Button id="prev_timesheet_btn" variant="outlined" size="small" onClick={() => moveTimesheet(1)} className={classes.buttonLeft}>
                                    <ArrowBackIosRoundedIcon />
                                  </Button>
                                </Link>
                                :
                                <Button variant="outlined" size="small" disabled={true} className={classes.disableButtonLeft}>
                                  <ArrowBackIosRoundedIcon />
                                </Button>
                          }
                        </span>
                        <span>
                          {NextEmpRef.current ?
                            NextEmpRef.current.id < NextEmpRef.current.arrow.length - 1 ?
                              <Button
                                variant="outlined"
                                className={classes.buttonRight}
                                onClick={() => moveTimesheet(5)}
                              >
                                <ArrowForwardIosRoundedIcon />
                              </Button>
                              :
                              <Button variant="outlined" size="small" disabled={true} className={classes.disableButtonRight}>
                                <ArrowForwardIosRoundedIcon />
                              </Button>
                            :
                            right ?
                              <Link to={`/approvals/${right.id}`}>
                                <Button id="next_timesheet_btn" variant="outlined" size="small" onClick={() => moveTimesheet(2)} className={classes.buttonRight}>
                                  <ArrowForwardIosRoundedIcon />
                                </Button>
                              </Link>
                              :
                              <Button variant="outlined" size="small" disabled={true} className={classes.disableButtonRight}>
                                <ArrowForwardIosRoundedIcon />
                              </Button>
                          }
                        </span>
                      </div>
                      <div style={{ minWidth: '40px', maxWidth: '40px' }}>
                        {sessionData === 0 && AdminPermission() ?
                          <DeleteButton aria-label="delete" size="large" disabled={status === 'deleted'}>
                            <DeleteIcon fontSize="inherit" onClick={() => handleClickDeleteTimesheet(items.timesheets[0])} />
                          </DeleteButton>
                          : ''}
                      </div>
                    </Grid>
                  </Grid>

                  {items.sessions && employeeIdChosen ?
                    <SessionList
                      setLoading={setLoading}
                      saveLoad={saveLoading}
                      timesheets={items.timesheets[0]}
                      session={items.sessions}
                      loadTimesheets={loadTimesheets}
                      ref={childRef}
                      status={status}
                      loadSession={loadSession}
                      employeeIdChosen={employeeIdChosen}
                      timesheetIdChosen={timesheetIdChosen}
                      page='session'
                      right={right}
                      totalCountLatLong={countLatLong}
                      marker={MarkerRef.current}
                    />
                    : ''}
                </div>
              </div>
              : ''}
        </Container>
      </Page>

      {/* Duplicate Dialog */}
      <Dialog
        id="duplicate_timesheet_dialog"
        isopen={`${duplicateOpen}`}
        open={duplicateOpen}
        fullWidth={true}
        onClose={handleDuplicateClose}
      >
        <DialogTitle disableTypography={true} id="form-dialog-title"><Typography id="title_duplicate" component="div" variant="h6"><h3>Duplicate {selectedTimesheet ? selectedTimesheet.employee.user.first_name : ''}  {selectedTimesheet ? selectedTimesheet.employee.user.last_name : ''}'s Timesheet</h3></Typography></DialogTitle>

        <DialogContent>
          <CustomElements />
        </DialogContent>

        <DialogActions>
          {duplicateLoading ?
            <CircularProgress className={classes.circular} />
            :
            <div>
              <CancelButton
                id="cancel_dialog_btn"
                size="small"
                variant="contained"
                onClick={handleDuplicateClose}
              >
                Cancel
              </CancelButton>
              <DuplicateButton
                id="duplicate_dialog_btn"
                size="small"
                variant="contained"
                onClick={() => duplicateFunction(selectedTimesheet)}
              >
                Duplicate
              </DuplicateButton>
            </div>
          }
        </DialogActions>
      </Dialog>

      {/* Delete Timesheet Dialog */}
      <Dialog
        open={deleteTimesheetOpen}
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title"><h3> Delete {selectedTimesheet ? selectedTimesheet.employee.user.first_name : ''}  {selectedTimesheet ? selectedTimesheet.employee.user.last_name : ''}'s Timesheet</h3></DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure?</DialogContentText>
        </DialogContent>
        <DialogActions>
          {deleteLoading ?
            <CircularProgress className={classes.circular} />
            :
            <>
              <CancelButton
                size="small"
                variant="contained"
                onClick={handleDeleteTimesheet}
              >
                Cancel
              </CancelButton>
              <SubmitButton
                size="small"
                variant="contained"
                onClick={() => deleteTimesheet(selectedTimesheet)}
              >
                Delete
              </SubmitButton>
            </>
          }
        </DialogActions>
      </Dialog>

      {/* Flag Dialog */}
      <Dialog
        open={flagOpen}
        fullWidth={true}
        onClose={handleFlagClose}
      >
        <FlagDialogTitle id="form-dialog-title" onClose={handleFlagClose}>
          Admin Messages for {selectedTimesheet ? selectedTimesheet.employee.user.first_name : ''}  {selectedTimesheet ? selectedTimesheet.employee.user.last_name : ''}'s Timesheet
        </FlagDialogTitle>

        <DialogContent>
          {timesheetReviewRef.current ?
            <span>
              {timesheetReviewRef.current.map((review, index) => {
                let empStatus;

                if (directusUser.first_name === review.employee.user.first_name && directusUser.last_name === review.employee.user.last_name) {
                  empStatus = '#e3ffde';
                }
                else {
                  if (review.employee.user.role !== null) {
                    if (review.employee.user.role === 'Supervisor') {
                      empStatus = '#ffe5d6';
                    }
                    else if (review.employee.user.role === 'Admin' || review.employee.user.role === 'Company Admin') {
                      empStatus = '#d9f2fa';
                    }
                    else {
                      empStatus = '#dee0ff';
                    }
                  }
                }

                return (
                  <Grid key={index} container spacing={1} justifyContent="flex-end">
                    {directusUser.first_name === review.employee.user.first_name && directusUser.last_name === review.employee.user.last_name ?
                      <Grid item xs={12} md={12}>
                        <Card variant="outlined" style={{ lineBreak: "anywhere", borderRadius: '16px', float: 'right', backgroundColor: empStatus }}>
                          <CardContent style={{ padding: '3px 6px' }}>
                            <Typography
                              style={{ lineBreak: "anywhere", fontFamily: 'Montserrat', fontSize: '12px' }}
                            >
                              <b style={{ fontSize: '10px' }}>{review.employee.user.first_name}</b>
                              <br />
                              {review.description.split("\n").map(function (item, index) {
                                return (
                                  <span key={index}>
                                    {item}
                                    <br />
                                  </span>
                                )
                              })}

                              <p style={{ textAlign: 'right', fontSize: '10px', paddingLeft: '45px' }}>{moment(review.created_on).format('hh:mm A')} </p>
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      :
                      <Grid item xs={12} md={12}>
                        <Card variant="outlined" style={{ lineBreak: "anywhere", borderRadius: '16px', float: 'left', backgroundColor: empStatus }}>
                          <CardContent style={{ padding: '3px 6px' }}>
                            <Typography
                              style={{ lineBreak: "anywhere", fontFamily: 'Montserrat', fontSize: '12px' }}
                            >
                              <b style={{ fontSize: '10px' }}>{review.employee.user.first_name}</b>
                              <br />
                              {review.description.split("\n").map(function (item, index) {
                                return (
                                  <span key={index}>
                                    {item}
                                    <br />
                                  </span>
                                )
                              })}
                              <p style={{ textAlign: 'right', fontSize: '10px', paddingLeft: '45px' }}>{moment(review.created_on).format('hh:mm A')} </p>
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    }
                  </Grid>
                );
              })
              }
              {flagLoading ?
                <CircularProgress className={classes.circular} size={20} style={{ marginBottom: '8px', marginTop: '8px', textAlign: 'right', marginLeft: '6px' }} />
                :
                ''
              }
            </span>
            :
            <CircularProgress className={classes.circular} size={20} style={{ marginBottom: '8px', marginTop: '8px' }} />
          }

          <br />

          {status !== 'deleted' ?
            <Grid
              container
              spacing={0.5}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={11} md={11}>
                <FlagTextField
                  name="Description"
                  style={{ borderRadius: '16px' }}
                  fullWidth
                  onChange={handleChangeDescription}
                  value={descriptionRef.current}
                  variant="outlined"
                  label='Type Message'
                  rows={2}
                  multiline
                />
              </Grid>
              <Grid item xs={1} md={1}>
                <IconButton aria-label="send" size="large">
                  {
                    descriptionRef.current === ' ' || descriptionRef.current === '' || descriptionRef.current === null ?
                      <SendIcon
                        fontSize="inherit"
                        disabled
                      />
                      :
                      <SendIcon
                        fontSize="inherit"
                        onClick={() => SaveFlag(selectedTimesheet)}
                        disabled
                      />
                  }
                </IconButton>
              </Grid>
            </Grid>
            :
            <Grid
              container
              spacing={0.5}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={11} md={11}>
                <FlagTextFieldDisabled
                  name="Description"
                  style={{ borderRadius: '16px' }}
                  fullWidth
                  disabled
                  variant="outlined"
                  label='Type Message'
                  rows={2}
                  multiline
                />
              </Grid>
              <Grid item xs={1} md={1}>
                <IconButton aria-label="send" size="large" disabled>
                  <SendIcon
                    fontSize="inherit"
                    disabled
                  />
                </IconButton>
              </Grid>
            </Grid>
          }

        </DialogContent>
      </Dialog>

      {/* force dialog */}
      <Dialog
        open={forceOpen}
        fullWidth={true}
        onClose={handleForceClose}
      >
        <DialogContent>
          <Typography>
            <br /><br />
            There is already an existing timesheet for the selected week. This process will replace it. Are you sure?
            <br /><br />
          </Typography>
        </DialogContent>
        <DialogActions>
          {forceLoading ?
            <CircularProgress className={classes.circular} />
            :
            <div>
              <NoButton
                size="small"
                variant="contained"
                onClick={handleForceClose}
              >
                No
              </NoButton>
              <SubmitButton
                size="small"
                variant="contained"
                onClick={() => forceDuplicateFunction(selectedTimesheet, true)}
              >
                Yes
              </SubmitButton>
            </div>
          }
        </DialogActions>
      </Dialog>

      <Dialog
        id="error_duplicate_dialog"
        isopen={`${errorOpen}`}
        open={errorOpen}
        fullWidth={true}
        onClose={handleErrorClose}
      >
        <DialogContent>
          <Typography>
            <br /><br />
            Duplicate is not allowed when the date destination is same with current date.
            <br /><br />
          </Typography>
        </DialogContent>
        <DialogActions>

          <div>
            <CancelButton
              size="small"
              variant="contained"
              onClick={handleErrorClose}
            >
              Cancel
            </CancelButton>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={statusOpen}
        fullWidth={true}
        onClose={handleStatusClose}
      >
        <DialogContent>
          <Typography>
            <br /><br />
            Duplicate is not allowed when there are already approved sessions
            <br /><br />
          </Typography>
        </DialogContent>
        <DialogActions>

          <div>
            <CancelButton
              size="small"
              variant="contained"
              onClick={handleStatusClose}
            >
              Cancel
            </CancelButton>
          </div>
        </DialogActions>
      </Dialog>
    </div>

  );
};

export default ApprovalSession;
